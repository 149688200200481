// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require('buffer').Buffer;

export const PrivateCryptoKey = {
  AesKey: 'sJt7TpTGPZxvLQqJcJCpc3jr4uJmSuyC',
  AesIV: 'sJt7TpTGPZxvLQqJ',
};
class Decode {
  static decodeHash(hash: string) {
    try {
      // const encryptionType = 'aes-256-cbc';
      // const encryptionEncoding = 'base64';
      // const bufferEncryption = 'utf-8';

      // const buff = Buffer.from(hash, encryptionEncoding);
      // const key = Buffer.from(PrivateCryptoKey.AesKey, bufferEncryption);
      // const iv = Buffer.from(PrivateCryptoKey.AesIV, bufferEncryption);
      // const decipher = crypto.createDecipheriv(encryptionType, key, iv);
      // let decrypted = decipher.update(buff);
      // decrypted = Buffer.concat([decrypted, decipher.final()]);
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export default Decode;
