import { ICompanyCategory } from './core.interface';

export const IBETCategoryList: ICompanyCategory[] = [
  {
    label: 'Sportsbook',
    value: '1',
  },
  {
    label: 'Sportsbook',
    value: '11',
    parentId: 1,
  },
  {
    label: 'SABA Soccer',
    value: '12',
    parentId: 1,
  },

  {
    label: 'SABA Basketball',
    value: '13',
    parentId: 1,
  },
  {
    label: 'SABA Cricket',
    value: '14',
    parentId: 1,
  },
  {
    label: 'SABA Soccer PinGoal',
    value: '15',
    parentId: 1,
  },
  {
    label: 'SABA Basketball PinGoal',
    value: '16',
    parentId: 1,
  },
  {
    label: 'SABA E-Sports PinGoal',
    value: '19',
    parentId: 1,
  },
  {
    label: 'SABA Tennis',
    value: '20',
    parentId: 1,
  },
  {
    label: 'Number Game',
    value: '3',
  },
  {
    label: 'Lotto',
    value: '23',
  },
  {
    label: 'Lottery',
    value: '28',
  },
  {
    label: 'Sports Lottery',
    value: '63',
  },
  {
    label: 'RNG Keno',
    value: '8',
  },
  {
    label: 'Funky Games',
    value: '50',
  },
  {
    label: 'Table Game',
    value: '40',
  },
  {
    label: 'Virtual Games',
    value: '38',
  },
  {
    label: 'Virtual Sports',
    value: '5',
  },
  {
    label: 'SABA.games',
    value: '42',
  },
  {
    label: 'Bitcoin',
    value: '69',
  },
  {
    label: 'FGG',
    value: '70',
  },
  // {
  //   id: 2,
  //   label: 'Live Casino',
  //   value: '',
  // },
  {
    label: 'FGG',
    value: '70',
  },
  {
    label: 'Allbet',
    value: '21',
  },
  {
    label: 'IBCBet Live Casino',
    value: '46',
  },
  {
    label: 'BBIN',
    value: '47',
  },
  {
    label: 'SA Gaming',
    value: '39',
  },
  {
    label: 'AE Sexy',
    value: '45',
  },
  {
    label: 'BG',
    value: '57',
  },
  {
    label: 'PP Live Casino',
    value: '65',
  },
  {
    label: 'YeeBet',
    value: '76',
  },
  {
    label: 'WE Live Casino',
    value: '80',
  },
  {
    label: 'ON Casino',
    value: '96',
  },
  {
    label: 'ION',
    value: '52',
  },
  {
    label: 'WM',
    value: '51',
  },

  // {
  //   id: 3,
  //   label: 'RNG Casino',
  //   value: '',
  // },
  {
    label: 'Macau Games',
    value: '22',
  },
  {
    label: 'Saba Casino',
    value: '6',
  },
  {
    label: 'Arcadia Gaming',
    value: '34',
  },
  {
    label: 'SG',
    value: '36',
  },
  {
    label: 'PP',
    value: '32',
  },
  {
    label: 'PG Soft',
    value: '55',
  },
  {
    label: 'MaxGame',
    value: '60',
  },
  {
    label: 'Habanero',
    value: '61',
  },
  {
    label: 'CG',
    value: '62',
  },
  {
    label: 'AdvantPlay',
    value: '67',
  },
  {
    label: 'AdvantPlay Mini',
    value: '68',
  },
  {
    label: 'SEAL Entertainment',
    value: '72',
  },
  {
    label: 'MG-RNG',
    value: '73',
  },
  {
    label: 'Jili',
    value: '75',
  },
  {
    label: 'Live22',
    value: '79',
  },

  {
    label: 'Nextspin',
    value: '85',
  },
  {
    label: 'SABA xD',
    value: '86',
  },
  {
    label: 'FastSpin',
    value: '87',
  },

  {
    label: 'FA CHAI',
    value: '89',
  },
];

export const SBOCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'SPORTBOOK',
    value: '1',
  },
  {
    id: 2,
    label: 'Live Casino & Casino Games',
    value: '2',
  },
  {
    id: 3,
    label: 'Games',
    value: '3',
  },
  {
    id: 4,
    label: 'Racing',
    value: '4',
  },
];
export const EXSBOCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'SPORTBOOK',
    value: 'SportsBook',
  },
  {
    id: 2,
    label: 'CASINO',
    value: 'RoyalCasino',
  },
  {
    id: 3,
    label: 'GAMES',
    value: 'Games',
  },
  {
    id: 4,
    label: 'RaceTote',
    value: 'RaceTote',
  },
];

export const SVENUSCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'COCK FIGHT',
    value: 'SV388',
  },
  {
    id: 2,
    label: 'CAM',
    value: 'CAM',
    parentId: 1,
  },
  {
    id: 3,
    label: 'PHI',
    value: 'PHI',
    parentId: 1,
  },
  {
    id: 4,
    label: 'TH',
    value: 'TH',
    parentId: 1,
  },
  {
    id: 5,
    label: 'HRB',
    value: 'HRB',
  },
  {
    id: 6,
    label: 'FACHAI',
    value: 'FACHAI',
  },
  {
    id: 7,
    label: 'JDB',
    value: 'JDB',
  },
  {
    id: 8,
    label: 'JILI',
    value: 'JILI',
  },
  {
    id: 9,
    label: 'KINGMAKER',
    value: 'KINGMAKER',
  },
  {
    id: 10,
    label: 'MIMI',
    value: 'MIMI',
  },
  {
    id: 11,
    label: 'OTHER',
    value: 'OTHER',
  },
  {
    id: 12,
    label: 'CASINO SEXY',
    value: 'SEXYBCRT',
  },
  {
    id: 13,
    label: 'VENUS',
    value: 'VENUS',
  },
  {
    id: 14,
    label: 'HRG',
    value: 'HRG',
  },
  {
    id: 15,
    label: 'SVCASINO',
    value: 'SVCASINO',
  },
];

export const LASVEGASCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'CASINO',
    value: 'CASINO',
  },
];

export const ONE789CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'Xổ số truyền thống',
    value: '0',
  },
  {
    id: 2,
    label: 'Xổ số 789',
    value: '1',
  },
  {
    id: 3,
    label: '789 Casino',
    value: '2',
  },
  {
    id: 4,
    label: 'WM Casino',
    value: '100',
  },
  {
    id: 5,
    label: 'Bóng88',
    value: '101',
  },
];
export const SGS889CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'LOTTO',
    value: '-1',
  },
];

export const HK1107CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'LOTTO',
    value: 'LOTTO',
  },
];
export const VN8899CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'LOTTO',
    value: 'LOTTO',
  },
];
export const SGD777CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'CASINO',
    value: 'CASINO',
  },
];
export const OK368CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'LOTTO',
    value: 'LOTTO',
  },
];
export const S3IN1BETCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'SPORTBOOK',
    value: 'SPORTBOOK',
  },
];

export const PS3838CategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'SPORTBOOK',
    value: 'SPORTBOOK',
  },
];

export const WONCategoryList: ICompanyCategory[] = [
  {
    id: 1,
    label: 'ALL',
    value: 'ALL',
  },
];
