/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import {
  IAccount,
  IResultScanAccount,
} from '../../../repositories/account/account.interface';
import { ErrorType } from '../../../repositories/core/error-type';
import AccountService from '../../../services/account/account.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'scanFailed'; error: ErrorType; account: IAccount }
    | { type: 'scanSuccess'; result: IResultScanAccount };
  start: () => void;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  scanFailed: (error: ErrorType, account: IAccount) => void;
  scanSuccess: (result: IResultScanAccount) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    scanFailed: (error, account) =>
      set({ state: { type: 'scanFailed', error, account } }),
    scanSuccess: (result) => set({ state: { type: 'scanSuccess', result } }),
  }));

const useScanState = () => {
  const [useStore] = useState(createStore);
  const { state, start, isLoading, setLoading, scanFailed, scanSuccess } =
    useStore();

  const scanItem = async ({
    isQuick,
    fromDate,
    toDate,
    account,
  }: {
    isQuick: boolean;
    fromDate: string;
    toDate: string;
    account: IAccount;
  }) => {
    start();
    setLoading(true);
    const result = await AccountService.scanAccount({
      isQuick,
      fromDate,
      toDate,
      account,
    });
    if (isRight(result)) {
      scanSuccess(result.right);
    } else {
      scanFailed(result.left, account);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      scanItem,
    },
  };
};

export default useScanState;
