import TabbarScanComponent from './components/tabbar';

const ScanPage = () => {
  return (
    <div className="p-2">
      <TabbarScanComponent />
    </div>
  );
};

export default ScanPage;
