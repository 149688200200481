import { useEffect, useState } from 'react';

import {
  DeleteOutlined,
  EditOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Breadcrumb,
  Form,
  FormInstance,
  Tooltip,
  TablePaginationConfig,
} from 'antd';

import DrawerCreateUpdateCustomerComponent from './components/DrawerCreateUpdateCustomerComponent';
import DrawerManageViewReportListCustomerComponent from './components/DrawerManageViewReportListCustomerComponent';
import useAccountDeleteState from './states/useCustomerDeleteState';
import useCustomerListState from './states/useCustomerListState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { defaultPageSize } from '../../../repositories/core/constant';
import { ICustomer } from '../../../repositories/customer/customer.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';

const TableList = ({
  isLoading,
  list,
  form,
  totalItems,
  current,
  onDelete,
  onUpdate,
  onViewReportManage,
  onSubmitSearch,
  onChangePagination,
  onOpenDrawer,
}: {
  isLoading: boolean;
  list: ICustomer[];
  form: FormInstance<any>;
  totalItems: number;
  current: number;
  onDelete: (item: ICustomer) => void;
  onUpdate: (item: ICustomer) => void;
  onViewReportManage: (item: ICustomer) => void;
  onSubmitSearch: (values: any) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
  onOpenDrawer: () => void;
}) => {
  const columns = [
    {
      title: 'Khách hàng',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Mã đối ứng',
      dataIndex: 'keyMatch',
      key: 'keyMatch',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: ICustomer) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: ICustomer) => (
        <div className="flex gap-2">
          <Tooltip title="Danh sách tài khoản xem báo cáo">
            <Button
              onClick={() => onViewReportManage(record)}
              size="small"
              icon={<OrderedListOutlined />}
            />
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>

          <Tooltip title="Xóa khách hàng">
            <Popconfirm
              title="Xóa khách hàng này"
              description="Bạn có chắc chắn xóa khách hàng này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form onFinish={onSubmitSearch} form={form} layout="vertical">
        <div className="grid  grid-cols-1 gap-2 lg:grid-cols-2">
          <div>
            <Form.Item name="username">
              <Input placeholder="Nhập tên khách hàng" allowClear />
            </Form.Item>
          </div>

          <div className="flex gap-2">
            <Button
              className="w-full"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Tìm kiếm
            </Button>
            <Button onClick={onOpenDrawer} className="w-full" type="primary">
              Thêm khách hàng
            </Button>
          </div>
        </div>
      </Form>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Quản lý khách hàng',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">
        Quản lý khách hàng
      </h2>
    </div>
  );
};

const CustomerListPage = () => {
  const { state, event, isLoading, list, totalItems } = useCustomerListState();

  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useAccountDeleteState();

  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null,
  );
  const [selectedCustomerViewReport, setSelectedCustomerViewReport] =
    useState<ICustomer | null>(null);
  const [isCloseCreateUpdate, setIsCloseCreateUpdate] =
    useState<boolean>(false);
  const [isCloseViewReportManage, setIsCloseViewReportManage] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      username: formSearch.getFieldValue('username'),
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  const onConfirmDelete = (item: ICustomer) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onCloseCreateUpdate = () => {
    setIsCloseCreateUpdate(false);
    setSelectedCustomer(null);
  };

  const onCloseViewReportManage = () => {
    setIsCloseViewReportManage(false);
    setSelectedCustomerViewReport(null);
  };

  const onSelectedViewReportManage = (value: ICustomer) => {
    setSelectedCustomerViewReport(value);
    setIsCloseViewReportManage(true);
  };

  const onSelectedUpdate = (value: ICustomer) => {
    setIsCloseCreateUpdate(true);
    setSelectedCustomer(value);
  };

  const onSubmitSearch = () => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa khách hàng thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <HeaderList />
      <TableList
        form={formSearch}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        isLoading={isLoading}
        onSubmitSearch={onSubmitSearch}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsCloseCreateUpdate(true)}
        onViewReportManage={onSelectedViewReportManage}
        onChangePagination={onChangePagination}
      />
      <DrawerCreateUpdateCustomerComponent
        selected={selectedCustomer}
        isClose={isCloseCreateUpdate}
        onClose={onCloseCreateUpdate}
        onReload={() => onLoad({})}
      />
      <DrawerManageViewReportListCustomerComponent
        selected={selectedCustomerViewReport}
        isClose={isCloseViewReportManage}
        onClose={onCloseViewReportManage}
        onReload={() => onLoad({})}
      />
    </div>
  );
};

export default CustomerListPage;
