import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Empty, Popconfirm, Spin, Tooltip } from 'antd';
import classNames from 'classnames';

import {
  IAccountScanMethod,
  IMapOnlyMethod,
} from '../../../repositories/account-scan/account-scan.interface';
import { ICompany } from '../../../repositories/company/company.interface';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';

const getCurrencyMethodBidDivision = ({
  method,
}: {
  method: IMapOnlyMethod;
}) => {
  return (
    <div
      className={classNames(
        'relative h-[70px] w-full border-b border-solid border-neutral-300  bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      {method.currencyDeliverName !== method.currencyReceiveName && (
        <>
          <div className="relative h-[35px] w-full font-semibold">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              {method.currencyReceiveName}
            </span>
          </div>
          <div className="relative h-[35px] w-full border-t border-solid border-neutral-300 font-semibold">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              {method.currencyDeliverName}
            </span>
          </div>
        </>
      )}
      {method.currencyDeliverName === method.currencyReceiveName && (
        <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
          {method.currencyDeliverName}
        </span>
      )}
    </div>
  );
};

const getCurrencyMethod = ({ method }: { method: IMapOnlyMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[35px] w-full border-b border-solid  border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.currencyName}
      </span>
    </div>
  );
};

const getMemberMethodBidDivision = ({ method }: { method: IMapOnlyMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[70px] border-b border-solid border-neutral-300  bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.memberUsername} [{method.percentBidDivision * -1}%]
      </span>
    </div>
  );
};

const getMemberMethod = ({ method }: { method: IMapOnlyMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[35px] w-full  border-b  border-solid border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.memberUsername}
      </span>
    </div>
  );
};

const getMethodBidDivision = ({
  method,
  companyCode,
}: {
  method: IMapOnlyMethod;
  companyCode: string;
}) => {
  const receive = `${method.methodReceiveName.replace(`${companyCode}-`, '')}${
    method.percentReceive > 0 ? `[${method.percentReceive}%]` : ''
  }`;
  const deliver = `${method.methodDeliverName.replace(`${companyCode}-`, '')}${
    method.percentDeliver > 0 ? `[${method.percentDeliver * -1}%]` : ''
  }`;

  return (
    <div
      className={classNames(
        'relative h-[70px] w-full border-b border-solid border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {receive}#{deliver}
      </span>
    </div>
  );
};

const getMethod = ({
  method,
  companyCode,
}: {
  method: IMapOnlyMethod;
  companyCode: string;
}) => {
  return (
    <div
      className={classNames(
        'relative h-[35px] w-full border-b border-solid  border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.methodName.replace(`${companyCode}-`, '')}
      </span>
    </div>
  );
};

const TableRowAccountScan = ({
  accountScan,
  classNameUsername,
  loadingList,
  company,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
  onOpenAddCustomAccountScan,
  onDeleteCustomAccountScan,
}: {
  loadingList: number[];
  classNameUsername?: string;
  accountScan: IAccountScanMethod;
  company: ICompany | null;
  onFetchByParentId: (parentId: number) => void;
  onRemoveByParentId: (parentId: number) => void;
  onOpenMethodManage: (accountScan: IAccountScanMethod) => void;
  onOpenAddCustomAccountScan: (accountScan: IAccountScanMethod) => void;
  onDeleteCustomAccountScan: (accountScan: IAccountScanMethod) => void;
}) => {
  return (
    <tr className="hover:bg-slate-100">
      <td className="border border-solid border-neutral-300 align-middle text-xs">
        <div
          className={classNames(
            'h-full w-full cursor-pointer items-center',
            classNameUsername,
          )}
          onClick={() => {
            if (accountScan.isChildren) {
              if (accountScan.children.length > 0) {
                onRemoveByParentId(accountScan.id);
              } else if (accountScan.children.length === 0) {
                onFetchByParentId(accountScan.id);
              }
            }
          }}
        >
          {accountScan.isChildren && (
            <>
              <div className="inline-block h-[20px] w-[14px]">
                {accountScan.children.length === 0 &&
                  !loadingList.includes(accountScan.id) && (
                    <PlusSquareOutlined />
                  )}
                {accountScan.children.length > 0 &&
                  !loadingList.includes(accountScan.id) && (
                    <MinusSquareOutlined />
                  )}
                {loadingList.includes(accountScan.id) && <Spin size="small" />}
              </div>
            </>
          )}

          <p className="inline-block  pl-1 uppercase">{accountScan.username}</p>
        </div>
      </td>
      <td className="border border-solid border-neutral-300 text-left align-middle text-xs font-semibold">
        <div className="flex h-full w-full items-center justify-center gap-2 text-center ">
          <div className="inline-block">
            <Tooltip title="Thêm tuyến dưới">
              <Button
                size="small"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => onOpenAddCustomAccountScan(accountScan)}
              />
            </Tooltip>
          </div>
          <div className="inline-block">
            {accountScan.children.length === 0 && (
              <Tooltip title="Xóa tuyến dưới này">
                <Popconfirm
                  title="Xóa tuyến dưới này"
                  description="Bạn có chắc chắn xóa tuyến dưới này?"
                  onConfirm={() => onDeleteCustomAccountScan(accountScan)}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button
                    danger
                    size="small"
                    type="dashed"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        </div>
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {accountScan.mapMembers.length > 0 &&
          accountScan.mapMembers.map((member) => {
            return (
              <>
                {member.isBidDivision &&
                  getMethodBidDivision({
                    method: member,
                    companyCode: company ? company.companyCode : '',
                  })}
                {!member.isBidDivision &&
                  getMethod({
                    method: member,
                    companyCode: company ? company.companyCode : '',
                  })}
              </>
            );
          })}
        {accountScan.mapMembers.length === 0 && (
          <div className="relative h-[35px] w-full">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              Chưa có công thức
            </span>
          </div>
        )}
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {accountScan.mapMembers.map((member) => {
          return (
            <>
              {member.isBidDivision &&
                getMemberMethodBidDivision({
                  method: member,
                })}
              {!member.isBidDivision &&
                getMemberMethod({
                  method: member,
                })}
            </>
          );
        })}
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {accountScan.mapMembers.map((member) => {
          return (
            <>
              {member.isBidDivision &&
                getCurrencyMethodBidDivision({
                  method: member,
                })}
              {!member.isBidDivision &&
                getCurrencyMethod({
                  method: member,
                })}
            </>
          );
        })}
      </td>
      <td className="border border-solid border-neutral-300 p-1 text-left align-middle text-xs font-semibold">
        <div className="h-full w-full p-[1px] text-center">
          <Tooltip title="Thêm công thức">
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => onOpenMethodManage(accountScan)}
            />
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

const TableRowListAccountScan = ({
  level,
  accountScan,
  loadingList,
  company,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
  onOpenAddCustomAccountScan,
  onDeleteCustomAccountScan,
}: {
  level: number;
  accountScan: IAccountScanMethod;
  loadingList: number[];
  company: ICompany | null;
  onFetchByParentId: (parentId: number) => void;
  onRemoveByParentId: (parentId: number) => void;
  onOpenMethodManage: (accountScan: IAccountScanMethod) => void;
  onOpenAddCustomAccountScan: (accountScan: IAccountScanMethod) => void;
  onDeleteCustomAccountScan: (accountScan: IAccountScanMethod) => void;
}) => {
  return (
    <>
      <TableRowAccountScan
        classNameUsername={`pl-${level}`}
        key={`${accountScan.id}-${level}`}
        accountScan={accountScan}
        loadingList={loadingList}
        company={company}
        onFetchByParentId={onFetchByParentId}
        onRemoveByParentId={onRemoveByParentId}
        onOpenMethodManage={onOpenMethodManage}
        onOpenAddCustomAccountScan={onOpenAddCustomAccountScan}
        onDeleteCustomAccountScan={onDeleteCustomAccountScan}
      />
      {accountScan.children &&
        accountScan.children.map((child, index) => {
          return (
            <TableRowListAccountScan
              level={level + 2}
              key={`${child.id}-${child.parentId}-${index}`}
              accountScan={child}
              loadingList={loadingList}
              company={company}
              onFetchByParentId={onFetchByParentId}
              onRemoveByParentId={onRemoveByParentId}
              onOpenMethodManage={onOpenMethodManage}
              onOpenAddCustomAccountScan={onOpenAddCustomAccountScan}
              onDeleteCustomAccountScan={onDeleteCustomAccountScan}
            />
          );
        })}
    </>
  );
};

const TableHeaderAccountScan = () => {
  return (
    <thead className="border-separate ">
      <tr className="p-2 font-semibold">
        <th className="w-[135px] border border-solid border-neutral-300 p-2">
          Tài Khoản
        </th>
        <th className="w-[80px] border border-solid border-neutral-300 p-2">
          Tuyến dưới
        </th>
        <th className="border border-solid border-neutral-300 p-2">
          Công thức
        </th>
        <th className="w-[120px] border border-solid border-neutral-300 p-2">
          Thành viên
        </th>
        <th className="w-[80px] border border-solid border-neutral-300 p-2">
          Loại tiền
        </th>
        <th className="w-[24px] border border-solid border-neutral-300 p-2"></th>
      </tr>
    </thead>
  );
};

const TableAccountScanDetail = ({
  companyId,
  loadingList,
  accountScans,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
  onOpenAddCustomAccountScan,
  onDeleteCustomAccountScan,
}: {
  companyId: number;
  loadingList: number[];
  accountScans: IAccountScanMethod[];
  onFetchByParentId: (parentId: number) => void;
  onRemoveByParentId: (parentId: number) => void;
  onOpenMethodManage: (accountScan: IAccountScanMethod) => void;
  onOpenAddCustomAccountScan: (accountScan: IAccountScanMethod) => void;
  onDeleteCustomAccountScan: (accountScan: IAccountScanMethod) => void;
}) => {
  const { list: companys } = useCompanyListState();
  const getCompanyCode = companys.find((x) => x.id === companyId);
  return (
    <div>
      <table className="w-full border border-solid border-neutral-300">
        <TableHeaderAccountScan />
        <tbody>
          {accountScans.map((accountScan, index) => {
            const level = 2;
            return (
              <TableRowListAccountScan
                level={level}
                key={`${accountScan.id}-${index}`}
                company={getCompanyCode ?? null}
                accountScan={accountScan}
                loadingList={loadingList}
                onFetchByParentId={onFetchByParentId}
                onRemoveByParentId={onRemoveByParentId}
                onOpenMethodManage={onOpenMethodManage}
                onOpenAddCustomAccountScan={onOpenAddCustomAccountScan}
                onDeleteCustomAccountScan={onDeleteCustomAccountScan}
              />
            );
          })}
          {accountScans.length === 0 && loadingList.length === 0 && (
            <td colSpan={6}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableAccountScanDetail;
