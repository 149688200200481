import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CoffeeOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies

import { IAccount } from '../../../repositories/account/account.interface';
import {
  ConnectionCode,
  StatusScan,
} from '../../../repositories/core/constant';
import CommonStatusCode from '../../../ultis/common-status-code';
import { match } from '../../../ultis/custom-matcher';

const StatusAccountScan = ({ account }: { account: IAccount }) => {
  let statusCode = StatusScan.waiting;
  if (account.connectionCode === ConnectionCode.SUCCESS) {
    statusCode = StatusScan.success;
  } else if (account.connectionCode === ConnectionCode.FAILED) {
    statusCode = StatusScan.failed;
  } else if (account.connectionCode === ConnectionCode.PROCESSING) {
    statusCode = StatusScan.processing;
  } else if (account.connectionCode === ConnectionCode.WAITING) {
    statusCode = StatusScan.waiting;
  } else {
    statusCode = StatusScan.stopped;
  }
  return match([statusCode as StatusScan])({
    [StatusScan.waiting]: () => (
      <div className="flex items-center">
        <span className="mr-1 ">
          <CoffeeOutlined size={10} />
        </span>
        <span>
          <strong>{account.prefixCode ?? account.username}</strong>
          {' - '}
          <i className="capitalize italic">
            ({CommonStatusCode.getMessageConnectionCode(account.connectionCode)}
            )
          </i>
        </span>
      </div>
    ),
    [StatusScan.processing]: () => (
      <div className="flex items-center">
        <span className="mr-1 text-state-informative">
          <SyncOutlined spin size={10} />
        </span>
        <span className=" text-state-informative">
          <strong>{account.prefixCode ?? account.username}</strong>
          {' - '}
          <i className="capitalize italic">
            ({CommonStatusCode.getMessageConnectionCode(account.connectionCode)}
            )
          </i>
        </span>
      </div>
    ),
    [StatusScan.failed]: () => (
      <div className="flex items-center">
        <span className="mr-1 text-state-critical">
          <ExclamationCircleOutlined size={10} />
        </span>
        <span className=" text-state-critical">
          <strong>{account.prefixCode ?? account.username}</strong>
          {' - '}
          <i className="capitalize italic">
            ({CommonStatusCode.getMessageConnectionCode(account.connectionCode)}
            )
          </i>
        </span>
      </div>
    ),
    [StatusScan.stopped]: () => (
      <div className="flex items-center">
        <span className="mr-1 text-state-negative">
          <CloseCircleOutlined size={10} />
        </span>
        <span className=" text-state-negative">
          <strong>{account.prefixCode ?? account.username}</strong>
          {' - '}
          <i className="capitalize italic">
            ({CommonStatusCode.getMessageConnectionCode(account.connectionCode)}
            )
          </i>
        </span>
      </div>
    ),
    [StatusScan.success]: () => (
      <div className="flex items-center">
        <span className="mr-1 text-state-positive">
          <CheckCircleOutlined size={10} />
        </span>
        <span className=" text-state-positive">
          <strong>{account.prefixCode ?? account.username}</strong>
          {' - '}
          <i className="capitalize italic">
            ({CommonStatusCode.getMessageConnectionCode(account.connectionCode)}
            )
          </i>
        </span>
      </div>
    ),
  });
};

export default StatusAccountScan;
