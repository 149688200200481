import { PropsWithChildren } from 'react';

import { Spin, Watermark } from 'antd';

import useAuthenticatedState from '../auth/states/useAuthenticatedState';

const PermissionPackageWrapper = ({ children }: PropsWithChildren) => {
  const { currentPackage } = useAuthenticatedState();

  return (
    <>
      {!currentPackage && (
        <div>
          <Watermark
            content={['HP79KT', 'Chưa đăng ký']}
            zIndex={100}
            gap={[40, 40]}
            font={{ fontWeight: 600 }}
          >
            <Spin tip="Chưa đăng ký gói">{children}</Spin>
          </Watermark>
        </div>
      )}
      {currentPackage && children}
    </>
  );
};

export default PermissionPackageWrapper;
