import { Drawer, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { drawerStyle } from '../../../../repositories/core/constant';
import { IReportCompare } from '../../../../repositories/report/report.interface';

const TablReportCompareList = ({
  isLoading,
  list,
}: {
  isLoading: boolean;
  list: IReportCompare[];
}) => {
  const columns: ColumnsType<IReportCompare> = [
    {
      title: 'Công thức',
      dataIndex: 'methodName',
      key: 'methodName',
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        size="middle"
        pagination={false}
      />
    </>
  );
};

const DrawerMethodCompareComponent = ({
  isOpen,
  list,
  isLoading,
  selectedMemberId,
  onClose,
}: {
  isOpen: boolean;
  isLoading: boolean;
  selectedMemberId: number | null | undefined;
  list: IReportCompare[];
  onClose: () => void;
}) => {
  return (
    <Drawer
      title={'Xem thông tin khớp sổ'}
      width={'80%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selectedMemberId && (
        <div className="grid grid-cols-2 gap-2">
          <div>
            <h4 className="w-full p-2 text-center text-lg font-bold">
              Công thức thành viên
            </h4>
            <TablReportCompareList
              list={list.filter((x) => x.memberId === selectedMemberId)}
              isLoading={isLoading}
            />
          </div>
          <div>
            <h4 className="w-full p-2 text-center text-lg font-bold">
              Công thức đối chiếu
            </h4>
            <TablReportCompareList
              list={list.filter((x) => x.memberId !== selectedMemberId)}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default DrawerMethodCompareComponent;
