/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import ReportService from '../../../../services/report/report.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'exchangeFailed'; error: ErrorType }
    | { type: 'exchangeSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  exchangeFailed: (error: ErrorType) => void;
  exchangeSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    exchangeFailed: (error) =>
      set({ state: { type: 'exchangeFailed', error } }),
    exchangeSuccess: () => set({ state: { type: 'exchangeSuccess' } }),
  }));

const useReportExchangeState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    start,
    setLoading,
    exchangeFailed,
    exchangeSuccess,
  } = useStore();

  const exchangeItem = async ({
    fromCurrencyId,
    toCurrencyId,
    range,
    reportId,
    arrayMemberId,
  }: {
    fromCurrencyId: number;
    toCurrencyId: string;
    reportId: number;
    range: number;
    arrayMemberId: number[];
  }) => {
    start();
    setLoading(true);
    const result = await ReportService.exchangeCurrency({
      fromCurrencyId,
      toCurrencyId,
      range,
      reportId,
      arrayMemberId,
    });
    if (isRight(result)) {
      exchangeSuccess();
    } else {
      exchangeFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      exchangeItem,
    },
  };
};

export default useReportExchangeState;
