/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { IOption } from '../../../../repositories/core/core.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import { IReportDetail } from '../../../../repositories/report/report.interface';
import ReportService from '../../../../services/report/report.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; detail: IReportDetail };
  isLoading: boolean;
  detail: IReportDetail | null;
  customerList: IOption[];
  openList: string[];
  customerExchangedList: IOption[];
  start: () => void;
  setLoading: (value: boolean) => void;
  setOpenList: (list: string[]) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (
    detail: IReportDetail,
    cutomerList: IOption[],
    customerExchangeList: IOption[],
  ) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    detail: null,
    openList: [],
    customerList: [],
    customerExchangedList: [],
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    setOpenList: (list) => set({ openList: list }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (detail, customerList, customerExchangedList) =>
      set({
        state: { type: 'fetchSuccess', detail },
        detail,
        customerList,
        customerExchangedList,
      }),
  }));

const useReportDetailState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    detail,
    openList,
    customerList,
    customerExchangedList,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
    setOpenList,
  } = useStore();

  const toggleOpen = (key: string) => {
    let cloneList = [...openList];
    const find = cloneList.find((x) => x === key);
    if (find) {
      cloneList = cloneList.filter((x) => x !== key);
    } else {
      cloneList.push(key);
    }
    setOpenList(cloneList);
  };

  const getDetail = async ({
    pageSize,
    pageIndex,
    reportId,
    memberId,
  }: {
    pageSize: number;
    pageIndex: number;
    reportId: number;
    memberId: number | null;
  }) => {
    start();
    setLoading(true);
    const result = await ReportService.getDetail({
      sortNew: true,
      pageSize,
      pageIndex,
      reportId,
      memberId,
    });

    if (isRight(result)) {
      const memberList: IOption[] = [];
      result.right.members.forEach((member) => {
        memberList.push({
          label: member.username,
          value: member.id,
        } as IOption);
      });

      const memberExchangedList: IOption[] = [];
      result.right.members
        .filter(
          (x) =>
            !x.exchangeCurrency.details ||
            x.exchangeCurrency.details.length === 0,
        )
        .forEach((member) => {
          memberExchangedList.push({
            label: member.username,
            value: member.id,
          } as IOption);
        });

      fetchSuccess(
        result.right,
        customerList.length > 0 ? customerList : memberList,
        memberExchangedList,
      );
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    detail,
    openList,
    customerList,
    customerExchangedList,
    event: {
      getDetail,
      toggleOpen,
    },
  };
};

export default useReportDetailState;
