const TableHeaderReport = ({
  selectedColumn,
}: {
  selectedColumn: string[];
}) => {
  return (
    <thead className="border-separate ">
      <tr className="p-2 font-semibold">
        {selectedColumn.includes('ID') && (
          <th className="w-[50px] border border-solid border-neutral-300 p-2">
            #
          </th>
        )}
        {selectedColumn.includes('IF') && (
          <th className="w-[150px] border border-solid border-neutral-300 p-2">
            Thông tin
          </th>
        )}
        {selectedColumn.includes('GC') && (
          <th className="w-[130px] border border-solid border-neutral-300 p-2">
            GrossComm
          </th>
        )}
        {selectedColumn.includes('WL') && (
          <th className="w-[130px] border border-solid border-neutral-300 p-2">
            WinLoss
          </th>
        )}

        {selectedColumn.includes('VND') && (
          <th className="w-[130px] border border-solid border-neutral-300 p-2">
            VND
          </th>
        )}
        {selectedColumn.includes('USD') && (
          <th className="w-[130px] border border-solid border-neutral-300 p-2">
            USD
          </th>
        )}

        {selectedColumn.includes('EC') && (
          <th className="w-[80px] border border-solid border-neutral-300 p-2">
            Đổi tiền
          </th>
        )}
        <td className="w-[100px] border border-solid border-neutral-300 p-2 text-center">
          Khớp sổ
        </td>
      </tr>
    </thead>
  );
};

export default TableHeaderReport;
