// eslint-disable-next-line import/no-extraneous-dependencies
import { ConnectionCode, Message } from '../repositories/core/constant';

class CommonStatusCode {
  static getMessageConnectionCode(value: string) {
    switch (value) {
      case ConnectionCode.CAPTRA_INVALID:
        return Message.CAPTRA_INVALID;
      case ConnectionCode.CHANGE_PASSWORD:
        return Message.CHANGE_PASSWORD;
      case ConnectionCode.CODE_INVALID:
        return Message.CODE_INVALID;
      case ConnectionCode.DENIED_IP:
        return Message.DENIED_IP;
      case ConnectionCode.EMPTY_ROLE_CODE_PREFIX_CODE:
        return Message.EMPTY_ROLE_CODE_PREFIX_CODE;
      case ConnectionCode.FAILED:
        return Message.FAILED;
      case ConnectionCode.NOT_WINLOSS:
        return Message.NOT_WINLOSS;
      case ConnectionCode.PROCESSING:
        return Message.PROCESSING;
      case ConnectionCode.STOPPED:
        return Message.STOPPED;
      case ConnectionCode.SUCCESS:
        return Message.SUCCESS;
      case ConnectionCode.SUSPENDED:
        return Message.SUSPENDED;
      case ConnectionCode.SYSTEM_MAINTENANCE:
        return Message.SYSTEM_MAINTENANCE;
      case ConnectionCode.WAITING:
        return Message.WAITING;
      case ConnectionCode.WRONG_PASSWORD:
        return Message.WRONG_PASSWORD;

      default:
        return '';
    }
  }
}

export default CommonStatusCode;
