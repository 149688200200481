import {
  EXSBOCategoryList,
  HK1107CategoryList,
  IBETCategoryList,
  LASVEGASCategoryList,
  OK368CategoryList,
  ONE789CategoryList,
  PS3838CategoryList,
  S3IN1BETCategoryList,
  SBOCategoryList,
  SGD777CategoryList,
  SGS889CategoryList,
  SVENUSCategoryList,
  VN8899CategoryList,
  WONCategoryList,
} from '../repositories/core/config-company';
import { CompanyId, RoleCode, RoleList } from '../repositories/core/constant';
import { ICompanyCategory } from '../repositories/core/core.interface';

class CommonProccess {
  static GetLevelUserByValue(value: number) {
    const find = RoleList.find((x) => x.value === value);
    return find ?? null;
  }

  static GetUnderLevel(roleCode: string) {
    switch (roleCode) {
      case RoleCode.SSUPER:
        return RoleCode.SUPER;
      case RoleCode.SUPER:
        return RoleCode.MASTER;
      case RoleCode.MASTER:
        return RoleCode.AGENT;
      case RoleCode.AGENT:
        return RoleCode.MEMBER;
      case RoleCode.MEMBER:
        return '';
      case RoleCode.SHARE_HOLDER:
        return RoleCode.AGENT;
      default:
        return RoleCode.SUPER;
    }
  }

  static GetCategoryListByCompanyId = (
    companyId: number,
  ): ICompanyCategory[] => {
    switch (companyId) {
      case CompanyId.IBET:
        return IBETCategoryList;
      case CompanyId.EXIBET:
        return IBETCategoryList;
      case CompanyId.EXSBO:
        return EXSBOCategoryList;
      case CompanyId.SBO:
        return SBOCategoryList;
      case CompanyId.SVENUS:
        return SVENUSCategoryList;
      case CompanyId.LASVEGAS:
        return LASVEGASCategoryList;
      case CompanyId.ONE789:
        return ONE789CategoryList;
      case CompanyId.SGS889:
        return SGS889CategoryList;
      case CompanyId.HK1107:
        return HK1107CategoryList;
      case CompanyId.VN8899:
        return VN8899CategoryList;
      case CompanyId.SGD777:
        return SGD777CategoryList;
      case CompanyId.OK368:
        return OK368CategoryList;
      case CompanyId.S3IN1BET:
        return S3IN1BETCategoryList;
      case CompanyId.PS3838:
        return PS3838CategoryList;
      case CompanyId.WON:
        return WONCategoryList;
      default:
        return [];
    }
  };

  static GetCategoryListFromIds = (value: string, companyId: number) => {
    const list: ICompanyCategory[] = [];
    const ids = value ? value.split(',') : [];
    this.GetCategoryListByCompanyId(companyId).forEach((item) => {
      if (ids.find((x) => x === item.value)) {
        list.push(item);
      }
    });
    return list;
  };
}
export default CommonProccess;
