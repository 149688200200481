import { Either } from 'fp-ts/lib/Either';

import { ErrorType } from '../../repositories/core/error-type';
import {
  IPackage,
  IRegisterPackageInput,
  IUsedPackage,
} from '../../repositories/package/package.interface';
import PackageRepository from '../../repositories/package/package.repository';

class PackageService {
  static getList(): Promise<Either<ErrorType, IPackage[]>> {
    return PackageRepository.getList();
  }

  static create({
    userId,
    packageId,
  }: {
    userId: number;
    packageId: number;
  }): Promise<Either<ErrorType, boolean>> {
    const data = {
      userId,
      packageId,
    } as IRegisterPackageInput;
    return PackageRepository.create({ data });
  }

  static getPackageByUserId({
    userId,
  }: {
    userId: number;
  }): Promise<Either<ErrorType, IUsedPackage>> {
    return PackageRepository.getPackageByUserId({ userId });
  }
}

export default PackageService;
