import { useEffect, useState } from 'react';

import { Button, Drawer, Form, Input, Space } from 'antd';

import useAccountDetailState from './states/useAccountDetailComponentState';
import useAccountScanMethodListScanState from './states/useAccountScanMethodListScanState';
import useCustomAccountScanCreateState from './states/useCustomAccountScanCreateState';
import useCustomAccountScanDeleteState from './states/useCustomAccountScanDeleteState';
import TableAccountScanDetail from './table-account-scan';
import DrawerAccountScanMapMethodManage from '../../../components/DrawerAccountScanMapMethodManage/DrawerAccountScanMapMethodManage';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { IAccount } from '../../../repositories/account/account.interface';
import {
  IAccountScan,
  IAccountScanMethod,
} from '../../../repositories/account-scan/account-scan.interface';
import { drawerStyle, maxPageSize } from '../../../repositories/core/constant';
import CommonProccess from '../../../ultis/common-proccess';
import { match } from '../../../ultis/custom-matcher';

const DrawerAddCustomAccountScanComponent = ({
  isOpen,
  accountScan,
  onClose,
}: {
  isOpen: boolean;
  accountScan: IAccountScan;
  onClose: () => void;
}) => {
  const { event, isLoading, state } = useCustomAccountScanCreateState();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    event.createItem({
      accountId: accountScan.accountId,
      companyId: accountScan.companyId,
      parentId: accountScan.id,
      roleCode: CommonProccess.GetUnderLevel(accountScan.roleCode),
      username: values.username,
    });
  };

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Thêm tuyến dưới thành công!');
        form.resetFields();
        onClose();
      },
    });
  }, [state.type]);

  return (
    <Drawer
      title="Thêm tuyến dưới"
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <div className="grid grid-cols-1 gap-2">
          <div>
            <Form.Item
              name="username"
              label="Tuyến dưới"
              rules={[{ required: true, message: 'Nhập tên tuyến dưới' }]}
            >
              <Input placeholder="Nhập tên tuyến dưới" allowClear />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const AccountDetailComponent = ({ account }: { account: IAccount }) => {
  const { event, list, loadingAccountScanList } = useAccountDetailState();
  const { event: eventAccountScan, list: accountScans } =
    useAccountScanMethodListScanState();
  const {
    event: eventDelete,
    isLoading: isLoadingDelete,
    state: stateDelete,
  } = useCustomAccountScanDeleteState();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [
    isOpenAddCustomAccountScanDrawer,
    setIsOpenAddCustomAccountScanDrawer,
  ] = useState<boolean>(false);
  const [selectedAccountScan, setSelectedAccountScan] =
    useState<IAccountScanMethod | null>(null);

  const onFetchByParentId = (parentId: number) => {
    event.getList({
      pageSize: maxPageSize,
      pageIndex: 1,
      accountId: account.id,
      parentId,
    });
  };

  const onRemoveByParentId = (parentId: number) => {
    event.removeListByParentId({
      parentId,
    });
  };

  const onOpenMethodManage = (accountScan: IAccountScanMethod) => {
    setSelectedAccountScan(accountScan);
    setIsOpenDrawer(true);
  };

  const onCloseMethodManage = () => {
    setIsOpenDrawer(false);
    if (selectedAccountScan) {
      onFetchByParentId(selectedAccountScan.parentId);
      setTimeout(() => {
        setSelectedAccountScan(null);
      }, 1000);
    }
  };

  const onCloseAddCustomAccountScan = () => {
    setIsOpenAddCustomAccountScanDrawer(false);
    if (selectedAccountScan) {
      onFetchByParentId(selectedAccountScan.id);
      setTimeout(() => {
        setSelectedAccountScan(null);
      }, 1000);
    }
  };

  const onOpenAddCustomAccountScan = (accountScan: IAccountScanMethod) => {
    setSelectedAccountScan(accountScan);
    setIsOpenAddCustomAccountScanDrawer(true);
  };

  useEffect(() => {
    event.getAccountScanListByMethod({
      pageSize: maxPageSize,
      pageIndex: 1,
      accountId: account.id,
    });
  }, []);

  useEffect(() => {
    eventAccountScan.getList(list);
  }, [list]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess(res) {
        showSuccessDialog('Xóa tuyến dưới thành công!');
        onFetchByParentId(res.parentId);
      },
    });
  }, [stateDelete.type]);

  return (
    <>
      <div className="w-full overflow-auto">
        <div className="w-full min-w-[800px]">
          <TableAccountScanDetail
            companyId={account.companyId}
            loadingList={loadingAccountScanList}
            accountScans={accountScans}
            onFetchByParentId={onFetchByParentId}
            onRemoveByParentId={onRemoveByParentId}
            onOpenMethodManage={onOpenMethodManage}
            onOpenAddCustomAccountScan={onOpenAddCustomAccountScan}
            onDeleteCustomAccountScan={(accountScan) => {
              eventDelete.deleteItem({
                id: accountScan.id,
                parentId: accountScan.parentId,
              });
            }}
          />
        </div>
      </div>
      <DrawerAccountScanMapMethodManage
        isOpenDrawer={isOpenDrawer}
        onClose={onCloseMethodManage}
        accountScan={
          {
            id: selectedAccountScan?.id,
            username: selectedAccountScan?.username,
            accountId: selectedAccountScan?.accountId,
            companyId: selectedAccountScan?.companyId,
          } as IAccountScan
        }
      />
      <DrawerAddCustomAccountScanComponent
        accountScan={
          {
            id: selectedAccountScan?.id,
            username: selectedAccountScan?.username,
            accountId: selectedAccountScan?.accountId,
            companyId: selectedAccountScan?.companyId,
            parentId: selectedAccountScan?.parentId,
            roleCode: selectedAccountScan?.roleCode,
          } as IAccountScan
        }
        isOpen={isOpenAddCustomAccountScanDrawer}
        onClose={onCloseAddCustomAccountScan}
      />
    </>
  );
};

const DrawerAccountDetailComponent = ({
  isOpen,
  selected,
  onClose,
}: {
  isOpen: boolean;
  selected: IAccount | null;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Chi tiết sub quét"
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selected && <AccountDetailComponent account={selected} />}
    </Drawer>
  );
};

export default DrawerAccountDetailComponent;
