import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import {
  IPackage,
  IRegisterPackageInput,
  IUsedPackage,
} from './package.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class PackageRepository {
  static getList(): Promise<Either<ErrorType, IPackage[]>> {
    const query = () =>
      axiosClient<IPackage[]>({
        method: Methods.GET,
        url: '/v1/packages',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getPackageByUserId({
    userId,
  }: {
    userId: number;
  }): Promise<Either<ErrorType, IUsedPackage>> {
    const query = () =>
      axiosClient<IUsedPackage>({
        method: Methods.GET,
        url: `/v1/users/package-by/${userId}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IRegisterPackageInput;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.POST,
        url: `/v1/users/register-package`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }
}

export default PackageRepository;
