/* eslint-disable max-classes-per-file */
export const StatusCode = {
  Unauthorized: 401,
  BadRequest: 400,
  PaymentRequired: 402,
  NotFound: 404,
  MethodNotAllowed: 405,
};

interface WithKind {
  readonly kind: string;
}

export type SingleInvalidInputError = {
  field: string;
  message: string;
};

export class InvalidInputError extends Error implements WithKind {
  kind: 'InvalidInputError' = 'InvalidInputError';

  errors: SingleInvalidInputError[];

  constructor(message: string, errors: SingleInvalidInputError[]) {
    super(message);
    this.errors = errors;
    this.message = message;
  }
}
export class UnauthorizedError extends Error implements WithKind {
  kind: 'UnauthorizedError' = 'UnauthorizedError';
}
export class PaymentError extends Error implements WithKind {
  kind: 'PaymentError' = 'PaymentError';
}
export class NotAllowedError extends Error implements WithKind {
  kind: 'NotAllowedError' = 'NotAllowedError';
}
export class NotFoundError extends Error implements WithKind {
  kind: 'NotFoundError' = 'NotFoundError';
}
export class RateLimitedError extends Error implements WithKind {
  kind: 'RateLimitedError' = 'RateLimitedError';
}
export class InvalidCredentialsError extends Error implements WithKind {
  kind: 'InvalidCredentialsError' = 'InvalidCredentialsError';
}
export class ServerError extends Error implements WithKind {
  kind: 'ServerError' = 'ServerError';
}
export class NetworkError extends Error implements WithKind {
  kind: 'NetworkError' = 'NetworkError';
}
export class TimeoutError extends Error implements WithKind {
  kind: 'TimeoutError' = 'TimeoutError';
}
export class DefinedError extends Error implements WithKind {
  kind: 'DefinedError' = 'DefinedError';
}
export class OtherError extends Error implements WithKind {
  kind: 'OtherError' = 'OtherError';

  category: string;

  constructor(message: string, category: string) {
    super(message);
    this.category = category;
    this.message = message;
  }
}
export class GraphDocumentError extends Error implements WithKind {
  kind: 'GraphDocumentError' = 'GraphDocumentError';
}
export class UnexpectedError extends Error implements WithKind {
  kind: 'UnexpectedError' = 'UnexpectedError';
}
export type ErrorType =
  | InvalidInputError
  | UnauthorizedError
  | PaymentError
  | NotAllowedError
  | NotFoundError
  | RateLimitedError
  | InvalidCredentialsError
  | ServerError
  | NetworkError
  | TimeoutError
  | DefinedError
  | OtherError
  | GraphDocumentError
  | UnexpectedError;
