// eslint-disable-next-line import/no-extraneous-dependencies

import { useEffect, useState } from 'react';

import { Progress } from 'antd';

import { IAccount } from '../../../repositories/account/account.interface';
import FormatDuration from '../../../ultis/format-duration';

const CountUpScanComponent = ({
  isScanning,
  totalScanning,
  processingList,
}: {
  isScanning: boolean;
  totalScanning: number;
  processingList: IAccount[];
}) => {
  const [intervalCount, setIntervalCount] = useState<any>();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (isScanning) {
      clearInterval(intervalCount);
      setCount(0);
      setIntervalCount(
        setInterval(() => setCount((prevState) => prevState + 1), 1000),
      );
    } else {
      clearInterval(intervalCount);
    }
  }, [isScanning]);

  return (
    <div className="flex">
      <Progress
        percent={(1 - processingList.length / totalScanning) * 100}
        status="active"
        showInfo={false}
      />
      <div>
        <span>{FormatDuration.convertSecondToDuration(count)}</span>
      </div>
    </div>
  );
};

export default CountUpScanComponent;
