import { useEffect, useState } from 'react';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Breadcrumb,
  Form,
  FormInstance,
  Select,
  Tooltip,
  TablePaginationConfig,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import useMethodDeleteState from './states/useMethodDeleteState';
import useMethodListState from './states/useMethodListState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { ICategoryMethod } from '../../../repositories/category-method/category-method.interface';
import { ICompany } from '../../../repositories/company/company.interface';
import {
  defaultPageSize,
  maxPageSize,
} from '../../../repositories/core/constant';
import { IOption } from '../../../repositories/core/core.interface';
import { IMethod } from '../../../repositories/method/method.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';
// eslint-disable-next-line import/no-named-as-default
import DrawerCreateUpdateMethodComponent from '../components/create-update-method/DrawerCreateUpdateMethodComponent';
import useCategoryMethodListState from '../components/create-update-method/states/useCategoryMethodListState';

const TableList = ({
  isLoading,
  list,
  form,
  companys,
  categories,
  totalItems,
  current,
  onDelete,
  onUpdate,
  onOpenDrawer,
  onSubmitSearch,
  onChangeCompanyId,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IMethod[];
  form: FormInstance<any>;
  companys: ICompany[];
  categories: ICategoryMethod[];
  totalItems: number;
  current: number;
  onDelete: (item: IMethod) => void;
  onUpdate: (item: IMethod) => void;
  onOpenDrawer: () => void;
  onSubmitSearch: (values: any) => void;
  onChangeCompanyId: (values: any) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IMethod> = [
    {
      title: 'Công thức',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
    },

    {
      title: 'Loại',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: 'Công ty',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 140,
    },

    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IMethod) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: IMethod) => (
        <div className="flex items-center justify-center gap-2">
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Xóa khách hàng">
            <Popconfirm
              title="Xóa khách hàng này"
              description="Bạn có chắc chắn xóa khách hàng này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form onFinish={onSubmitSearch} form={form} layout="vertical">
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-5">
          <div>
            <Form.Item name="name">
              <Input placeholder="Nhập tên công thức" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="companyId">
              <Select
                placeholder="Chọn Công ty"
                style={{ width: '100%' }}
                onChange={onChangeCompanyId}
                options={companys.map((x) => {
                  return { value: x.id.toString(), label: x.name } as IOption;
                })}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="categoryId">
              <Select
                placeholder={'Chọn công thức'}
                disabled={categories.length === 0}
                style={{ width: '100%' }}
                options={categories.map((x) => {
                  return { value: x.id.toString(), label: x.name } as IOption;
                })}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item>
              <Button
                className="w-full"
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Form.Item>
              <Button onClick={onOpenDrawer} className="w-full" type="primary">
                Thêm công thức
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        scroll={{ x: 1000 }}
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Quản lý công thức',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Quản lý công thức</h2>
    </div>
  );
};

const MethodListPage = () => {
  const { state, event, isLoading, list, totalItems } = useMethodListState();

  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useMethodDeleteState();

  const { list: companys } = useCompanyListState();
  const {
    event: eventCategory,
    isLoading: isLoadingCategory,
    list: categories,
  } = useCategoryMethodListState();
  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedMethod, setSelectedMethod] = useState<IMethod | null>(null);
  const [isOpenCreateUpdate, setIsOpenCreateUpdate] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      categoryId: formSearch.getFieldValue('categoryId'),
      companyId: formSearch.getFieldValue('companyId'),
      name: formSearch.getFieldValue('name'),
    });
  };

  const onConfirmDelete = (item: IMethod) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onSelectedUpdate = (value: IMethod) => {
    setIsOpenCreateUpdate(true);
    setSelectedMethod(value);
  };

  const onSubmitSearch = () => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  };

  const onOpenCreateUpdate = () => {
    setIsOpenCreateUpdate(false);
    setSelectedMethod(null);
  };

  const onChangeCompanyId = (value: number) => {
    formSearch.setFieldValue('categoryId', null);
    eventCategory.getList({
      pageSize: maxPageSize,
      pageIndex: 1,
      companyId: value,
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa công thức thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <HeaderList />
      <TableList
        form={formSearch}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        companys={companys}
        categories={categories}
        isLoading={isLoading}
        onSubmitSearch={onSubmitSearch}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsOpenCreateUpdate(true)}
        onChangeCompanyId={onChangeCompanyId}
        onChangePagination={onChangePagination}
      />
      <DrawerCreateUpdateMethodComponent
        selected={selectedMethod}
        isClose={isOpenCreateUpdate}
        onClose={onOpenCreateUpdate}
        onReload={() => onLoad({})}
      />
    </div>
  );
};

export default MethodListPage;
