import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Checkbox, Tooltip, Popconfirm, Button } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import classNames from 'classnames';

import CustomFormatNumberReport from '../../../../components/CustomFormatNumberReport';
import {
  IReportMember,
  IExchangeCurrencyList,
  IExchangeCurrency,
  IReportAccountScan,
  IReportCategory,
} from '../../../../repositories/report/report.interface';

const TableRowCategory = ({
  accountScan,
  category,
  selectedColumn,
}: {
  selectedColumn: string[];
  accountScan: IReportAccountScan;
  category: IReportCategory;
}) => {
  return (
    <tr className="hover:bg-slate-100">
      {selectedColumn.includes('ID') && (
        <td className=" border border-solid border-neutral-300 text-left align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('IF') && (
        <td className=" border border-solid border-neutral-300 p-1 align-middle text-xs">
          <div
            className={classNames(
              'h-full w-full cursor-pointer items-center pl-8',
            )}
          >
            <p className="inline-block  pl-1 uppercase">
              {accountScan.username}-{category.configScanUserName}
            </p>
          </div>
        </td>
      )}
      {selectedColumn.includes('GC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              category.grossComm > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={category.grossComm}
          />
        </td>
      )}
      {selectedColumn.includes('WL') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              category.winlossMember > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={category.winlossMember}
          />
        </td>
      )}
      {selectedColumn.includes('VND') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              category.vnd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={category.vnd}
          />
        </td>
      )}
      {selectedColumn.includes('USD') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              category.usd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={category.usd}
          />
        </td>
      )}
      {selectedColumn.includes('EC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
    </tr>
  );
};

const TableRowAccountScan = ({
  member,
  accountScan,
  openList,
  selectedColumn,
  onToggleOpen,
  onShowCompare,
}: {
  member: IReportMember;
  accountScan: IReportAccountScan;
  openList: string[];
  selectedColumn: string[];
  onToggleOpen: (key: string) => void;
  onShowCompare: (memberId: number, accountScan: string) => void;
}) => {
  const keyString = `${member.id.toString()}-${accountScan.id.toString()}`;
  return (
    <tr className="hover:bg-slate-100">
      {selectedColumn.includes('ID') && (
        <td className="border border-solid border-neutral-300 text-left align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('IF') && (
        <td className=" border border-solid border-neutral-300 p-1 align-middle text-xs">
          <div
            className={classNames(
              'h-full w-full cursor-pointer items-center pl-4',
            )}
            onClick={() => onToggleOpen(keyString)}
          >
            {accountScan.categorys && (
              <>
                <div className="inline-block h-[20px] w-[14px]">
                  {openList.includes(keyString) ? (
                    <MinusSquareOutlined />
                  ) : (
                    <PlusSquareOutlined />
                  )}
                </div>
              </>
            )}

            <p className="inline-block  pl-1 uppercase">
              {accountScan.username}
            </p>
          </div>
        </td>
      )}
      {selectedColumn.includes('GC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              accountScan.grossComm > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={accountScan.grossComm}
          />
        </td>
      )}
      {selectedColumn.includes('WL') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              accountScan.winlossMember > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={accountScan.winlossMember}
          />
        </td>
      )}
      {selectedColumn.includes('VND') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              accountScan.vnd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={accountScan.vnd}
          />
        </td>
      )}
      {selectedColumn.includes('USD') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              accountScan.usd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={accountScan.usd}
          />
        </td>
      )}
      {selectedColumn.includes('EC') && (
        <td className="border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      <td className="border border-solid border-neutral-300 text-center align-middle  font-semibold">
        {accountScan.matchValue === 1 && (
          <Button
            onClick={() => onShowCompare(member.id, accountScan.username)}
            size="small"
            type="primary"
            className="text-xs"
          >
            <span className="text-xs">Khớp</span>
          </Button>
        )}
        {accountScan.matchValue === 2 && (
          <Button size="small" type="primary" className="text-xs">
            <span className="text-xs">Không khớp</span>
          </Button>
        )}
      </td>
    </tr>
  );
};

const TableRowMember = ({
  member,
  order,
  openList,
  selectedColumn,
  checkedExchange,
  onToggleOpen,
}: {
  member: IReportMember;
  order: number;
  openList: string[];
  selectedColumn: string[];
  checkedExchange: CheckboxValueType[];
  onToggleOpen: (key: string) => void;
}) => {
  const keyString = member.id.toString();
  return (
    <tr className="hover:bg-slate-100">
      {selectedColumn.includes('ID') && (
        <td className="border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          {order}
        </td>
      )}
      {selectedColumn.includes('IF') && (
        <td className="border border-solid border-neutral-300 p-1 align-middle text-xs">
          <div
            className={classNames(
              'flex h-[35px] w-full cursor-pointer items-center',
            )}
            onClick={() => onToggleOpen(keyString)}
          >
            {member.accountScans && (
              <>
                <div className="inline-block h-[20px] w-[14px]">
                  {openList.includes(keyString) ? (
                    <MinusSquareOutlined />
                  ) : (
                    <PlusSquareOutlined />
                  )}
                </div>
              </>
            )}

            <p className="inline-block  pl-1 uppercase">{member.username}</p>
          </div>
        </td>
      )}

      {selectedColumn.includes('GC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              member.grossComm > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={member.grossComm}
          />
        </td>
      )}
      {selectedColumn.includes('WL') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              member.winlossMember > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={member.winlossMember}
          />
        </td>
      )}
      {selectedColumn.includes('VND') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              member.vnd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={member.vnd}
          />
        </td>
      )}

      {selectedColumn.includes('USD') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              member.usd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={member.usd}
          />
        </td>
      )}

      {selectedColumn.includes('EC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          {member.exchangeCurrency &&
          member.exchangeCurrency.details &&
          member.exchangeCurrency.details.length > 0 ? (
            <></>
          ) : (
            <>
              <Checkbox
                checked={checkedExchange.includes(member.id) ?? false}
                value={member.id}
                disabled={checkedExchange.includes(0)}
              ></Checkbox>
            </>
          )}
        </td>
      )}
      <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
    </tr>
  );
};

const TableRowExchange = ({
  exchange,
  selectedColumn,
}: {
  exchange: IExchangeCurrency;
  selectedColumn: string[];
}) => {
  return (
    <tr className="hover:bg-slate-100">
      {selectedColumn.includes('ID') && (
        <td className="border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('IF') && (
        <td className="border border-solid border-neutral-300 p-1 align-middle text-xs">
          <div
            className={classNames(
              'h-full w-full cursor-pointer items-center pl-4',
            )}
          >
            <p className="inline-block  pl-1 uppercase">Đổi tiền</p>
          </div>
        </td>
      )}

      {selectedColumn.includes('GC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('WL') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('VND') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              exchange.vnd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={exchange.vnd}
          />
        </td>
      )}

      {selectedColumn.includes('USD') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              exchange.usd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={exchange.usd}
          />
        </td>
      )}

      {selectedColumn.includes('EC') && (
        <td className="  border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
    </tr>
  );
};

const TableRowExchangeCurrency = ({
  exchangeCurrency,
  selectedColumn,
  onDelete,
}: {
  exchangeCurrency: IExchangeCurrencyList;
  selectedColumn: string[];
  onDelete: (value: IExchangeCurrencyList) => void;
}) => {
  return (
    <tr className="hover:bg-slate-100">
      {selectedColumn.includes('ID') && (
        <td className="border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('IF') && (
        <td className="border border-solid border-neutral-300 p-1 align-middle text-xs">
          <div
            className={classNames(
              'h-full w-full cursor-pointer items-center pl-8',
            )}
          >
            <p className="inline-block  pl-1 uppercase">
              {exchangeCurrency.title}
            </p>
          </div>
        </td>
      )}

      {selectedColumn.includes('GC') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('WL') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
      )}
      {selectedColumn.includes('VND') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              exchangeCurrency.vnd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={exchangeCurrency.vnd}
          />
        </td>
      )}

      {selectedColumn.includes('USD') && (
        <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <CustomFormatNumberReport
            className={classNames(
              exchangeCurrency.usd > 0
                ? 'font-semibold '
                : 'font-semibold text-state-negative',
            )}
            value={exchangeCurrency.usd}
          />
        </td>
      )}
      {selectedColumn.includes('EC') && (
        <td className="  border border-solid border-neutral-300 text-center align-middle text-xs font-semibold">
          <Tooltip title="Xóa đổi tiền">
            <Popconfirm
              title="Xóa đổi tiền này"
              description="Bạn có chắc chắn xóa đổi tiền này?"
              onConfirm={() => onDelete(exchangeCurrency)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </td>
      )}
      <td className=" border border-solid border-neutral-300 text-center align-middle text-xs font-semibold"></td>
    </tr>
  );
};

const TableRowAccountScanReport = ({
  member,
  order,
  openList,
  selectedColumn,
  checkedExchange,
  onToggleOpen,
  onDelete,
  onShowCompare,
}: {
  member: IReportMember;
  order: number;
  openList: string[];
  selectedColumn: string[];
  checkedExchange: CheckboxValueType[];
  onToggleOpen: (memberId: string) => void;
  onDelete: (value: IExchangeCurrencyList) => void;
  onShowCompare: (memberId: number, accountScan: string) => void;
}) => {
  return (
    <>
      <TableRowMember
        selectedColumn={selectedColumn}
        key={`${member.id}`}
        member={member}
        order={order}
        openList={openList}
        checkedExchange={checkedExchange}
        onToggleOpen={onToggleOpen}
      />
      {openList.includes(member.id.toString()) &&
        member.accountScans.map((accountScan) => {
          return (
            <>
              <TableRowAccountScan
                selectedColumn={selectedColumn}
                key={`${accountScan.id}`}
                accountScan={accountScan}
                openList={openList}
                member={member}
                onToggleOpen={onToggleOpen}
                onShowCompare={onShowCompare}
              />
              {openList.includes(
                `${member.id.toString()}-${accountScan.id.toString()}`,
              ) &&
                accountScan.categorys.map((category) => {
                  return (
                    <TableRowCategory
                      selectedColumn={selectedColumn}
                      key={`${member.id.toString()}-${accountScan.id.toString()}`}
                      category={category}
                      accountScan={accountScan}
                    />
                  );
                })}
            </>
          );
        })}
      {member.exchangeCurrency.details &&
        member.exchangeCurrency.details.length > 0 &&
        openList.includes(member.id.toString()) && (
          <>
            <TableRowExchange
              selectedColumn={selectedColumn}
              key={`${member.id}`}
              exchange={member.exchangeCurrency}
            />
            {member.exchangeCurrency.details.map((exchange) => (
              <TableRowExchangeCurrency
                selectedColumn={selectedColumn}
                key={`${member.id}`}
                exchangeCurrency={exchange}
                onDelete={onDelete}
              />
            ))}
          </>
        )}
    </>
  );
};

export default TableRowAccountScanReport;
