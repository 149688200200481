import TabbarConfigComponent from './components/tabbar';

const ConfigPage = () => {
  return (
    <div className="p-2">
      <TabbarConfigComponent />
    </div>
  );
};

export default ConfigPage;
