import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { IScanConfig } from './config.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class ConfigRepository {
  static getScanConfig({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IScanConfig>>> {
    const query = () =>
      axiosClient<PageItems<IScanConfig>>({
        method: Methods.GET,
        url: '/v1/config-scan-users/get-list-detail-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IScanConfig;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const query = () =>
      axiosClient<IScanConfig>({
        method: Methods.POST,
        url: '/v1/config-scan-users',
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static update({
    data,
  }: {
    data: IScanConfig;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const query = () =>
      axiosClient<IScanConfig>({
        method: Methods.PUT,
        url: '/v1/config-scan-users',
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/config-scan-users/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static reset({
    companyId,
  }: {
    companyId: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.POST,
        url: `/v1/config-scan-users/reset-default?companyId=${companyId}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getConfigScanUserIdBy({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const query = () =>
      axiosClient<IScanConfig>({
        method: Methods.GET,
        url: `/v1/config-scan-users/get-detail-by-category`,
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default ConfigRepository;
