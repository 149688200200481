// eslint-disable-next-line import/no-extraneous-dependencies
import format from 'format-number';

class CustomNumber {
  static formatNumber(value: number) {
    return format({ integerSeparator: ',', padRight: 2, round: 2 })(value);
  }
}

export default CustomNumber;
