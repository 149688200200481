import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import { IGroupMethod } from '../../repositories/group-method/group-method.interface';
import GroupMethodRepository from '../../repositories/group-method/group-method.repository';
import { IMethod } from '../../repositories/method/method.interface';

class GroupMethodService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
    name,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    name?: string;
  }): Promise<Either<ErrorType, PageItems<IGroupMethod>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      name: name ?? '',
    });
    return GroupMethodRepository.getList({ params });
  }

  static create({
    name,
    description,
  }: {
    name: string;
    description: string;
  }): Promise<Either<ErrorType, IGroupMethod>> {
    const variables = {
      name,
      description,
    } as IGroupMethod;
    return GroupMethodRepository.create({ data: variables });
  }

  static update({
    id,
    name,
    description,
  }: {
    id: number;
    name: string;
    description: string;
  }): Promise<Either<ErrorType, IGroupMethod>> {
    const variables = {
      id,
      name,
      description,
    } as IGroupMethod;
    return GroupMethodRepository.update({ data: variables });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return GroupMethodRepository.delete({ id });
  }

  static getMethodGroupDeliverReceiveList({
    sortNew,
    isDeliver,
    companyId,
  }: {
    sortNew: boolean;
    isDeliver: boolean;
    companyId: number;
  }): Promise<Either<ErrorType, IGroupMethod[]>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      IsDeliver: isDeliver.toString(),
      companyId: companyId.toString(),
    });
    return GroupMethodRepository.getMethodGroupDeliverReceiveList({ params });
  }

  static getMethodListOfGroup({
    sortNew,
    pageSize,
    pageIndex,
    methodGroupId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    methodGroupId: number;
  }): Promise<Either<ErrorType, PageItems<IMethod>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      MethodGroupId: methodGroupId.toString(),
    });
    return GroupMethodRepository.getMethodListOfGroup({ params });
  }

  static addToGroup({
    methodId,
    methodGroupId,
  }: {
    methodId: number;
    methodGroupId: number;
  }): Promise<Either<ErrorType, boolean>> {
    return GroupMethodRepository.addToGroup({ methodId, methodGroupId });
  }

  static deleteMethodFromGroup({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    return GroupMethodRepository.deleteMethodFromGroup({ id });
  }
}

export default GroupMethodService;
