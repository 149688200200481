import { useEffect, useState } from 'react';

import { Table, Breadcrumb, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

import useHistoryListState from './states/useHistoryListState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { defaultPageSize } from '../../../repositories/core/constant';
import { IHistory } from '../../../repositories/history/history.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';

const TableList = ({
  isLoading,
  list,
  totalItems,
  current,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IHistory[];
  totalItems: number;
  current: number;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IHistory> = [
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: 180,
      fixed: 'left',
    },
    {
      title: 'IP',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
    {
      title: 'Trình duyệt',
      dataIndex: 'browser',
      key: 'browser',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IHistory) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        scroll={{ x: 1000 }}
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderPageList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Lịch sử',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Lịch sử</h2>
    </div>
  );
};

const HistoryListPage = () => {
  const { state, event, isLoading, list, totalItems } = useHistoryListState();

  const { showErrorDialog } = useErrorHandler();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,

      pageIndex: current ?? currentPage,
    });
  };

  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  return (
    <div className="w-full p-2">
      <HeaderPageList />
      <TableList
        list={list}
        totalItems={totalItems}
        current={currentPage}
        isLoading={isLoading}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default HistoryListPage;
