import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import {
  IInputExchangeCurrency,
  IReport,
  IReportDetail,
  IReportExport,
  IReportCompanyDetail,
  IReportCompare,
} from './report.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class ReportRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IReport>>> {
    const query = () =>
      axiosClient<PageItems<IReport>>({
        method: Methods.GET,
        url: '/v1/reports/get-list-detail-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    name,
    description,
    isQuick,
    fromDate,
    toDate,
    arrayAccountId,
  }: {
    name: string;
    description: string;
    isQuick: boolean;
    fromDate: string;
    toDate: string;
    arrayAccountId: number[];
  }): Promise<Either<ErrorType, number>> {
    const query = () =>
      axiosClient<number>({
        method: Methods.POST,
        url: `/v1/reports/add-by-array-account`,
        data: {
          name,
          description,
          isQuick,
          fromDate,
          toDate,
          arrayAccountId: arrayAccountId.join(','),
        },
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/reports/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static export({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, IReportExport>> {
    const query = () =>
      axiosClient<IReportExport>({
        method: Methods.POST,
        url: `/v1/reports/export-report-pdf/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static getDetail({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IReportDetail>> {
    const query = () =>
      axiosClient<IReportDetail>({
        method: Methods.GET,
        url: '/v1/reports/get-list-report-detail-member-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getDetailMarketCodeBy({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IReportCompanyDetail>> {
    const query = () =>
      axiosClient<IReportCompanyDetail>({
        method: Methods.GET,
        url: '/v1/reports/get-list-report-detail-member-marketcode-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static exchangeCurrency({
    data,
  }: {
    data: IInputExchangeCurrency;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.POST,
        url: `/v1/reports/add-exchange-currency`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static deleteExchangeCurrency({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/reports/delete-exchange-currency`,
        data: {
          ExchangeCurrencies: [
            {
              id,
            },
          ],
        },
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static getMethodsCompare({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IReportCompare[]>> {
    const query = () =>
      axiosClient<IReportCompare[]>({
        method: Methods.GET,
        url: '/v1/reports/get-report-detail-method-match-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static exportExcel({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, string>> {
    const query = () =>
      axiosClient<any>({
        method: Methods.POST,
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        url: `/v1/reports/export-report-excel/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default ReportRepository;
