import { useEffect, useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Space,
  Button,
  Input,
  Popconfirm,
  Table,
  Breadcrumb,
  Form,
  Drawer,
  FormInstance,
  Tooltip,
  TablePaginationConfig,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import useCustomerCreateState from './states/useGroupMethodCreateState';
import useAccountDeleteState from './states/useGroupMethodDeleteState';
import useGroupMethodListState from './states/useGroupMethodListState';
import useCustomerUpdateState from './states/useGroupMethodUpdateState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import {
  defaultPageSize,
  drawerStyle,
} from '../../../repositories/core/constant';
import { IGroupMethod } from '../../../repositories/group-method/group-method.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import GroupMethodDetailPage from '../detail/group-method-detail';

const DrawerAddEditGroupMethodComponent = ({
  form,
  isClose,
  isLoading,
  onSubmit,
  onClose,
}: {
  form: FormInstance<any>;
  isClose: boolean;
  isLoading: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={onClose}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <div className="grid grid-cols-1 gap-2">
          <div>
            <Form.Item
              name="name"
              label="Nhóm"
              rules={[{ required: true, message: 'Nhập tên Nhóm' }]}
            >
              <Input placeholder="Nhập tên Nhóm" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="description" label="Mô tả">
              <Input.TextArea
                rows={4}
                placeholder="please enter url description"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const TableList = ({
  isLoading,
  list,
  form,
  totalItems,
  current,
  onDelete,
  onUpdate,
  onOpenDrawer,
  onSubmitSearch,
  onRowClick,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IGroupMethod[];
  form: FormInstance<any>;
  totalItems: number;
  current: number;
  onDelete: (item: IGroupMethod) => void;
  onUpdate: (item: IGroupMethod) => void;
  onOpenDrawer: () => void;
  onSubmitSearch: (values: any) => void;
  onRowClick?: (record: IGroupMethod) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IGroupMethod> = [
    {
      title: 'Nhóm công thức',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      fixed: 'left',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IGroupMethod) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 200,
      render: (record: IGroupMethod) => (
        <div className="flex gap-2">
          <Button
            type="primary"
            onClick={() => onRowClick && onRowClick(record)}
            size="small"
          >
            Thêm công thức
          </Button>
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Xóa nhóm">
            <Popconfirm
              title="Xóa nhóm này"
              description="Bạn có chắc chắn xóa nhóm này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form onFinish={onSubmitSearch} form={form} layout="vertical">
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
          <div>
            <Form.Item name="name">
              <Input placeholder="Nhập tên nhóm" allowClear />
            </Form.Item>
          </div>

          <div className="">
            <Button
              className="w-full"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Button onClick={onOpenDrawer} className="w-full" type="primary">
              Thêm nhóm
            </Button>
          </div>
        </div>
      </Form>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        scroll={{ x: 700 }}
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Quản lý nhóm công thức',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">
        Quản lý nhóm công thức
      </h2>
    </div>
  );
};

const GroupMethodListPage = () => {
  const { state, event, isLoading, list, totalItems } =
    useGroupMethodListState();
  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useCustomerCreateState();
  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useCustomerUpdateState();
  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useAccountDeleteState();
  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedGroup, setSelectedGroup] = useState<IGroupMethod | null>(null);
  const [selectedGroupDetail, setSelectedGroupDetail] =
    useState<IGroupMethod | null>(null);
  const [isCloseCreateUpdate, setIsCloseCreateUpdate] =
    useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      name: formSearch.getFieldValue('name'),
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  const onConfirmDelete = (item: IGroupMethod) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onCloseCreateUpdate = () => {
    setIsCloseCreateUpdate(false);
    setSelectedGroup(null);
    form.resetFields();
  };
  const onSelectedUpdate = (value: IGroupMethod) => {
    form.setFieldsValue(value);
    setIsCloseCreateUpdate(true);
    setSelectedGroup(value);
  };
  const onSubmitCreateUpdate = (values: IGroupMethod) => {
    if (selectedGroup && selectedGroup.id) {
      eventUpdate.updateItem({
        id: selectedGroup.id,
        name: values.name,
        description: values.description,
      });
    } else {
      eventCreate.createItem({
        name: values.name,
        description: values.description,
      });
    }
  };

  const onSubmitSearch = () => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  };

  const onCloseMethodList = () => {
    setSelectedGroupDetail(null);
  };

  const onRowClick = (record: IGroupMethod) => {
    setSelectedGroupDetail(record);
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Tạo nhóm công thức thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess() {
        showSuccessDialog('Cập nhật nhóm công thức thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateUpdate.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa nhóm công thức thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <HeaderList />
      <TableList
        form={formSearch}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        isLoading={isLoading}
        onSubmitSearch={onSubmitSearch}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsCloseCreateUpdate(true)}
        onRowClick={onRowClick}
        onChangePagination={onChangePagination}
      />
      <DrawerAddEditGroupMethodComponent
        form={form}
        isClose={isCloseCreateUpdate}
        onClose={onCloseCreateUpdate}
        onSubmit={onSubmitCreateUpdate}
        isLoading={!!(isLoadingCreate || isLoadingUpdate)}
      />
      <Drawer
        title={selectedGroupDetail?.name}
        width={'100%'}
        onClose={onCloseMethodList}
        open={!!selectedGroupDetail}
        headerStyle={drawerStyle}
        bodyStyle={drawerStyle}
        placement={'right'}
        extra={
          <Space>
            <Button onClick={onCloseMethodList}>Đóng</Button>
          </Space>
        }
      >
        {selectedGroupDetail?.id && (
          <GroupMethodDetailPage methodGroupId={selectedGroupDetail?.id} />
        )}
      </Drawer>
    </div>
  );
};

export default GroupMethodListPage;
