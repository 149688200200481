import React from 'react';

import { RouteObject } from 'react-router-dom';

import LoadingPage from '../components/LoadingPage';
import AccountListPage from '../pages/account/list';
import ConfigPage from '../pages/config';
import CustomerListPage from '../pages/customer/list';
import GroupMethodListPage from '../pages/group-method/list/group-method-list';
import HistoryListPage from '../pages/history';
import HomePage from '../pages/home';
import MethodListPage from '../pages/method/list';
import ReportListPage from '../pages/report/list';
import ScanPage from '../pages/scan/scan-list';
import UserListPage from '../pages/user';
import AuthenticatedWrapper from '../wrappers/auth';
import CompanyWrapper from '../wrappers/company';

const authRoutes: RouteObject[] = [
  {
    element: <AuthenticatedWrapper />,
    children: [
      {
        element: <CompanyWrapper />,
        children: [
          {
            path: '/',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <HomePage />
              </React.Suspense>
            ),
          },
          {
            path: '/scan',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <ScanPage />
              </React.Suspense>
            ),
          },
          {
            path: '/account',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <AccountListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/method',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <MethodListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/group-method',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <GroupMethodListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/customer',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <CustomerListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/history',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <HistoryListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/user',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <UserListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/report',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <ReportListPage />
              </React.Suspense>
            ),
          },
          {
            path: '/config',
            element: (
              <React.Suspense fallback={<LoadingPage />}>
                <ConfigPage />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export default authRoutes;
