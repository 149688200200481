import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { ICompany } from './company.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class CompanyRepository {
  static getList(): Promise<Either<ErrorType, ICompany[]>> {
    const query = () =>
      axiosClient<ICompany[]>({
        method: Methods.GET,
        url: '/v1/companys',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default CompanyRepository;
