import { isRight } from 'fp-ts/lib/Either';
// eslint-disable-next-line lodash/import-scope
import { create } from 'zustand';

import { IAccount } from '../../../repositories/account/account.interface';
import { ICompany } from '../../../repositories/company/company.interface';
import { ConnectionCode } from '../../../repositories/core/constant';
import { ErrorType } from '../../../repositories/core/error-type';
import AccountService from '../../../services/account/account.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'setList'; list: IAccount[] }
    | { type: 'setListActive'; listActive: IAccount[] }
    | { type: 'setCompanyList'; list: ICompany[] }
    | { type: 'setCompanyListActive'; listActive: ICompany[] };
  isLoading: boolean;
  list: IAccount[];
  listActive: IAccount[];
  companyList: ICompany[];
  companyListActive: ICompany[];
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  setList: (list: IAccount[]) => void;
  setListActive: (list: IAccount[]) => void;
  setCompanyList: (list: ICompany[]) => void;
  setCompanyListActive: (list: ICompany[]) => void;
};

const createStore = create<IState>((set) => ({
  state: { type: 'init' },
  isLoading: false,
  list: [],
  listActive: [],
  companyList: [],
  companyListActive: [],
  start: () => set({ state: { type: 'start' } }),
  setLoading: (value) => set({ isLoading: value }),
  fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
  setList: (list) => set({ state: { type: 'setList', list }, list }),
  setCompanyList: (list) =>
    set({ state: { type: 'setCompanyList', list }, companyList: list }),

  setListActive: (list) =>
    set({
      state: { type: 'setListActive', listActive: list },
      listActive: list,
    }),
  setCompanyListActive: (list) =>
    set({
      state: { type: 'setCompanyListActive', listActive: list },
      companyListActive: list,
    }),
}));

const useAccountListScanState = () => {
  const {
    state,
    isLoading,
    list,
    listActive,
    companyList,
    companyListActive,
    start,
    setLoading,
    fetchFailed,
    setList,
    setListActive,
    setCompanyList,
    setCompanyListActive,
  } = createStore();

  const getList = async ({
    pageSize,
    pageIndex,
    username,
    prefixCode,
    companyId,
  }: {
    pageSize: number;
    pageIndex: number;
    username?: string;
    prefixCode?: string;
    companyId?: number;
  }) => {
    start();
    setLoading(true);
    const result = await AccountService.getList({
      sortNew: true,
      pageSize,
      pageIndex,
      isDelete: false,
      username,
      prefixCode,
      companyId,
    });
    if (isRight(result)) {
      const convert = result.right.items.map((x) => {
        return { ...x, connectionCode: ConnectionCode.WAITING };
      });
      setListActive(convert);
      setList(convert);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  const toggleCheckAccount = ({
    isChecked,
    account,
  }: {
    isChecked: boolean;
    account: IAccount;
  }) => {
    let cloneAccountList = [...listActive];
    if (isChecked) {
      cloneAccountList.push(account);
    } else {
      cloneAccountList = cloneAccountList.filter((x) => x.id !== account.id);
    }
    setListActive(cloneAccountList);
  };

  const toggleCheckCompany = ({
    isChecked,
    companyId,
  }: {
    isChecked: boolean;
    companyId: number;
  }) => {
    let cloneAccountList = [...listActive];
    let cloneCompanyList = [...companyListActive];
    if (isChecked) {
      const findCompany = companyList.find((x) => x.id === companyId);

      if (findCompany) {
        cloneAccountList.push(...list.filter((x) => x.companyId === companyId));
        cloneCompanyList.push(findCompany);
      }
    } else {
      cloneAccountList = cloneAccountList.filter(
        (x) => x.companyId !== companyId,
      );
      cloneCompanyList = cloneCompanyList.filter((x) => x.id !== companyId);
    }
    setListActive(cloneAccountList);
    setCompanyListActive(cloneCompanyList);
  };

  const toggleCheckAll = ({ isChecked }: { isChecked: boolean }) => {
    let cloneAccountList = [...listActive];
    let cloneCompanyList = [...companyListActive];
    if (isChecked) {
      cloneAccountList.push(...list);
      cloneCompanyList.push(...companyList);
    } else {
      cloneAccountList = [];
      cloneCompanyList = [];
    }
    setListActive(cloneAccountList);
    setCompanyListActive(cloneCompanyList);
  };

  const updateConnectionCode = ({
    accountId,
    connectionCode,
  }: {
    accountId: number;
    connectionCode: string;
  }) => {
    const cloneAccountList = [...list];
    const indexAccount = cloneAccountList.findIndex((x) => x.id === accountId);
    const currentAccount = cloneAccountList[indexAccount];
    if (currentAccount && connectionCode) {
      currentAccount.connectionCode = connectionCode;
      setList(cloneAccountList);
    }
  };

  const getAccountListByMemberId = async ({
    memberId,
  }: {
    memberId?: number;
  }) => {
    if (memberId) {
      const result = await AccountService.GetAccountListByMemberId({
        memberId,
      });
      if (isRight(result)) {
        const cloneList: IAccount[] = [];
        result.right.forEach((item) => {
          const find = list.find((x) => x.id === item.id);
          if (find) {
            cloneList.push(find);
          }
        });
        setListActive(cloneList);
      }
    } else {
      setListActive([]);
    }
  };

  const updateCompanyList = ({ companys }: { companys: ICompany[] }) => {
    setCompanyList(companys);
    setCompanyListActive(companys);
  };

  return {
    state,
    isLoading,
    list,
    listActive,
    companyListActive,
    event: {
      getList,
      toggleCheckAccount,
      toggleCheckCompany,
      toggleCheckAll,
      updateConnectionCode,
      updateCompanyList,
      getAccountListByMemberId,
    },
  };
};

export default useAccountListScanState;
