import { useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import LeftLayout from './components/sidebar';
import useAuthenticatedState from './states/useAuthenticatedState';
import LoadingPage from '../../components/LoadingPage';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { match } from '../../ultis/custom-matcher';

const AuthenticatedWrapper = () => {
  const { state, event, currentAuth } = useAuthenticatedState();
  const { showErrorDialog } = useErrorHandler();
  const navigate = useNavigate();
  useEffect(() => {
    event.getCurrentAuth();
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
        navigate(`/login`);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  return (
    <>
      {currentAuth && (
        <div className="relative m-auto h-full min-h-full w-full max-w-[1400px] flex-1 border-b border-l border-r border-solid border-neutral-300 bg-neutral-100">
          <div className="relative h-full w-full bg-neutral-100">
            <LeftLayout />
            <div className="w-full pt-[60px] lg:pl-[200px] lg:pt-0">
              <div className="bg-white">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
      {!currentAuth && <LoadingPage></LoadingPage>}
    </>
  );
};

export default AuthenticatedWrapper;
