import { useState } from 'react';

import { Checkbox, Drawer } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SegmentedValue } from 'antd/es/segmented';

import TableMemberDetailComponent from './components/table-member-list';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../../hooks/useSuccessHandler';
import { drawerStyle } from '../../../../repositories/core/constant';
import { IOption } from '../../../../repositories/core/core.interface';
import { IReport } from '../../../../repositories/report/report.interface';
import CustomDateTime from '../../../../ultis/format-datetime';

const DrawerSelectColumnComponent = ({
  options,
  isOpen,
  onChange,
  onClose,
}: {
  options: IOption[];
  isOpen: boolean;
  onChange: (values: CheckboxValueType[]) => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Cột hiển thị"
      placement="right"
      width={'50%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      <Checkbox.Group
        defaultValue={options.map((x) => x.value)}
        onChange={onChange}
      >
        <div className="grid w-full grid-cols-1 gap-2">
          {options.map((item) => (
            <Checkbox
              key={item.value}
              className="ml-[8px]"
              value={item.value}
              disabled={
                !!(
                  item.value === 'USD' ||
                  item.value === 'VND' ||
                  item.value === 'IF' ||
                  item.value === 'ID' ||
                  item.value === 'EC'
                )
              }
            >
              {item.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
    </Drawer>
  );
};

const ReportDetailComponent = ({ report }: { report: IReport }) => {
  const columnOptions: IOption[] = [
    { label: '#', value: 'ID' },
    { label: 'Thông tin', value: 'IF' },
    { label: 'WinLoss', value: 'WL' },
    { label: 'Gross Comm', value: 'GC' },
    { label: 'USD', value: 'USD' },
    { label: 'VND', value: 'VND' },
    { label: 'Đổi tiền', value: 'EC' },
  ];

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const [isOpenSelectColumn, setIsOpenSelectColumn] = useState<boolean>(false);
  const [checkedCustomerId, setCheckedCustomerId] = useState<
    CheckboxValueType[]
  >([]);
  const [customerList, setCustomerList] = useState<IOption[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string[]>(
    columnOptions.map((x) => x.value),
  );

  const [checkedExchange, setCheckedExchange] = useState<CheckboxValueType[]>(
    [],
  );

  const [layoutReport, setLayoutReport] = useState<'member' | 'companyName'>(
    'member',
  );

  const [selectedCustomer, setSelectedCustomer] = useState<number | null>();

  const [triggerFetch, setTriggerFetch] = useState<number>(0);

  const onChangeCheckedExchange = (values: CheckboxValueType[]) => {
    setCheckedExchange(values);
  };

  const onUpdateCustomerList = (values: IOption[]) => {
    setCustomerList(values);
  };

  const onChangeLayout = (value: SegmentedValue) => {
    setLayoutReport(value as 'member' | 'companyName');
  };

  return (
    <>
      <div className="m-auto w-full max-w-[1000px]">
        <div className="w-full overflow-auto">
          <div className="m-auto w-full min-w-[600px]">
            <TableMemberDetailComponent
              triggerFetch={triggerFetch}
              report={report}
              selectedColumn={selectedColumn}
              checkedExchange={checkedExchange}
              selectedCustomer={selectedCustomer ?? null}
              onDelete={() => {}}
              onChangeLayout={onChangeLayout}
              onChangeCheckedExchange={onChangeCheckedExchange}
              onUpdateCustomerList={onUpdateCustomerList}
            />
          </div>
        </div>
      </div>

      <DrawerSelectColumnComponent
        options={columnOptions}
        isOpen={isOpenSelectColumn}
        onChange={(values) => setSelectedColumn(values as string[])}
        onClose={() => setIsOpenSelectColumn(false)}
      />
    </>
  );
};

const DrawerReportDetailComponent = ({
  isOpen,
  isLoadingExport,
  selected,
  onClose,
  onExport,
}: {
  isOpen: boolean;
  isLoadingExport: boolean;
  selected: IReport | null;
  onClose: () => void;
  onExport: (id: number) => void;
}) => {
  return (
    <Drawer
      title={
        selected
          ? `Báo cáo ${CustomDateTime.convertStringToDateTime(
              selected.fromDate ?? null,
              'dd/MM/yyyy',
            )} - ${CustomDateTime.convertStringToDateTime(
              selected.toDate ?? null,
              'dd/MM/yyyy',
            )}`
          : ''
      }
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selected && <ReportDetailComponent report={selected} />}
    </Drawer>
  );
};

export default DrawerReportDetailComponent;
