import { Either } from 'fp-ts/lib/Either';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';

import {
  IAccountScan,
  IAccountScanMapMethod,
  IAccountScanMethod,
  IInputAccountScan,
  IInputAccountScanMapBiddingMethod,
  IInputAccountScanMapGroupMethod,
  IInputAccountScanMapMethod,
  MemberBidDivision,
} from '../../repositories/account-scan/account-scan.interface';
import AccountScanRepository from '../../repositories/account-scan/account-scan.repository';
import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';

class AccountScanService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
    accountId,
    isQuick,
    memberId,
    toDate,
    fromDate,
    parentId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    accountId: number;
    isQuick: boolean;
    memberId?: number | null;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    parentId?: number | null;
  }): Promise<Either<ErrorType, PageItems<IAccountScan>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      AccountId: accountId.toString(),
      isQuick: isQuick.toString(),
      fromDate: fromDate.format('yyyy-MM-DD'),
      toDate: toDate.format('yyyy-MM-DD'),
    });
    if (memberId) {
      params.append('memberId', memberId.toString());
    }
    if (parentId) {
      params.append('parentId', parentId.toString());
    }
    return AccountScanRepository.getList({ params });
  }

  static getListMapMethodBy({
    sortNew,
    pageSize,
    pageIndex,
    accountId,
    parentId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    accountId: number;
    parentId: number | null;
  }): Promise<Either<ErrorType, PageItems<IAccountScanMethod>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      AccountId: accountId.toString(),
    });
    if (parentId) {
      params.append('parentId', parentId.toString());
    }
    return AccountScanRepository.getListMapMethodBy({ params });
  }

  static getListMapMethod({
    accountId,
  }: {
    accountId: number;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    return AccountScanRepository.getListMapMethod({ accountId });
  }

  static createAccountScanMapMethod({
    accountScanId,
    methodId,
    memberId,
    percentRefund,
  }: {
    methodId?: number;
    memberId: number;
    percentRefund?: number;
    accountScanId: number;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    const data = {
      accountScanId,
      methodId,
      memberId,
      percentRefund,
    } as IInputAccountScanMapMethod;
    return AccountScanRepository.createAccountScanMapMethod({ data });
  }

  static createAccountScanMapGroupMethod({
    accountScanId,
    methodGroupId,
    memberId,
    percentRefund,
  }: {
    memberId: number;
    percentRefund?: number;
    accountScanId: number;
    methodGroupId?: number;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    const data = {
      accountScanId,
      methodGroupId,
      memberId,
      percentRefund,
    } as IInputAccountScanMapGroupMethod;
    return AccountScanRepository.createAccountScanMapGroupMethod({ data });
  }

  static deleteAccountScanMapMethod({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    return AccountScanRepository.deleteAccountScanMapMethod({ id });
  }

  static deleteAccountScanMapGroupMethod({
    memberId,
    accountScanId,
    methodGroupId,
  }: {
    memberId: number;
    accountScanId: number;
    methodGroupId: number;
  }): Promise<Either<ErrorType, boolean>> {
    return AccountScanRepository.deleteAccountScanMapGroupMethod({
      memberId,
      accountScanId,
      methodGroupId,
    });
  }

  static createAccountScanMapBiddingMethod({
    accountScanId,
    categoryId,
    methodDeliverId,
    methodReceiveId,
    IsGroupMethod,
    memberBidDivisions,
    percentReceive,
    percentDeliver,
    groupMethodDeliverId,
    groupMethodReceiveId,
  }: {
    accountScanId: number;
    categoryId: number;
    methodDeliverId: number;
    methodReceiveId: number;
    IsGroupMethod: boolean;
    memberBidDivisions: MemberBidDivision[];
    percentReceive?: number;
    percentDeliver?: number;
    groupMethodDeliverId: number;
    groupMethodReceiveId: number;
  }): Promise<Either<ErrorType, boolean>> {
    const data = {
      accountScanId,
      categoryId,
      methodDeliverId,
      methodReceiveId,
      IsGroupMethod,
      groupMethodDeliverId,
      groupMethodReceiveId,
      memberBidDivisions: memberBidDivisions.map((x) => {
        return {
          ...x,
          percentReceive,
          percentDeliver,
        } as MemberBidDivision;
      }),
    } as IInputAccountScanMapBiddingMethod;
    return AccountScanRepository.createAccountScanMapBiddingMethod({
      data,
    });
  }

  static deleteAccountScanMapBiddingMethod({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    return AccountScanRepository.deleteAccountScanMapBiddingMethod({ id });
  }

  static create({
    accountId,
    companyId,
    parentId,
    roleCode,
    username,
  }: {
    accountId: number;
    companyId: number;
    parentId: number;
    roleCode: string;
    username: string;
  }): Promise<Either<ErrorType, number>> {
    const data = {
      accountId,
      companyId,
      parentId,
      roleCode,
      username,
    } as IInputAccountScan;
    return AccountScanRepository.create({ data });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return AccountScanRepository.delete({ id });
  }
}

export default AccountScanService;
