/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isLeft, isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../../repositories/core/error-type';
import { IGroupMethod } from '../../../../../repositories/group-method/group-method.interface';
import GroupMethodService from '../../../../../services/group-method/group-method.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | {
        type: 'fetchSuccess';
        listDeliver: IGroupMethod[];
        listReceive: IGroupMethod[];
      };
  isLoading: boolean;
  listDeliver: IGroupMethod[];
  listReceive: IGroupMethod[];
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (
    listDeliver: IGroupMethod[],
    listReceive: IGroupMethod[],
  ) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    listDeliver: [],
    listReceive: [],
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (listDeliver, listReceive) =>
      set({
        state: { type: 'fetchSuccess', listDeliver, listReceive },
        listDeliver,
        listReceive,
      }),
  }));

const useGroupMethodDeliverReceiveListScanState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    listDeliver,
    listReceive,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = useStore();

  const getList = async ({ companyId }: { companyId: number }) => {
    start();
    setLoading(true);
    const resultDeliver =
      await GroupMethodService.getMethodGroupDeliverReceiveList({
        sortNew: true,
        isDeliver: true,
        companyId,
      });
    const resultReceive =
      await GroupMethodService.getMethodGroupDeliverReceiveList({
        sortNew: true,
        isDeliver: false,
        companyId,
      });
    if (isRight(resultDeliver) && isRight(resultReceive)) {
      fetchSuccess(resultDeliver.right, resultReceive.right);
    } else if (isLeft(resultDeliver)) {
      fetchFailed(resultDeliver.left);
    } else if (isLeft(resultReceive)) {
      fetchFailed(resultReceive.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    listDeliver,
    listReceive,
    event: {
      getList,
    },
  };
};

export default useGroupMethodDeliverReceiveListScanState;
