import { useEffect } from 'react';

import { Button, Card, Drawer } from 'antd';

import usePackageByUserIdState from './states/usePackageByUserIdState';
import usePackageListState from './states/usePackageListState';
import useRegisterPackageyUserIdState from './states/useRegisterPackageyUserIdState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { drawerStyle } from '../../../repositories/core/constant';
import { IPackage } from '../../../repositories/package/package.interface';
import { IUser } from '../../../repositories/user/user.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';

const PackageManageComponent = ({ selected }: { selected: IUser }) => {
  const { state, event, list } = usePackageListState();
  const {
    state: statePackage,
    event: eventPackage,
    detail,
  } = usePackageByUserIdState();

  const {
    state: stateRegister,
    event: eventRegister,
    isLoading: isLoadingRegister,
  } = useRegisterPackageyUserIdState();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const registerPackage = (item: IPackage) => {
    eventRegister.register({ userId: selected.id, packageId: item.id });
  };

  useEffect(() => {
    event.getList();
    eventPackage.getDetail({ userId: selected.id });
  }, []);

  useEffect(() => {
    match([stateRegister, 'type'])({
      start() {},
      init() {},
      registerFailed(res) {
        showErrorDialog(res.error);
      },
      registerSuccess() {
        showSuccessDialog('Đăng ký gói thành công!');
        eventPackage.getDetail({ userId: selected.id });
      },
    });
  }, [stateRegister.type]);

  return (
    <div>
      {detail && (
        <div className="my-4 text-center">
          <p className="text-lg font-bold">
            Thời gian sử dụng: {'  '}
            {CustomDateTime.convertStringToDateTime(
              detail.endDate ?? null,
              'dd/MM/yyyy',
            )}
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        {list.map((item) => (
          <Card
            key={item.id}
            title={`Gói ${item.numberOfMonth} tháng`}
            bordered={false}
          >
            <p className="font-bold">{item.name}</p>
            {item.promotionOfMonth > 0 && (
              <p className="font-bold">
                Khuyến mãi {item.promotionOfMonth} tháng
              </p>
            )}
            <div className="mt-4">
              <Button
                danger
                loading={isLoadingRegister}
                onClick={() => registerPackage(item)}
                type={detail ? 'dashed' : 'primary'}
              >
                {detail ? 'Gia hạn tiếp' : 'Đăng ký'}
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const DrawerPackageManageComponent = ({
  isClose,
  selected,
  onClose,
}: {
  isClose: boolean;
  selected: IUser | null;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={onClose}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selected && <PackageManageComponent selected={selected} />}
    </Drawer>
  );
};

export default DrawerPackageManageComponent;
