import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import AppEnv from './app-env';

export const initSentry = () => {
  Sentry.init({
    dsn: '123',
    environment: AppEnv.get().environment,
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        unmask: ['.custom-error-dialog', '.custom-error-component'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: AppEnv.get().environment === 'DEV' ? 1.0 : 0.3,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: AppEnv.get().environment === 'DEV' ? 1 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
};

class SentryCatch {
  static CatchError = (err: any) => {
    Sentry.captureException(err);
  };
}
export default SentryCatch;
