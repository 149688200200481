import { useEffect, useState } from 'react';

import { Result, Image, Statistic, Button } from 'antd';
import type { CountdownProps } from 'antd';

import { useErrorHandler } from '../../hooks/useErrorHandler';
import { StatusCodeWeb } from '../../repositories/core/constant';
import { match } from '../../ultis/custom-matcher';
import useMaintenanceState from '../../wrappers/services/states/useMaintenanceState';

const { Countdown } = Statistic;
const MaintenancePage = () => {
  const { state, event } = useMaintenanceState();
  const { showErrorDialog } = useErrorHandler();
  const [deadline, setDeadline] = useState<number>(0);

  useEffect(() => {
    event.getHealth();
  }, []);

  const onFinish: CountdownProps['onFinish'] = () => {
    console.log('finished!');
  };

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess(res) {
        if (res.res.statusCode === StatusCodeWeb.MAINTENANCE) {
          const date = new Date(res.res.datetime).getTime() ?? 0;
          if (date >= 0) {
            setDeadline(date ?? 0);
          }
        }
      },
    });
  }, [state.type]);

  return (
    <div className="w-full">
      <div className="flex-none py-4 text-center">
        <Image width={150} src="logo.png" preview={false} />
      </div>
      <Result
        status="403"
        title="Phần mềm đang bảo trì"
        subTitle={
          <>
            <p>
              Chúng tôi xin lỗi vì sự bất tiện này, phần mềm đang trong quá
              trình bảo trì nâng cấp
            </p>
            <div>
              <Countdown
                title="Tiến trình kết thúc vào lúc"
                value={deadline}
                onFinish={onFinish}
              />
            </div>
          </>
        }
        extra={
          deadline === 0 && (
            <Button href="/" type="primary">
              Trờ về trang chính
            </Button>
          )
        }
      />
    </div>
  );
};

export default MaintenancePage;
