import { useEffect, useState } from 'react';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Skeleton, Tooltip } from 'antd';

import DrawerCreateUpdateConfigCompanyComponent from './DrawerCreateUpdateConfigCompanyComponent';
import useScanConfigDeleteState from './states/useScanConfigDeleteState';
import useScanConfigListState from './states/useScanConfigListState';
import useScanConfigResetState from './states/useScanConfigResetState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { IScanConfig } from '../../../repositories/config/config.interface';
import { maxPageSize } from '../../../repositories/core/constant';
import CommonProccess from '../../../ultis/common-proccess';
import { match } from '../../../ultis/custom-matcher';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';

const { Panel } = Collapse;
const TabItemConfigComponent = () => {
  const { event: eventList, isLoading, list } = useScanConfigListState();
  const { list: companys } = useCompanyListState();
  const { state: stateDelete, event: eventDelete } = useScanConfigDeleteState();

  const { state: stateReset, event: eventReset } = useScanConfigResetState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedCategory, setSelectedCategory] = useState<IScanConfig | null>(
    null,
  );
  const [isOpenCreateUpdate, setIsOpenCreateUpdate] = useState<boolean>(false);

  const onReload = () => {
    eventList.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
  };

  const onCloseCreateUpdate = () => {
    setIsOpenCreateUpdate(false);
    setSelectedCategory(null);
  };

  const onCreate = (value: IScanConfig) => {
    setIsOpenCreateUpdate(true);
    setSelectedCategory(value);
  };

  const onUpdate = (value: IScanConfig) => {
    setIsOpenCreateUpdate(true);
    setSelectedCategory(value);
  };

  const onDelete = (value: IScanConfig) => {
    eventDelete.deleteItem({ id: value.id });
  };

  const onReset = (value: number) => {
    eventReset.resetItem({ companyId: value });
  };

  useEffect(() => {
    eventList.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
  }, []);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa cấu hình dữ liệu thành công!');
        onReload();
      },
    });
  }, [stateDelete.type]);

  useEffect(() => {
    match([stateReset, 'type'])({
      start() {},
      init() {},
      resetFailed(res) {
        showErrorDialog(res.error);
      },
      resetSuccess() {
        showSuccessDialog('Phục hồi cấu hình dữ liệu thành công!');
        onReload();
      },
    });
  }, [stateReset.type]);

  return (
    <div className="mt-4">
      {companys.map((company) => {
        const configList = list.filter((x) => x.companyId === company.id);
        return (
          <div className="mt-2">
            <Collapse
              size="small"
              key={company.companyCode}
              expandIconPosition={'start'}
              defaultActiveKey={company.companyCode}
            >
              <Panel
                header={
                  <div>
                    <strong className="pl-2">{company.name}</strong>
                  </div>
                }
                extra={
                  <div className="flex gap-2">
                    <Tooltip title="Thêm mới thể loại">
                      <Button
                        size="small"
                        type="primary"
                        onClick={(event) => {
                          onCreate({
                            companyId: company.id,
                          } as IScanConfig);
                          event.stopPropagation();
                        }}
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>

                    {/* <div>
                      <Tooltip title="Phục hồi dữ liệu">
                        <Popconfirm
                          title="Phục hồi mặc định dữ liệu"
                          description={
                            <div className="max-w-[200px]">
                              <p>
                                Phục hồi mặc định cấu hình này thì những công
                                thức đã được và dữ liệu tính toán của cấu hình
                                này sẽ bị xóa chung.
                              </p>
                              <p className="font-bold text-state-negative">
                                Bạn có chắc chắn muốn phục hồi không?
                              </p>
                            </div>
                          }
                          onConfirm={(e) => {
                            onReset(company.id);
                            e?.stopPropagation();
                          }}
                          okText="Có"
                          cancelText="Không"
                        >
                          <Button
                            danger
                            size="small"
                            type="dashed"
                            onClick={(event) => event.stopPropagation()}
                            icon={<RedoOutlined />}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </div> */}
                  </div>
                }
                key={company.companyCode}
              >
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                  {configList.map((item, index) => {
                    const parent = CommonProccess.GetCategoryListFromIds(
                      item.value,
                      company.id,
                    );
                    const children = CommonProccess.GetCategoryListFromIds(
                      item.children,
                      company.id,
                    );

                    return (
                      <div key={index}>
                        <Card
                          headStyle={{ padding: 10, fontSize: 12 }}
                          bodyStyle={{ padding: 10 }}
                          title={`${item.name}`}
                          bordered={true}
                          extra={
                            <div className="flex gap-2">
                              <Tooltip title="Chỉnh sửa">
                                <Button
                                  onClick={() => onUpdate(item)}
                                  size="small"
                                  icon={<EditOutlined />}
                                />
                              </Tooltip>
                              {/* <Tooltip title="Xóa cấu hình">
                                <Popconfirm
                                  title="Xóa cấu hình này"
                                  description={
                                    <div className="max-w-[200px]">
                                      <p>
                                        Xóa cấu hình này thì những công thức đã
                                        được và dữ liệu tính toán của cấu hình
                                        này sẽ bị xóa chung.
                                      </p>
                                      <p className="font-bold text-state-negative">
                                        Bạn có chắc chắn muốn xóa không?
                                      </p>
                                    </div>
                                  }
                                  onConfirm={() => onDelete(item)}
                                  okText="Có"
                                  cancelText="Không"
                                >
                                  <Button
                                    danger
                                    size="small"
                                    type="dashed"
                                    icon={<DeleteOutlined />}
                                  />
                                </Popconfirm>
                              </Tooltip> */}
                            </div>
                          }
                        >
                          {parent?.map((x) => {
                            return (
                              <>
                                <div>
                                  <p className="font-bold">{x.label}</p>
                                  <div className="pl-4">
                                    {children
                                      ?.filter(
                                        (child) => child.parentId === x.id,
                                      )
                                      .map((y) => {
                                        return <p>{y.label}</p>;
                                      })}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </Card>
                      </div>
                    );
                  })}
                  {isLoading && <Skeleton active paragraph={{ rows: 4 }} />}
                </div>
              </Panel>
            </Collapse>
          </div>
        );
      })}
      <DrawerCreateUpdateConfigCompanyComponent
        selected={selectedCategory}
        isOpen={isOpenCreateUpdate}
        onClose={onCloseCreateUpdate}
        onReload={onReload}
      />
    </div>
  );
};

export default TabItemConfigComponent;
