import { format } from 'date-fns';

class CustomDateTime {
  static convertStringToDateTime(
    datetime: string | null,
    formatString?: string,
  ) {
    if (datetime) {
      return format(new Date(datetime), formatString ?? 'dd/MM/yyyy hh:mm');
    }
    return '';
  }
}

export default CustomDateTime;
