import { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Space, Button, Form, Drawer, Select } from 'antd';
import { FormInstance } from 'antd/es/form';

import useAddMethodToGroupState from './states/useAddMethodToGroupState';
import useMethodListState from './states/useMethodListState';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../../hooks/useSuccessHandler';
import {
  drawerStyle,
  maxPageSize,
} from '../../../../repositories/core/constant';
import { IOption } from '../../../../repositories/core/core.interface';
import { IMethod } from '../../../../repositories/method/method.interface';
import { match } from '../../../../ultis/custom-matcher';
import DrawerCreateUpdateMethodComponent from '../../../method/components/create-update-method/DrawerCreateUpdateMethodComponent';

const AddMethodToGroupComponent = ({
  form,
  methods,
  isLoadingMethods,
  onSubmit,
  onAddNewMethod,
}: {
  form: FormInstance<any>;
  methods: IMethod[];
  isLoadingMethods: boolean;
  onSubmit: (values: any) => void;
  onAddNewMethod: () => void;
}) => {
  return (
    <Form form={form} onFinish={onSubmit} layout="vertical" initialValues={{}}>
      <div className="grid grid-cols-1 gap-2">
        <div>
          <Form.Item label="Công thức">
            <Space.Compact block={true}>
              <Form.Item
                name="methodId"
                style={{ width: '100%' }}
                rules={[{ required: true, message: 'Chọn công thức' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  loading={isLoadingMethods}
                  options={methods.map((x) => {
                    return { value: x.id, label: x.name } as IOption;
                  })}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={onAddNewMethod}
                  icon={<PlusOutlined />}
                ></Button>
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

const DrawerAddMethodToGroupComponent = ({
  methodGroupId,
  isClose,
  onClose,
  onReload,
}: {
  methodGroupId: number;
  isClose: boolean;
  onClose: () => void;
  onReload: () => void;
}) => {
  const { event, isLoading: isLoadingMethods, list } = useMethodListState();
  const {
    state: stateAdd,
    event: eventAdd,
    isLoading: isLoadingAdd,
  } = useAddMethodToGroupState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [form] = Form.useForm();
  const [isOpenCreateMethod, setIsOpenCreateMethod] = useState<boolean>(false);

  const onCloseAdd = () => {
    onClose();
    form.resetFields();
  };
  const onSubmitCreateUpdate = (values: any) => {
    eventAdd.addItem({ methodGroupId, methodId: values.methodId });
  };

  const onReloadCreateNewMethod = (method: IMethod) => {
    event.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      onFinished() {
        form.setFieldsValue({
          methodId: method.id,
        });
      },
    });
  };

  useEffect(() => {
    event.getList({ pageIndex: 1, pageSize: maxPageSize });
  }, []);

  useEffect(() => {
    match([stateAdd, 'type'])({
      start() {},
      init() {},
      addFailed(res) {
        showErrorDialog(res.error);
      },
      addSuccess() {
        showSuccessDialog('Thêm công thức thành công!');
        form.resetFields();
        onClose();
        onReload();
      },
    });
  }, [stateAdd.type]);

  return (
    <>
      <Drawer
        title="Thêm công thức"
        width={'100%'}
        onClose={onCloseAdd}
        afterOpenChange={() => {}}
        open={isClose}
        headerStyle={drawerStyle}
        bodyStyle={drawerStyle}
        extra={
          <Space>
            <Button
              loading={isLoadingAdd}
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Lưu lại
            </Button>
          </Space>
        }
      >
        <AddMethodToGroupComponent
          form={form}
          methods={list}
          isLoadingMethods={isLoadingMethods}
          onSubmit={onSubmitCreateUpdate}
          onAddNewMethod={() => {
            setIsOpenCreateMethod(true);
          }}
        />
      </Drawer>
      <DrawerCreateUpdateMethodComponent
        selected={
          {
            isDeliver: true,
          } as IMethod
        }
        isClose={isOpenCreateMethod}
        onClose={() => setIsOpenCreateMethod(false)}
        onReload={onReloadCreateNewMethod}
      />
    </>
  );
};

export default DrawerAddMethodToGroupComponent;
