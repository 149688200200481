/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import ConfigService from '../../../../services/config/config.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'resetFailed'; error: ErrorType }
    | { type: 'resetSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  resetFailed: (error: ErrorType) => void;
  resetSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    resetFailed: (error) => set({ state: { type: 'resetFailed', error } }),
    resetSuccess: () => set({ state: { type: 'resetSuccess' } }),
  }));

const useScanConfigResetState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, resetFailed, resetSuccess } =
    useStore();

  const resetItem = async ({ companyId }: { companyId: number }) => {
    start();
    setLoading(true);
    const result = await ConfigService.reset({
      companyId,
    });
    if (isRight(result)) {
      resetSuccess();
    } else {
      resetFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      resetItem,
    },
  };
};

export default useScanConfigResetState;
