/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import AccountService from '../../../../services/account/account.service';

type IAccountListState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'updateFailed'; error: ErrorType }
    | { type: 'updateSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  updateFailed: (error: ErrorType) => void;
  updateSuccess: () => void;
};

const createStore = () =>
  create<IAccountListState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    updateFailed: (error) => set({ state: { type: 'updateFailed', error } }),
    updateSuccess: () => set({ state: { type: 'updateSuccess' } }),
  }));

const useAccountListPageState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, updateSuccess, updateFailed } =
    useStore();

  const updateItem = async ({
    id,
    password,
    companyId,
    code,
    description,
    isScanMember,
  }: {
    id: number;
    password: string;
    companyId: number;
    code: string;
    description: string;
    isScanMember: boolean;
  }) => {
    start();
    setLoading(true);
    const result = await AccountService.update({
      id,
      password,
      companyId,
      code,
      description,
      isScanMember,
    });
    if (isRight(result)) {
      updateSuccess();
    } else {
      updateFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      updateItem,
    },
  };
};

export default useAccountListPageState;
