import React, { lazy } from 'react';

import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import authRoutes from './auth-routes';
import Root from './Root';
import LoadingPage from '../components/LoadingPage';
import MaintenancePage from '../pages/maintenance';
import LoginViewReportPage from '../pages/view-report/login';
import ViewReportListPage from '../pages/view-report/report/list/report-list';
import ServicesWrapper from '../wrappers/services';

// DONT USE /auth, /api/rest, /graphql (backend used)
const LoginPage = lazy(() => import('../pages/login/index'));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Error = () => {
  return <div>Some thing wrong</div>;
};

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <ServicesWrapper />,
        children: [...authRoutes],
      },
      {
        path: '/login',
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: '/view-report/login',
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <LoginViewReportPage />
          </React.Suspense>
        ),
      },
      {
        path: '/view-report',
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <ViewReportListPage />
          </React.Suspense>
        ),
      },
      {
        path: '/maintenance',
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <MaintenancePage />
          </React.Suspense>
        ),
      },
    ],
  },
]);

export default router;
