import { useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, List, Modal, Timeline, Image } from 'antd';

import ChangePasswordComponent from './components/change-password';
import CustomDateTime from '../../ultis/format-datetime';
import useAuthenticatedState from '../../wrappers/auth/states/useAuthenticatedState';

const HomePage = () => {
  const { currentPackage, currentAuth } = useAuthenticatedState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const data = [
    {
      title: 'Bảng giá phần mềm kế toán',
      description:
        'Phần mềm kế toán HP79KT.com được nâng cấp với các tính năng mới, linh động cho người sử dụng có thể tính toán chính xác theo các công thức và số liệu từ các máy chủ khác nhau.',
      content:
        'Phần mềm kế toán HP79KT.com được nâng cấp với các tính năng mới, linh động cho người sử dụng có thể tính toán chính xác theo các công thức và số liệu từ các máy chủ khác nhau.',
    },
  ];
  return (
    <div className="w-full p-4">
      <div className="grid grid-cols-1 gap-4">
        <Card title="Phần mềm kế toán tổng hợp 2.0">
          <div className="flex items-center gap-2 pb-2">
            <Avatar
              style={{ backgroundColor: '#87d068' }}
              icon={<UserOutlined />}
            />
            <p>{currentAuth?.username}</p>
          </div>
          <div className="py-2">
            <ChangePasswordComponent />
          </div>
          <div>
            {currentPackage && (
              <p>
                Ngày hết hạn sử dụng:{' '}
                <strong>
                  {CustomDateTime.convertStringToDateTime(
                    currentPackage.endDate ?? null,
                  )}
                </strong>
              </p>
            )}
            {!currentPackage && <p>Tài khoản chưa đăng ký gói sử dụng</p>}
          </div>
        </Card>
        <Card title="Đối tác quảng cáo">
          <div className="w-full text-center">
            <a
              className="w-full max-w-[500px]"
              target="_blank"
              href="https://woncasino.info/"
            >
              <Image
                className="w-full max-w-[500px]"
                src="/banner/WON.gif"
                preview={false}
              />
              <h4 className="mt-[10px] text-2xl font-bold uppercase text-red-500">
                Casino đẳng cấp online
              </h4>
            </a>
          </div>
        </Card>
        <Card title="Tin tức">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <>
                      <p className="font-bold">{item.title}</p>
                    </>
                  }
                  description={
                    <>
                      <div>{item.description}</div>
                      {item.content && (
                        <div className="mt-2">
                          <Button onClick={() => setIsModalOpen(true)}>
                            Xem thêm
                          </Button>
                        </div>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
        <Card title="Hệ thống">
          <Timeline
            items={[
              {
                children: 'Bản nâng cấp v2.0 - 09/05/2023',
              },
            ]}
          />
        </Card>
      </div>
      <Modal
        title="Bảng giá phần mềm kế toán"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Đang cập nhật.</p>
      </Modal>
    </div>
  );
};

export default HomePage;
