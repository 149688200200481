import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { IAuth, IHealth } from './auth.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';
import { IUsedPackage } from '../package/package.interface';

class AuthRepository {
  static getDetail(): Promise<Either<ErrorType, IAuth>> {
    const query = () =>
      axiosClient<IAuth>({
        method: Methods.GET,
        url: 'v1/users/get-detail-by-token',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static changePassword({
    newPassword,
    oldPassword,
  }: {
    newPassword: string;
    oldPassword: string;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.PUT,
        url: 'v1/users/change-pass',
        data: {
          newPassword,
          oldPassword,
        },
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getPackageDetail(): Promise<Either<ErrorType, IUsedPackage>> {
    const query = () =>
      axiosClient<IUsedPackage>({
        method: Methods.GET,
        url: 'v1/users/package',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getHealth(): Promise<Either<ErrorType, IHealth>> {
    const query = () =>
      axiosClient<IHealth>({
        method: Methods.GET,
        url: 'check-health',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default AuthRepository;
