/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { PageItems } from '../../../../repositories/core/core.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import { IHistory } from '../../../../repositories/history/history.interface';
import HistoryService from '../../../../services/history/history.service';

type IHistoryListState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; res: PageItems<IHistory> };
  isLoading: boolean;
  list: IHistory[];
  totalItems: number;
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (res: PageItems<IHistory>) => void;
};

const createStore = () =>
  create<IHistoryListState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    list: [],
    totalItems: 0,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (res) =>
      set({
        state: { type: 'fetchSuccess', res },
        list: res.items,
        totalItems: res.totalItems,
      }),
  }));

const useHistoryListState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    list,
    totalItems,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = useStore();

  const getList = async ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => {
    start();
    setLoading(true);
    const result = await HistoryService.getList({
      sortNew: true,
      pageSize,
      pageIndex,
    });
    if (isRight(result)) {
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    list,
    totalItems,
    event: {
      getList,
    },
  };
};

export default useHistoryListState;
