class FormatDuration {
  static convertSecondToDuration(totalSeconds: number) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    // const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${minutes}:${seconds}`;
  }
}

export default FormatDuration;
