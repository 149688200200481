import { useEffect } from 'react';

import { Space, Button, Input, Form, Drawer } from 'antd';

import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../../hooks/useSuccessHandler';
import { drawerStyle } from '../../../../repositories/core/constant';
import { ICustomerReport } from '../../../../repositories/customer/customer.interface';
import { match } from '../../../../ultis/custom-matcher';
import useAccountViewReportCreateState from '../states/useViewReportCreateState';
import useAccountViewReportUpdateState from '../states/useViewReportUpdatedState';

const DrawerCreateUpdateViewReportComponent = ({
  memberId,
  selected,
  isClose,
  onClose,
  onReload,
}: {
  memberId: number;
  selected: ICustomerReport | null;
  isClose: boolean;
  onClose: () => void;
  onReload: (value: ICustomerReport) => void;
}) => {
  const [form] = Form.useForm();

  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useAccountViewReportCreateState();
  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useAccountViewReportUpdateState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const onSubmitCreateUpdate = (values: ICustomerReport) => {
    if (selected && selected.id) {
      eventUpdate.updateItem({
        id: selected.id,
        username: values.username,
        password: values.password,
        memberId,
      });
    } else {
      eventCreate.createItem({
        username: values.username,
        password: values.password,
        memberId,
      });
    }
  };

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess(res) {
        showSuccessDialog('Tạo khách hàng thành công!');
        form.resetFields();
        onReload(res.customer);
        onClose();
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess(res) {
        showSuccessDialog('Cập nhật khách hàng thành công!');
        form.resetFields();
        onReload(res.customer);
        onClose();
      },
    });
  }, [stateUpdate.type]);

  useEffect(() => {
    form.setFieldsValue(selected);
  }, [selected]);

  return (
    <Drawer
      title="Thông tin"
      width={'80%'}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmitCreateUpdate} layout="vertical">
        <div className="grid grid-cols-1 gap-2">
          <Form.Item
            name="username"
            label="Tài khoản"
            rules={[{ required: true, message: 'Nhập tên tài khoản' }]}
          >
            <Input autoComplete="nope" placeholder="Nhập tên tài khoản" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[{ required: true, message: 'Nhập tên mật khẩu' }]}
          >
            <Input autoComplete="nope" placeholder="Nhập tên mật khẩu" />
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
export default DrawerCreateUpdateViewReportComponent;
