import { useEffect, useState } from 'react';

import {
  BarsOutlined,
  AppstoreOutlined,
  EllipsisOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  FormInstance,
  InputNumber,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Segmented, { SegmentedValue } from 'antd/es/segmented';

import DrawerMethodCompareComponent from './components/method-compare-component';
import TableMarketCodeDetailComponent from './components/table-market-code-list';
import TableMemberDetailComponent from './components/table-member-list';
import useReportDeleteExchangeState from './states/useReportDeleteExchangeState';
import useReportExchangeState from './states/useReportExchangeState';
import useReportExportExcelState from './states/useReportExportState';
import useReportMethodCompareState from './states/useReportMethodCompareState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { drawerStyle } from '../../../repositories/core/constant';
import { IOption } from '../../../repositories/core/core.interface';
import { ICurrency } from '../../../repositories/currency/currency.interface';
import {
  IExchangeCurrencyList,
  IReport,
} from '../../../repositories/report/report.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import useCurrencyListState from '../../method/components/create-update-method/states/useCurrencyListState';

const TableFilter = ({
  form,
  customerList,
  isDisabled,
  isLoadingExport,
  onChangeCustomer,
  onExchange,
  onChangeLayout,
  onOpenSelectColumn,
  onExportExcel,
}: {
  form: FormInstance<any>;
  customerList: IOption[];
  isDisabled: boolean;
  isLoadingExport: boolean;
  onChangeCustomer: (values: any) => void;
  onExchange: () => void;
  onChangeLayout: (value: SegmentedValue) => void;
  onOpenSelectColumn: () => void;
  onExportExcel: () => void;
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <Form form={form} layout="vertical">
            <Form.Item name="marketCode">
              <Select
                className="w-full"
                placeholder={'Lọc khách hàng'}
                style={{ width: '100%' }}
                options={customerList}
                onChange={onChangeCustomer}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="flex gap-2">
          <Button
            disabled={isDisabled}
            onClick={onExchange}
            className="w-full"
            type="primary"
          >
            Đổi tiền
          </Button>
          <div className="flex-none">
            <Tooltip title="Tuỳ chỉnh chế độ xem">
              <Segmented
                options={[
                  {
                    value: 'member',
                    icon: <BarsOutlined />,
                  },
                  {
                    value: 'companyName',
                    icon: <AppstoreOutlined />,
                  },
                ]}
                onChange={onChangeLayout}
              />
            </Tooltip>
          </div>
          <Tooltip title="Tuỳ chỉnh cột hiển thị">
            <Button
              onClick={() => onOpenSelectColumn()}
              className="w-[50px] flex-none"
              icon={<EllipsisOutlined />}
            />
          </Tooltip>
          <Tooltip title="Xuất file excel">
            <Button
              type="primary"
              loading={isLoadingExport}
              disabled={isLoadingExport}
              onClick={() => onExportExcel()}
              className="inline-block !w-[50px]"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const DrawerAddExchangeComponent = ({
  form,
  isOpen,
  isLoading,
  currencys,
  onSubmit,
  onClose,
}: {
  form: FormInstance<any>;
  isOpen: boolean;
  isLoading: boolean;
  currencys: ICurrency[];
  onSubmit: (values: any) => void;
  onClose: () => void;
}) => {
  const range = Form.useWatch('range', form);
  const fromCurrencyId = Form.useWatch('fromCurrencyId', form);
  const toCurrencyId = Form.useWatch('toCurrencyId', form);

  return (
    <Drawer
      title="Thông tin"
      width={'90%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <div className="grid grid-cols-1 gap-2">
          <div>
            <Form.Item
              name="range"
              label="Hệ số"
              rules={[{ required: true, message: 'Nhập hệ số' }]}
            >
              <InputNumber
                placeholder="Nhập hệ số"
                min={0}
                max={100000}
                step={0.001}
                className="w-full"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="fromCurrencyId"
              label="Đổi tiền"
              rules={[
                {
                  required: true,
                  message: 'Chọn loại tiền',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={currencys
                  .filter((x) => x.id !== toCurrencyId)
                  .map((currency) => {
                    return { label: currency.name, value: currency.id };
                  })}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="toCurrencyId"
              label="Thành tiền"
              rules={[
                {
                  required: true,
                  message: 'Chọn loại tiền',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={currencys
                  .filter((x) => x.id !== fromCurrencyId)
                  .map((currency) => {
                    return { label: currency.name, value: currency.id };
                  })}
                allowClear
              />
            </Form.Item>
          </div>
          <div>Kết quả: Tổng tiền * {range}</div>
        </div>
      </Form>
    </Drawer>
  );
};

const DrawerSelectColumnComponent = ({
  options,
  isOpen,
  onChange,
  onClose,
}: {
  options: IOption[];
  isOpen: boolean;
  onChange: (values: CheckboxValueType[]) => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Cột hiển thị"
      placement="right"
      width={'50%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      <Checkbox.Group
        defaultValue={options.map((x) => x.value)}
        onChange={onChange}
      >
        <div className="grid w-full grid-cols-1 gap-2">
          {options.map((item) => (
            <Checkbox
              key={item.value}
              className="ml-[8px]"
              value={item.value}
              disabled={
                !!(
                  item.value === 'USD' ||
                  item.value === 'VND' ||
                  item.value === 'IF' ||
                  item.value === 'ID' ||
                  item.value === 'EC'
                )
              }
            >
              {item.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
    </Drawer>
  );
};

const ReportDetailComponent = ({ report }: { report: IReport }) => {
  const columnOptions: IOption[] = [
    { label: '#', value: 'ID' },
    { label: 'Thông tin', value: 'IF' },
    { label: 'WinLoss', value: 'WL' },
    { label: 'Gross Comm', value: 'GC' },
    { label: 'USD', value: 'USD' },
    { label: 'VND', value: 'VND' },
    { label: 'Đổi tiền', value: 'EC' },
  ];
  const {
    event: eventExchange,
    state: stateExchange,
    isLoading: isLoadingExchange,
  } = useReportExchangeState();

  const { event: eventCurrency, list: currencys } = useCurrencyListState();
  const {
    state: stateCompare,
    event: eventCompare,
    isLoading: isLoadingComapre,
    list: compareList,
  } = useReportMethodCompareState();

  const { event: eventDelete, state: stateDelete } =
    useReportDeleteExchangeState();

  const {
    event: eventExport,
    state: stateExport,
    isLoading: isLoadingExport,
  } = useReportExportExcelState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [formAddExchange] = Form.useForm();
  const [formSearch] = Form.useForm();

  const [isOpenAddExchange, setIsOpenAddExchange] = useState<boolean>(false);
  const [isOpenSelectColumn, setIsOpenSelectColumn] = useState<boolean>(false);
  const [checkedCustomerId, setCheckedCustomerId] = useState<
    CheckboxValueType[]
  >([]);
  const [customerList, setCustomerList] = useState<IOption[]>([]);
  const [customerExchangedList, setCustomerExchangedList] = useState<IOption[]>(
    [],
  );
  const [selectedColumn, setSelectedColumn] = useState<string[]>(
    columnOptions.map((x) => x.value),
  );

  const [checkedExchange, setCheckedExchange] = useState<CheckboxValueType[]>(
    [],
  );

  const [layoutReport, setLayoutReport] = useState<'member' | 'companyName'>(
    'member',
  );

  const [selectedCustomer, setSelectedCustomer] = useState<number | null>();

  const [triggerFetch, setTriggerFetch] = useState<number>(0);

  const [isOpenMethodCompare, setIsOpenMethodCompare] =
    useState<boolean>(false);
  const [selectedMemberCompareId, setSelectedMemberCompareId] = useState<
    number | null
  >();

  const onExchange = (value: CheckboxValueType[]) => {
    setCheckedCustomerId(value);
    setIsOpenAddExchange(true);
  };

  const onDelete = (value: IExchangeCurrencyList) => {
    eventDelete.deleteItem({ id: value.id });
  };

  const onSubmitExchange = () => {
    eventExchange.exchangeItem({
      fromCurrencyId: formAddExchange.getFieldValue('fromCurrencyId'),
      toCurrencyId: formAddExchange.getFieldValue('toCurrencyId'),
      reportId: report.id,
      range: formAddExchange.getFieldValue('range'),
      arrayMemberId: checkedCustomerId
        .filter((x) => x !== 0)
        .map((x) => x as number),
    });
  };

  const onCloseAddExchange = () => {
    setIsOpenAddExchange(false);
    formAddExchange.resetFields();
  };

  const onChangeCheckedExchange = (values: CheckboxValueType[]) => {
    if (values.includes(0)) {
      if (!checkedExchange.includes(0)) {
        const temp = customerExchangedList.map((x) => x.value);
        temp.push(0);
        setCheckedExchange(temp);
      } else {
        setCheckedExchange(values);
      }
    } else if (checkedExchange.includes(0)) {
      setCheckedExchange([]);
    } else {
      setCheckedExchange(values);
    }
  };

  const onUpdateCustomerList = (values: IOption[], exchanged: IOption[]) => {
    setCustomerList(values);
    setCustomerExchangedList(exchanged);
  };

  const onChangeLayout = (value: SegmentedValue) => {
    setLayoutReport(value as 'member' | 'companyName');
  };

  const onShowCompare = (memberId: number, accountScan: string) => {
    setIsOpenMethodCompare(true);
    setSelectedMemberCompareId(memberId);
    eventCompare.getList({ reportId: report.id, memberId, accountScan });
  };

  const onExportExcel = () => {
    eventExport.exportItem({ id: report.id });
  };

  useEffect(() => {
    match([stateExchange, 'type'])({
      start() {},
      init() {},
      exchangeFailed(res) {
        showErrorDialog(res.error);
      },
      exchangeSuccess() {
        showSuccessDialog('Đổi tiền thành công!');
        onCloseAddExchange();
        setTriggerFetch(triggerFetch + 1);
      },
    });
  }, [stateExchange.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa đổi tiền thành công!');
        setTriggerFetch(triggerFetch + 1);
      },
    });
  }, [stateDelete.type]);

  useEffect(() => {
    eventCurrency.getList();
  }, []);

  useEffect(() => {
    match([stateCompare, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [stateCompare.type]);

  useEffect(() => {
    match([stateExport, 'type'])({
      start() {},
      init() {},
      exportFailed(res) {
        showErrorDialog(res.error);
      },
      exportSuccess(res) {
        window.open(
          `http://api.hp79kt.com${res.result}`,
          '_blank',
          'noreferrer',
        );
      },
    });
  }, [stateExport.type]);
  return (
    <>
      <div className="m-auto w-full max-w-[1000px]">
        <TableFilter
          isLoadingExport={isLoadingExport}
          form={formSearch}
          isDisabled={checkedExchange.filter((x) => x !== 0).length === 0}
          customerList={customerList}
          onChangeCustomer={(e) => setSelectedCustomer(e)}
          onExchange={() => onExchange(checkedExchange)}
          onChangeLayout={onChangeLayout}
          onOpenSelectColumn={() => setIsOpenSelectColumn(true)}
          onExportExcel={onExportExcel}
        />

        <div className="w-full overflow-auto">
          <div className="m-auto w-full min-w-[600px]">
            {match([layoutReport])({
              member: () => (
                <TableMemberDetailComponent
                  triggerFetch={triggerFetch}
                  report={report}
                  selectedColumn={selectedColumn}
                  checkedExchange={checkedExchange}
                  selectedCustomer={selectedCustomer ?? null}
                  onDelete={onDelete}
                  onChangeLayout={onChangeLayout}
                  onChangeCheckedExchange={onChangeCheckedExchange}
                  onUpdateCustomerList={onUpdateCustomerList}
                  onShowCompare={onShowCompare}
                />
              ),
              companyName: () => (
                <TableMarketCodeDetailComponent
                  triggerFetch={triggerFetch}
                  report={report}
                  selectedColumn={selectedColumn}
                  checkedExchange={checkedExchange}
                  selectedCustomer={selectedCustomer ?? null}
                  onDelete={onDelete}
                  onChangeLayout={onChangeLayout}
                  onChangeCheckedExchange={onChangeCheckedExchange}
                  onUpdateCustomerList={onUpdateCustomerList}
                  onShowCompare={onShowCompare}
                />
              ),
            })}
          </div>
        </div>
      </div>
      <DrawerAddExchangeComponent
        form={formAddExchange}
        currencys={currencys}
        isOpen={isOpenAddExchange}
        isLoading={isLoadingExchange}
        onSubmit={onSubmitExchange}
        onClose={onCloseAddExchange}
      />

      <DrawerSelectColumnComponent
        options={columnOptions}
        isOpen={isOpenSelectColumn}
        onChange={(values) => setSelectedColumn(values as string[])}
        onClose={() => setIsOpenSelectColumn(false)}
      />

      <DrawerMethodCompareComponent
        isOpen={isOpenMethodCompare}
        isLoading={isLoadingComapre}
        list={compareList}
        selectedMemberId={selectedMemberCompareId}
        onClose={() => {
          eventCompare.clearData();
          setIsOpenMethodCompare(false);
        }}
      />
    </>
  );
};

const DrawerReportDetailComponent = ({
  isOpen,
  isLoadingExport,
  selected,
  onClose,
  onExport,
}: {
  isOpen: boolean;
  isLoadingExport: boolean;
  selected: IReport | null;
  onClose: () => void;
  onExport: (id: number) => void;
}) => {
  return (
    <Drawer
      title={
        selected
          ? `Báo cáo ${CustomDateTime.convertStringToDateTime(
              selected.fromDate ?? null,
              'dd/MM/yyyy',
            )} - ${CustomDateTime.convertStringToDateTime(
              selected.toDate ?? null,
              'dd/MM/yyyy',
            )}`
          : ''
      }
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selected && <ReportDetailComponent report={selected} />}
    </Drawer>
  );
};

export default DrawerReportDetailComponent;
