import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import {
  IAccountScan,
  IAccountScanMapMethod,
  IAccountScanMethod,
  IInputAccountScan,
  IInputAccountScanMapBiddingMethod,
  IInputAccountScanMapGroupMethod,
  IInputAccountScanMapMethod,
} from './account-scan.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class AccountScanRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IAccountScan>>> {
    const query = () =>
      axiosClient<PageItems<IAccountScan>>({
        method: Methods.GET,
        url: '/v1/account-scans/get-list-all-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getListMapMethodBy({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IAccountScanMethod>>> {
    const query = () =>
      axiosClient<PageItems<IAccountScanMethod>>({
        method: Methods.GET,
        url: '/v1/account-scans/get-list-map-member-method-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getListMapMethod({
    accountId,
  }: {
    accountId: number;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    const query = () =>
      axiosClient<IAccountScanMapMethod[]>({
        method: Methods.GET,
        url: `/v1/account-scans/get-list-parent-acc-map-method/${accountId}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static createAccountScanMapMethod({
    data,
  }: {
    data: IInputAccountScanMapMethod;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    const query = () =>
      axiosClient<IAccountScanMapMethod[]>({
        method: Methods.POST,
        url: `/v1/account-scans/add-member-method`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static createAccountScanMapGroupMethod({
    data,
  }: {
    data: IInputAccountScanMapGroupMethod;
  }): Promise<Either<ErrorType, IAccountScanMapMethod[]>> {
    const query = () =>
      axiosClient<IAccountScanMapMethod[]>({
        method: Methods.POST,
        url: `/v1/account-scans/add-member-method-group`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static deleteAccountScanMapMethod({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/account-scans/delete-member-method`,
        data: {
          id,
        },
      });
    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static deleteAccountScanMapGroupMethod({
    memberId,
    accountScanId,
    methodGroupId,
  }: {
    memberId: number;
    accountScanId: number;
    methodGroupId: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/account-scans/delete-member-method`,
        data: {
          memberId,
          accountScanId,
          methodGroupId,
        },
      });
    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static createAccountScanMapBiddingMethod({
    data,
  }: {
    data: IInputAccountScanMapBiddingMethod;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.POST,
        url: `/v1/account-scans/add-bid-division`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static deleteAccountScanMapBiddingMethod({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/account-scans/delete-bid-division`,
        data: {
          bidDivisions: [{ id }],
        },
      });
    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IInputAccountScan;
  }): Promise<Either<ErrorType, number>> {
    const query = () =>
      axiosClient<number>({
        method: Methods.POST,
        url: `/v1/account-scans`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/account-scans/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default AccountScanRepository;
