import { useEffect, useState } from 'react';

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Space,
  Button,
  Input,
  Select,
  Switch,
  Popconfirm,
  Table,
  Breadcrumb,
  Form,
  Drawer,
  FormInstance,
  Tooltip,
  TablePaginationConfig,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import useAccountCreateState from './states/useAccountCreateState';
import useAccountDeleteState from './states/useAccountDeleteState';
import useAccountListState from './states/useAccountListState';
import useAccountUpdateState from './states/useAccountUpdateState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { IAccount } from '../../../repositories/account/account.interface';
import {
  defaultPageSize,
  drawerStyle,
} from '../../../repositories/core/constant';
import { IOption } from '../../../repositories/core/core.interface';
import { match, mayBeMatch } from '../../../ultis/custom-matcher';
import Decode from '../../../ultis/decode';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';
import DrawerAccountDetailComponent from '../components/account-manage-component';

const DrawerAddEditAccountComponent = ({
  form,
  companys,
  isClose,
  isLoading,
  selected,
  onSubmit,
  onClose,
}: {
  form: FormInstance<any>;
  companys: IOption[];
  isClose: boolean;
  isLoading: boolean;
  selected: IAccount | null;
  onSubmit: (values: any) => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={onClose}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <Form.Item
              name="username"
              label="Tài khoản"
              rules={[{ required: true, message: 'Nhập tên tài khoản' }]}
            >
              <Input disabled={!!selected} placeholder="Nhập tên tài khoản" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="password" label="Mật khẩu">
              <Input placeholder="Nhập tên mật khẩu" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="code" label="Code">
              <Input placeholder="Nhập tên Code" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="companyId"
              label="Công ty"
              rules={[
                {
                  required: true,
                  message: 'Chọn Công ty',
                },
              ]}
            >
              <Select style={{ width: '100%' }} options={companys} allowClear />
            </Form.Item>
          </div>
          <div className="col-span-2">
            <Form.Item name="description" label="Mô tả">
              <Input.TextArea
                rows={4}
                placeholder="please enter url description"
              />
            </Form.Item>
          </div>
          <div className="flex items-center">
            <Form.Item valuePropName="checked" name="isScanMember">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <p className="pl-2">Lấy các cấp con</p>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const TableAccountList = ({
  isLoading,
  list,
  companys,
  form,
  totalItems,
  current,
  onDelete,
  onUpdate,
  onDetail,
  onOpenDrawer,
  onSubmitSearch,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IAccount[];
  companys: IOption[];
  form: FormInstance<any>;
  totalItems: number;
  current: number;
  onDelete: (item: IAccount) => void;
  onUpdate: (item: IAccount) => void;
  onDetail: (item: IAccount) => void;
  onOpenDrawer: () => void;
  onSubmitSearch: (values: any) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IAccount> = [
    {
      title: 'Đầu',
      dataIndex: 'prefixCode',
      key: 'prefixCode',
      width: 140,
      fixed: 'left',
    },

    {
      title: 'Sub quét',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Cấp',
      width: 120,
      render: (record: IAccount) =>
        record.roleCode && (
          <p>
            <Tag color="default">{record.roleCode}</Tag>
          </p>
        ),
    },

    {
      title: 'Công ty',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 100,
    },
    {
      title: 'Kết nối',
      width: 160,
      render: (record: IAccount) =>
        record.connectionCode && (
          <p>
            {mayBeMatch([record.connectionCode])({
              WAITING: () => <Tag color="default">{record.connectionCode}</Tag>,
              SUCCESS: () => <Tag color="success">{record.connectionCode}</Tag>,
              PROCESSING: () => (
                <Tag color="processing">{record.connectionCode}</Tag>
              ),

              orElse: () => <Tag color="error">{record.connectionCode}</Tag>,
            })}
          </p>
        ),
    },

    {
      title: '',
      key: 'action',
      width: 160,
      render: (record: IAccount) => (
        <div className="flex gap-2">
          <Button onClick={() => onDetail(record)} type="primary" size="small">
            Tuyến dưới
          </Button>
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Xóa sub quét">
            <Popconfirm
              title="Xóa tài khoản này"
              description="Bạn có chắc chắn xóa tài khoản này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form onFinish={onSubmitSearch} form={form} layout="vertical">
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-5">
          <div>
            <Form.Item name="username">
              <Input placeholder="Nhập tên sub" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="prefixCode">
              <Input placeholder="Nhập tên đầu" allowClear />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="companyId">
              <Select
                placeholder={'Chọn công ty'}
                style={{ width: '100%' }}
                options={companys}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Button
              className="w-full"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Button onClick={onOpenDrawer} className="w-full" type="primary">
              Thêm mới
            </Button>
          </div>
        </div>
      </Form>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        scroll={{ x: 1000 }}
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderAccountList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Quản lý sub quét',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Quản lý sub quét</h2>
    </div>
  );
};

const AccountListPage = () => {
  const { state, event, isLoading, list, totalItems } = useAccountListState();
  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useAccountCreateState();
  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useAccountUpdateState();
  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useAccountDeleteState();

  const { list: companys } = useCompanyListState();

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [selectedAccountDetail, setSelectedAccountDetail] =
    useState<IAccount | null>(null);
  const [isCloseCreateUpdate, setIsCloseCreateUpdate] =
    useState<boolean>(false);
  const [isOpenAccountDetail, setIsOpenAccountDetail] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      username: formSearch.getFieldValue('username'),
      prefixCode: formSearch.getFieldValue('prefixCode'),
      companyId: formSearch.getFieldValue('companyId'),
    });
  };

  const onConfirmDelete = (item: IAccount) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onCloseCreateUpdate = () => {
    setIsCloseCreateUpdate(false);
    setSelectedAccount(null);
    form.resetFields();
  };

  const onCloseAccountDetail = () => {
    setIsOpenAccountDetail(false);
    setSelectedAccountDetail(null);
  };

  const onSelectedUpdate = (value: IAccount) => {
    form.setFieldsValue({
      ...value,
      password: value.password ? Decode.decodeHash(value.password) : '',
    });
    setIsCloseCreateUpdate(true);
    setSelectedAccount(value);
  };

  const onSelectedDetail = (value: IAccount) => {
    setIsOpenAccountDetail(true);
    setSelectedAccountDetail(value);
  };

  const onSubmitCreateUpdate = (values: IAccount) => {
    if (selectedAccount && selectedAccount.id) {
      eventUpdate.updateItem({
        id: selectedAccount.id,
        password: values.password,
        companyId: values.companyId,
        code: values.code ?? '',
        description: values.description,
        isScanMember: values.isScanMember,
      });
    } else {
      eventCreate.createItem({
        username: values.username,
        password: values.password,
        companyId: values.companyId,
        code: values.code ?? '',
        description: values.description,
        isScanMember: values.isScanMember,
      });
    }
  };

  const onSubmitSearch = () => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  };

  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Tạo sub quét thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess() {
        showSuccessDialog('Cập nhật sub quét thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateUpdate.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa sub quét thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <HeaderAccountList />
      <TableAccountList
        form={formSearch}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        companys={companys.map((x) => {
          return { value: x.id, label: x.name } as IOption;
        })}
        isLoading={isLoading}
        onSubmitSearch={onSubmitSearch}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onDetail={onSelectedDetail}
        onOpenDrawer={() => setIsCloseCreateUpdate(true)}
        onChangePagination={onChangePagination}
      />
      <DrawerAddEditAccountComponent
        form={form}
        selected={selectedAccount}
        companys={companys.map((x) => {
          return { value: x.id, label: x.name } as IOption;
        })}
        isClose={isCloseCreateUpdate}
        onClose={onCloseCreateUpdate}
        onSubmit={onSubmitCreateUpdate}
        isLoading={!!(isLoadingCreate || isLoadingUpdate)}
      />
      <DrawerAccountDetailComponent
        selected={selectedAccountDetail}
        isOpen={isOpenAccountDetail}
        onClose={onCloseAccountDetail}
      />
    </div>
  );
};

export default AccountListPage;
