import { useEffect } from 'react';

import {
  Space,
  Button,
  Input,
  Form,
  Drawer,
  Select,
  Switch,
  InputNumber,
} from 'antd';
import { FormInstance } from 'antd/es/form';

import useCategoryMethodListState from './states/useCategoryMethodListState';
import useCurrencyListState from './states/useCurrencyListState';
import useMethodCreateState from './states/useMethodCreateState';
import useMethodUpdateState from './states/useMethodUpdateState';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../../hooks/useSuccessHandler';
import { ICategoryMethod } from '../../../../repositories/category-method/category-method.interface';
import { ICompany } from '../../../../repositories/company/company.interface';
import { IScanConfig } from '../../../../repositories/config/config.interface';
import {
  drawerStyle,
  maxPageSize,
} from '../../../../repositories/core/constant';
import { IOption } from '../../../../repositories/core/core.interface';
import { ICurrency } from '../../../../repositories/currency/currency.interface';
import { IMethod } from '../../../../repositories/method/method.interface';
import { match } from '../../../../ultis/custom-matcher';
import useCompanyListState from '../../../../wrappers/company/states/useCompanyListState';
import useScanConfigListState from '../../../config/components/states/useScanConfigListState';
import useGetConfigScanUserIdByState from '../../../scan/states/method-manage/bidding/useGetConfigScanUserIdByState';

const CreateUpdateMethodComponent = ({
  form,
  companys,
  categories,
  currencys,
  configs,
  onSubmit,
  onChangeCompanyId,
  onChangeConfigScanUserId,
}: {
  form: FormInstance<any>;
  companys: ICompany[];
  categories: ICategoryMethod[];
  currencys: ICurrency[];
  configs: IScanConfig[];
  onSubmit: (values: any) => void;
  onChangeCompanyId: (values: any) => void;
  onChangeConfigScanUserId: (values: any) => void;
}) => {
  const selectedCategory = categories.find(
    (x) => x.id === form.getFieldValue('categoryId'),
  );
  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        isDeliver: true,
        bid: 0,
        number: 0,
      }}
    >
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2">
          <Form.Item
            name="name"
            label="Tên công thức"
            rules={[
              {
                required: true,
                message: 'Chưa thiết lập tên công thức',
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="companyId"
            label="Công ty"
            rules={[
              {
                required: true,
                message: 'Chọn công ty',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              onChange={(value) => onChangeCompanyId(value)}
              disabled={form.getFieldValue('isDisabledCompanyId')}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={companys.map((x) => {
                return {
                  value: x.id,
                  label: x.companyCode,
                } as IOption;
              })}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="configScanUserId"
            label="Loại cấu hình"
            rules={[
              {
                required: true,
                message: 'Chọn loại cấu hình',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={form.getFieldValue('isDisabledConfigScanUserId')}
              onChange={(value) => onChangeConfigScanUserId(value)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={configs
                .filter((x) => x.companyId === form.getFieldValue('companyId'))
                .map((x) => {
                  return {
                    value: x.id,
                    label: x.name,
                  } as IOption;
                })}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="categoryId"
            label="Công thức tính"
            rules={[
              {
                required: true,
                message: 'Chọn công thức',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              allowClear
              showSearch
              disabled={form.getFieldValue('isDisabledCategoryId')}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={categories
                .filter((x) => {
                  const getConfig = configs.find(
                    (y) => y.id === form.getFieldValue('configScanUserId'),
                  );
                  return (
                    x.parentId === null && x.id === getConfig?.categoryParentId
                  );
                })
                .map((category) => {
                  return {
                    label: category.name,
                    options: categories
                      .filter((x) => x.parentId === category.id)
                      .map((child) => {
                        return {
                          label: child.name,
                          value: child.id,
                        };
                      }),
                  };
                })}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="currencyId"
            label="Loại tiền"
            rules={[
              {
                required: true,
                message: 'Chọn loại tiền',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={currencys.map((x) => {
                return { value: x.id, label: x.name } as IOption;
              })}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="number"
            label="Hệ số"
            rules={[{ required: true, message: 'Nhập hệ số' }]}
          >
            <InputNumber
              min={0}
              className="w-full"
              max={100000}
              defaultValue={0}
              placeholder="Nhập hệ số"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="bid"
            label="Gía thầu"
            rules={[{ required: true, message: 'Nhập giá thầu' }]}
          >
            <InputNumber
              min={0}
              className="w-full"
              max={100000}
              defaultValue={0}
              placeholder="Nhập giá thầu"
            />
          </Form.Item>
        </div>
        <div className="flex ">
          <Form.Item valuePropName="checked" name="isDeliver">
            <Switch
              disabled={form.getFieldValue('isDisabledDeliver')}
              checkedChildren={'Giao'}
              unCheckedChildren={'Nhận'}
            />
          </Form.Item>
        </div>
        <div className="col-span-2">
          Kết quả:{' '}
          {selectedCategory?.method
            .replaceAll('Bid', form.getFieldValue('bid') ?? 0)
            .replaceAll('Number', form.getFieldValue('number') ?? 0)}
        </div>
      </div>
    </Form>
  );
};

const DrawerCreateUpdateMethodComponent = ({
  selected,
  isClose,
  onClose,
  onReload,
}: {
  selected: IMethod | null;
  isClose: boolean;
  onClose: () => void;
  onReload: (value: IMethod) => void;
}) => {
  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useMethodCreateState();

  const { event: eventConfig, list: configs } = useScanConfigListState();

  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useMethodUpdateState();

  const { list: companys } = useCompanyListState();
  const { event: eventCategory, list: categories } =
    useCategoryMethodListState();
  const { event: eventCurrency, list: currencys } = useCurrencyListState();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const {
    state: stateGetConfigId,
    event: eventGetConfigId,
    configScan,
  } = useGetConfigScanUserIdByState();
  const [form] = Form.useForm();

  const onCloseCreateUpdate = () => {
    onClose();
    form.resetFields();
  };
  const onSubmitCreateUpdate = (values: IMethod) => {
    if (selected && selected.id) {
      eventUpdate.updateItem({
        id: selected.id,
        bid: values.bid ?? 0,
        categoryId: values.categoryId ?? 0,
        companyId: values.companyId ?? 0,
        currencyId: values.currencyId ?? 0,
        isDeliver: values.isDeliver ?? false,
        name: values.name ?? '',
        number: values.number ?? 0,
        configScanUserId: values.configScanUserId ?? 0,
      });
    } else {
      eventCreate.createItem({
        bid: values.bid ?? 0,
        categoryId: values.categoryId ?? 0,
        companyId: values.companyId ?? 0,
        currencyId: values.currencyId ?? 0,
        isDeliver: values.isDeliver ?? false,
        name: values.name ?? '',
        number: values.number ?? 0,
        configScanUserId: values.configScanUserId ?? 0,
      });
    }
  };

  const onChangeCompanyId = (value: number) => {
    form.setFieldValue('categoryId', null);
    form.setFieldValue('configScanUserId', null);
    eventCategory.getList({
      pageSize: maxPageSize,
      pageIndex: 1,
      companyId: value,
    });
  };

  const onChangeConfigScanUserId = () => {
    form.setFieldValue('categoryId', null);
  };

  const companyId = Form.useWatch('companyId', form);
  const categoryId = Form.useWatch('categoryId', form);
  const currencyId = Form.useWatch('currencyId', form);
  const configScanUserId = Form.useWatch('configScanUserId', form);
  const number = Form.useWatch('number', form);
  const bid = Form.useWatch('bid', form);
  const isDeliver = Form.useWatch('isDeliver', form);

  useEffect(() => {
    eventConfig.getList({
      pageSize: maxPageSize,
      pageIndex: 1,
    });
    eventCurrency.getList();
  }, []);

  useEffect(() => {
    const array = [];

    if (companyId) {
      array.push(companys.find((x) => x.id === companyId)?.name);
    }
    if (currencyId) {
      array.push(currencys.find((x) => x.id === currencyId)?.name);
    }
    if (bid) {
      array.push(bid);
    }
    if (number) {
      array.push(number);
    }
    if (categoryId) {
      array.push(categories.find((x) => x.id === categoryId)?.code);
    }
    if (!isDeliver) {
      array.push('(-1)');
    }
    if (configScanUserId) {
      array.push(configs.find((x) => x.id === configScanUserId)?.name);
    }
    form.setFieldValue('name', array.join('-'));
  }, [
    companyId,
    categoryId,
    number,
    bid,
    currencyId,
    isDeliver,
    configScanUserId,
  ]);

  useEffect(() => {
    if (
      selected &&
      selected.isDisabledConfigScanUserId &&
      companyId &&
      categoryId
    ) {
      eventGetConfigId.getDetail({ companyId, categoryId });
    }
  }, [categoryId]);

  useEffect(() => {
    match([stateGetConfigId, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess(res) {
        form.setFieldValue('configScanUserId', res.res.id);
      },
    });
  }, [stateGetConfigId.type]);

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess(res) {
        showSuccessDialog('Tạo công thức thành công!');
        onReload(res.value);
        onCloseCreateUpdate();
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess(res) {
        showSuccessDialog('Cập nhật công thức thành công!');
        onReload(res.value);
        onCloseCreateUpdate();
      },
    });
  }, [stateUpdate.type]);

  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      destroyOnClose={true}
      onClose={onCloseCreateUpdate}
      afterOpenChange={() => {
        if (selected) {
          eventCategory.getList({
            pageSize: maxPageSize,
            pageIndex: 1,
            companyId: selected.companyId ?? 0,
            onFinished() {
              if (selected.id) {
                form.setFieldsValue({
                  id: selected.id,
                  bid: selected.bid ?? 0,
                  categoryId: selected.categoryId ?? 0,
                  configScanUserId: selected.configScanUserId ?? 0,
                  companyId: selected.companyId ?? 0,
                  currencyId: selected.currencyId ?? 0,
                  isDeliver: selected.isDeliver ?? false,
                  name: selected.name ?? '',
                  number: selected.number ?? 0,
                });
              } else {
                if (selected.categoryId) {
                  form.setFieldsValue({
                    categoryId: selected.categoryId ?? 0,
                  });
                }
                if (selected.configScanUserId) {
                  form.setFieldsValue({
                    configScanUserId: selected.configScanUserId ?? 0,
                  });
                }
                if (selected.companyId) {
                  form.setFieldsValue({
                    companyId: selected.companyId ?? 0,
                  });
                }
                if (selected.isDisabledCompanyId) {
                  form.setFieldsValue({
                    isDisabledCompanyId: selected.isDisabledCompanyId ?? false,
                  });
                }
                if (selected.isDisabledCategoryId) {
                  form.setFieldsValue({
                    isDisabledCategoryId:
                      selected.isDisabledCategoryId ?? false,
                  });
                }
                if (selected.isDisabledConfigScanUserId) {
                  form.setFieldsValue({
                    isDisabledConfigScanUserId:
                      selected.isDisabledConfigScanUserId ?? false,
                  });
                }
                if (selected.isDisabledDeliver) {
                  form.setFieldsValue({
                    isDisabledDeliver: selected.isDisabledDeliver ?? false,
                  });
                }

                form.setFieldsValue({
                  isDeliver: selected.isDeliver ?? false,
                });
              }
            },
          });
        }
      }}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <CreateUpdateMethodComponent
        form={form}
        companys={companys}
        categories={categories}
        currencys={currencys}
        configs={configs}
        onSubmit={onSubmitCreateUpdate}
        onChangeCompanyId={onChangeCompanyId}
        onChangeConfigScanUserId={onChangeConfigScanUserId}
      />
    </Drawer>
  );
};

export default DrawerCreateUpdateMethodComponent;
