/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { PageItems } from '../../../../repositories/core/core.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import { IMethod } from '../../../../repositories/method/method.interface';
import MethodService from '../../../../services/method/method.service';

type IMethodListState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; res: PageItems<IMethod> };
  isLoading: boolean;
  isLoadingCreateUpdate: boolean;
  list: IMethod[];
  totalItems: number;
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (res: PageItems<IMethod>) => void;
};

const createStore = () =>
  create<IMethodListState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    isLoadingCreateUpdate: false,
    list: [],
    totalItems: 0,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (res) =>
      set({
        state: { type: 'fetchSuccess', res },
        list: res.items,
        totalItems: res.totalItems,
      }),
  }));

const useMethodListState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    list,
    totalItems,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = useStore();

  const getList = async ({
    pageSize,
    pageIndex,
    companyId,
    categoryId,
    name,
  }: {
    pageSize: number;
    pageIndex: number;
    companyId?: number;
    categoryId?: number;
    name?: string;
  }) => {
    start();
    setLoading(true);
    const result = await MethodService.getList({
      sortNew: true,
      pageSize,
      pageIndex,
      companyId,
      categoryId,
      name,
    });
    if (isRight(result)) {
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    list,
    totalItems,
    event: {
      getList,
    },
  };
};

export default useMethodListState;
