import { Either } from 'fp-ts/lib/Either';

import { ICategoryMethod } from '../../repositories/category-method/category-method.interface';
import CategoryMethodRepository from '../../repositories/category-method/category-method.repository';
import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';

class CategoryMethodService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
    companyId,
    companyCode,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    companyId?: number;
    companyCode?: string;
  }): Promise<Either<ErrorType, PageItems<ICategoryMethod>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    if (companyId) {
      params.append('companyId', companyId.toString());
    }
    if (companyCode) {
      params.append('companyCode', companyCode);
    }
    return CategoryMethodRepository.getList({ params });
  }
}

export default CategoryMethodService;
