import { message } from 'antd';

import { ErrorType } from '../repositories/core/error-type';
import { mayBeMatch } from '../ultis/custom-matcher';

export const useErrorHandler = () => {
  const getMessageByCode = (code: string) => {
    switch (code) {
      case 'NOT_EMPTY':
        return 'Dữ liệu bị trống';
      case 'NOT_FOUND':
        return 'Không tìm thấy dữ liệu';
      case 'USERNAME_IS_EXIST':
        return 'Tên tài khoản đã có, xin chọn tên khác';
      case 'USERNAME_OR_PASSWORD_NOT_EMPTY':
        return 'Tên tài khoản hoặc mật khẩu không được trống';
      case 'NOT_UPDATE_USERNAME':
        return 'Không được thay đổi tên tài khoản';
      case 'UPDATE_USER_NOT_YOUR':
        return 'Không cập nhật được tài khoản khác';
      case 'DELETE_USER_NOT_YOUR':
        return 'Không xóa được tài khoản khác';
      case 'LOCK_USER_NOT_YOUR':
        return 'Không khóa được tài khoản khác';
      case 'UNLOCK_USER_NOT_YOUR':
        return 'Không mở khóa được tài khoản khác';
      case 'OLDPASSWORD_OR_NEWPASSWORD_NOT_EMPTY':
        return 'Mật khẩu không được trống';
      case 'OLDPASSWORD_INCORRECT':
        return 'Mật khẩu cũ không đúng';
      case 'ROLEID_NOT_EMPTY':
        return 'Loại phân quyền không được trống';
      case 'PERMISSION_DENIED':
        return 'Chặn quyền truy cập';
      case 'USERNAME_OR_PASSWORD_INCORRECT':
        return 'Tên tài khoản hoặc mật khẩu không đúng';
      case 'NOT_UPDATE_USERNAME_ACCOUNT':
        return 'Không cập nhật được tên tài khoản';
      case 'MAP_MEMBER_METHOD_IS_EXIST':
        return 'Đã có công thức và thành viên này';
      case 'ACCOUNTSCAN_EXISTS_METHOD':
        return 'Đã có công thức của tuyến dưới này';
      case 'ACCOUNTSCAN_EXISTS_CHILD':
        return 'Không thể xóa vì có tuyến dưới';
      case 'REPORT_NO_DATA':
        return 'Chưa có dữ liệu để tạo báo cáo';

      default:
        return 'Lỗi xử lý dữ liệu';
    }
  };

  function showErrorDialog(error: ErrorType) {
    mayBeMatch([error, 'kind'])({
      UnauthorizedError: () => {
        message.open({
          type: 'error',
          content: getMessageByCode(error.message),
        });
      },
      DefinedError: () => {
        message.open({
          type: 'error',
          content: getMessageByCode(error.message),
        });
      },
      orElse: () => {
        return message.open({
          type: 'error',
          content: 'This is an error message',
        });
      },
    });
  }

  return { showErrorDialog };
};
