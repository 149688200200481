import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import {
  IInputExchangeCurrency,
  IReport,
  IReportDetail,
  IReportExport,
  IReportCompanyDetail,
  IReportCompare,
} from '../../repositories/report/report.interface';
import ReportRepository from '../../repositories/report/report.repository';

class ReportService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
  }): Promise<Either<ErrorType, PageItems<IReport>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    return ReportRepository.getList({ params });
  }

  static create({
    name,
    description,
    isQuick,
    fromDate,
    toDate,
    arrayAccountId,
  }: {
    name: string;
    description: string;
    isQuick: boolean;
    fromDate: string;
    toDate: string;
    arrayAccountId: number[];
  }): Promise<Either<ErrorType, number>> {
    return ReportRepository.create({
      name,
      description,
      isQuick,
      fromDate,
      toDate,
      arrayAccountId,
    });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return ReportRepository.delete({ id });
  }

  static export({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, IReportExport>> {
    return ReportRepository.export({ id });
  }

  static getDetail({
    sortNew,
    pageSize,
    pageIndex,
    reportId,
    memberId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    reportId: number;
    memberId: number | null;
  }): Promise<Either<ErrorType, IReportDetail>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      ReportId: reportId.toString(),
    });
    if (memberId) {
      params.append('memberId', memberId.toString());
    }
    return ReportRepository.getDetail({ params });
  }

  static getDetailMarketCodeBy({
    sortNew,
    pageSize,
    pageIndex,
    reportId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    reportId: number;
  }): Promise<Either<ErrorType, IReportCompanyDetail>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      ReportId: reportId.toString(),
    });
    return ReportRepository.getDetailMarketCodeBy({ params });
  }

  static exchangeCurrency({
    fromCurrencyId,
    toCurrencyId,
    range,
    reportId,
    arrayMemberId,
  }: {
    fromCurrencyId: number;
    toCurrencyId: string;
    reportId: number;
    range: number;
    arrayMemberId: number[];
  }): Promise<Either<ErrorType, boolean>> {
    const data = {
      fromCurrencyId,
      toCurrencyId,
      reportId,
      range,
      arrayMemberId,
    } as IInputExchangeCurrency;

    return ReportRepository.exchangeCurrency({ data });
  }

  static deleteExchangeCurrency({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    return ReportRepository.deleteExchangeCurrency({ id });
  }

  static exportExcel({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, string>> {
    return ReportRepository.exportExcel({ id });
  }

  static getMethodsCompare({
    reportId,
    memberId,
    accountScan,
  }: {
    reportId: number;
    memberId: number;
    accountScan: string;
  }): Promise<Either<ErrorType, IReportCompare[]>> {
    const params = new URLSearchParams({
      reportId: reportId.toString(),
      memberId: memberId.toString(),
      accountScanName: accountScan.toString(),
    });
    return ReportRepository.getMethodsCompare({ params });
  }
}

export default ReportService;
