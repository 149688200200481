import { useState } from 'react';

import { Tabs } from 'antd';
import classNames from 'classnames';

import TabItemConfigComponent from './tab-item-config';
import { ITabbar } from '../../../repositories/core/core.interface';

const initialItems: ITabbar[] = [
  {
    label: 'Công ty',
    key: '1',
  },
];
const TabbarConfigComponent = () => {
  const [activeKey, setActiveKey] = useState(initialItems[0]?.key);
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };
  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={onChange}
        type="editable-card"
        items={initialItems}
      />
      {initialItems.map((x) => {
        return (
          <div
            key={x.key}
            className={classNames(activeKey === x.key ? 'block' : 'hidden')}
          >
            <TabItemConfigComponent />
          </div>
        );
      })}
    </div>
  );
};

export default TabbarConfigComponent;
