import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { ILogged, IUser, IUserInput } from './user.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class UserRepository {
  static userLogin({
    username,
    password,
    clientId,
    grantType,
  }: {
    username: string;
    password: string;
    clientId: string;
    grantType: string;
  }): Promise<Either<ErrorType, ILogged>> {
    const query = () =>
      axiosClient<ILogged>({
        method: Methods.POST,
        url: '/token',
        data: {
          username,
          password,
          clientId,
          grantType,
        },
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IUser>>> {
    const query = () =>
      axiosClient<PageItems<IUser>>({
        method: Methods.GET,
        url: '/v1/users/get-list-detail-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getPackage(): Promise<Either<ErrorType, any>> {
    const query = () =>
      axiosClient<any>({
        method: Methods.GET,
        url: '/v1/users/package',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IUserInput;
  }): Promise<Either<ErrorType, IUser>> {
    const query = () =>
      axiosClient<IUser>({
        method: Methods.POST,
        url: '/v1/users',
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static update({
    data,
  }: {
    data: IUserInput;
  }): Promise<Either<ErrorType, IUser>> {
    const query = () =>
      axiosClient<IUser>({
        method: Methods.PUT,
        url: '/v1/users',
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/users/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static lock({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.PUT,
        url: `/v1/users/lock/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static unlock({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.PUT,
        url: `/v1/users/unlock/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default UserRepository;
