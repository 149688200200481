import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { IMethod } from './method.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class MethodRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IMethod>>> {
    const query = () =>
      axiosClient<PageItems<IMethod>>({
        method: Methods.GET,
        url: '/v1/methods/get-list-detail-by',
        params,
        data: {},
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IMethod;
  }): Promise<Either<ErrorType, IMethod>> {
    const query = () =>
      axiosClient<IMethod>({
        method: Methods.POST,
        url: `/v1/methods`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static update({
    data,
  }: {
    data: IMethod;
  }): Promise<Either<ErrorType, IMethod>> {
    const query = () =>
      axiosClient<IMethod>({
        method: Methods.PUT,
        url: `/v1/methods`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/methods/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }
}

export default MethodRepository;
