import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import {
  ICustomer,
  ICustomerMapBiddingMethod,
  ICustomerMapMethod,
  ICustomerReport,
} from './customer.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class CustomerRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<ICustomer>>> {
    const query = () =>
      axiosClient<PageItems<ICustomer>>({
        method: Methods.GET,
        url: '/v1/members/get-list-detail-by',
        params,
        data: {},
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: ICustomer;
  }): Promise<Either<ErrorType, ICustomer>> {
    const query = () =>
      axiosClient<ICustomer>({
        method: Methods.POST,
        url: `/v1/members`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static update({
    data,
  }: {
    data: ICustomer;
  }): Promise<Either<ErrorType, ICustomer>> {
    const query = () =>
      axiosClient<ICustomer>({
        method: Methods.PUT,
        url: `/v1/members`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/members/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static getListMapAccountScan({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<ICustomerMapMethod>>> {
    const query = () =>
      axiosClient<PageItems<ICustomerMapMethod>>({
        method: Methods.GET,
        url: '/v1/members/get-list-map-account-scan-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getBiddingListMapAccountScan({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<ICustomerMapBiddingMethod>>> {
    const query = () =>
      axiosClient<PageItems<ICustomerMapBiddingMethod>>({
        method: Methods.GET,
        url: '/v1/account-scans/get-list-bid-divison-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getListViewReport({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<ICustomerReport>>> {
    const query = () =>
      axiosClient<PageItems<ICustomerReport>>({
        method: Methods.GET,
        url: '/v1/member-logins/get-list-detail-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static createViewReport({
    data,
  }: {
    data: ICustomerReport;
  }): Promise<Either<ErrorType, ICustomerReport>> {
    const query = () =>
      axiosClient<ICustomerReport>({
        method: Methods.POST,
        url: `/v1/member-logins`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static updateViewReport({
    data,
  }: {
    data: ICustomerReport;
  }): Promise<Either<ErrorType, ICustomerReport>> {
    const query = () =>
      axiosClient<ICustomerReport>({
        method: Methods.PUT,
        url: `/v1/member-logins`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static deleteViewReport({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/member-logins/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }
}

export default CustomerRepository;
