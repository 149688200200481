import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { IAuth } from '../../../repositories/auth/auth.interface';
import { ErrorType } from '../../../repositories/core/error-type';
import { IUsedPackage } from '../../../repositories/package/package.interface';
import AuthService from '../../../services/auth/auth.service';
import LocalStorage from '../../../ultis/local-storage';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; auth: IAuth };
  isLoading: boolean;
  currentAuth: IAuth | null;
  currentPackage: IUsedPackage | null;
  start: () => void;
  setCurrentAuth: (value: IAuth) => void;
  setCurrentPackage: (value: IUsedPackage) => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (value: IAuth) => void;
};

const createStore = create<IState>((set) => ({
  state: { type: 'init' },
  isLoading: false,
  currentAuth: null,
  currentPackage: null,
  start: () => set({ state: { type: 'start' } }),
  setCurrentAuth: (value) => set({ currentAuth: value }),
  setCurrentPackage: (value) => set({ currentPackage: value }),
  setLoading: (value) => set({ isLoading: value }),
  fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
  fetchSuccess: (value) =>
    set({ state: { type: 'fetchSuccess', auth: value }, currentAuth: value }),
}));

const useAuthenticatedState = () => {
  const {
    state,
    isLoading,
    currentAuth,
    currentPackage,
    start,
    setLoading,
    setCurrentPackage,
    fetchSuccess,
    fetchFailed,
  } = createStore();

  const getPackageDetail = async () => {
    start();
    setLoading(true);
    const result = await AuthService.getPackageDetail();
    if (isRight(result)) {
      setCurrentPackage(result.right);
    }
    setLoading(false);
  };

  const logout = async () => {
    start();
    setLoading(true);
    LocalStorage.removeToken();
    setLoading(false);
  };

  const getCurrentAuth = async () => {
    start();
    setLoading(true);
    const result = await AuthService.getDetail();

    if (isRight(result)) {
      getPackageDetail();
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
      LocalStorage.removeToken();
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    currentAuth,
    currentPackage,
    event: {
      getCurrentAuth,
      logout,
    },
  };
};

export default useAuthenticatedState;
