import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import useCompanyListState from './states/useCompanyListState';

const CompanyWrapper = () => {
  const { event } = useCompanyListState();
  useEffect(() => {
    event.getList();
  }, []);
  return <Outlet />;
};

export default CompanyWrapper;
