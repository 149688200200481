import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import { IHistory } from '../../repositories/history/history.interface';
import HistoryRepository from '../../repositories/history/history.repository';

class HistoryService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
  }): Promise<Either<ErrorType, PageItems<IHistory>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    return HistoryRepository.getList({ params });
  }
}

export default HistoryService;
