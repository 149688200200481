import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import {
  ICustomer,
  ICustomerMapBiddingMethod,
  ICustomerMapMethod,
  ICustomerReport,
} from '../../repositories/customer/customer.interface';
import CustomerRepository from '../../repositories/customer/customer.repository';

class CustomerService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
    username,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    username?: string;
  }): Promise<Either<ErrorType, PageItems<ICustomer>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      username: username ?? '',
    });
    return CustomerRepository.getList({ params });
  }

  static create({
    username,
    keyMatch,
  }: {
    username: string;
    keyMatch: string;
  }): Promise<Either<ErrorType, ICustomer>> {
    const variables = {
      username,
      keyMatch,
    } as ICustomer;
    return CustomerRepository.create({ data: variables });
  }

  static update({
    id,
    username,
    keyMatch,
  }: {
    id: number;
    username: string;
    keyMatch: string;
  }): Promise<Either<ErrorType, ICustomer>> {
    const variables = {
      id,
      username,
      keyMatch,
    } as ICustomer;
    return CustomerRepository.update({ data: variables });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return CustomerRepository.delete({ id });
  }

  static getListMapAccountScan({
    pageSize,
    pageIndex,
    accountScanId,
  }: {
    pageSize: number;
    pageIndex: number;
    accountScanId: number;
  }): Promise<Either<ErrorType, PageItems<ICustomerMapMethod>>> {
    const params = new URLSearchParams({
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      accountScanId: accountScanId.toString(),
    });
    return CustomerRepository.getListMapAccountScan({ params });
  }

  static getBiddingListMapAccountScan({
    pageSize,
    pageIndex,
    accountScanId,
  }: {
    pageSize: number;
    pageIndex: number;
    accountScanId: number;
  }): Promise<Either<ErrorType, PageItems<ICustomerMapBiddingMethod>>> {
    const params = new URLSearchParams({
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      accountScanId: accountScanId.toString(),
    });
    return CustomerRepository.getBiddingListMapAccountScan({ params });
  }

  static getListViewReport({
    sortNew,
    pageSize,
    pageIndex,
    memberId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    memberId: number;
  }): Promise<Either<ErrorType, PageItems<ICustomerReport>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      memberId: memberId.toString(),
    });
    return CustomerRepository.getListViewReport({ params });
  }

  static createViewReport({
    username,
    password,
    memberId,
  }: {
    username: string;
    password: string;
    memberId: number;
  }): Promise<Either<ErrorType, ICustomerReport>> {
    const variables = {
      username,
      password,
      memberId,
    } as ICustomerReport;
    return CustomerRepository.createViewReport({ data: variables });
  }

  static updateViewReport({
    id,
    username,
    password,
    memberId,
  }: {
    id: number;
    username: string;
    password: string;
    memberId: number;
  }): Promise<Either<ErrorType, ICustomerReport>> {
    const variables = {
      id,
      username,
      password,
      memberId,
    } as ICustomerReport;
    return CustomerRepository.updateViewReport({ data: variables });
  }

  static deleteViewReport({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    return CustomerRepository.deleteViewReport({ id });
  }
}

export default CustomerService;
