import { Either } from 'fp-ts/lib/Either';

import { ICompany } from '../../repositories/company/company.interface';
import CompanyRepository from '../../repositories/company/company.repository';
import { ErrorType } from '../../repositories/core/error-type';

class CompanyService {
  static getList(): Promise<Either<ErrorType, ICompany[]>> {
    return CompanyRepository.getList();
  }
}

export default CompanyService;
