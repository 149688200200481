import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import UserService from '../../../../services/user/user.service';
import LocalStorage from '../../../../ultis/local-storage';

type ILoginState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'loginFailed'; error: ErrorType }
    | { type: 'loginSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  loginFailed: (error: ErrorType) => void;
  loginSuccess: () => void;
};

const createStore = () =>
  create<ILoginState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    loginFailed: (error) => set({ state: { type: 'loginFailed', error } }),
    loginSuccess: () => set({ state: { type: 'loginSuccess' } }),
  }));

const useLoginPageState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, loginFailed, loginSuccess } =
    useStore();

  const loginUserByToken = async ({
    username,
    password,
    clientId,
    grantType,
  }: {
    username: string;
    password: string;
    clientId: string;
    grantType: string;
  }) => {
    start();
    setLoading(true);
    const result = await UserService.userLogin({
      username,
      password,
      clientId,
      grantType,
    });
    if (isRight(result)) {
      LocalStorage.setToken(result.right.tokenId);
      loginSuccess();
    } else {
      loginFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      loginUserByToken,
    },
  };
};

export default useLoginPageState;
