import { useEffect, useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Table,
  Form,
  FormInstance,
  Tooltip,
  TablePaginationConfig,
} from 'antd';

import DrawerCreateUpdateViewReportComponent from './components/DrawerCreateUpdateViewReportComponent';
import useAccountViewReportDeleteState from './states/useViewReportDeleteState';
import useAccountViewReportListState from './states/useViewReportListState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { defaultPageSize } from '../../../repositories/core/constant';
import { ICustomerReport } from '../../../repositories/customer/customer.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';

const TableList = ({
  isLoading,
  list,
  totalItems,
  current,
  onDelete,
  onUpdate,
  onOpenDrawer,
  onChangePagination,
}: {
  isLoading: boolean;
  list: ICustomerReport[];
  form: FormInstance<any>;
  totalItems: number;
  current: number;
  onDelete: (item: ICustomerReport) => void;
  onUpdate: (item: ICustomerReport) => void;
  onOpenDrawer: () => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns = [
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Mật khẩu',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: ICustomerReport) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: ICustomerReport) => (
        <div className="flex gap-2">
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>

          <Tooltip title="Xóa tài khoản">
            <Popconfirm
              title="Xóa tài khoản này"
              description="Bạn có chắc chắn xóa tài khoản này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mb-3 text-right">
        <Button onClick={onOpenDrawer} type="primary">
          Thêm tài khoản
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const CustomerViewReportListPage = ({ memberId }: { memberId: number }) => {
  const { state, event, isLoading, list, totalItems } =
    useAccountViewReportListState();

  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useAccountViewReportDeleteState();

  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedViewReport, setSelectedViewReport] =
    useState<ICustomerReport | null>(null);
  const [isCloseCreateUpdate, setIsCloseCreateUpdate] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      memberId,
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  const onConfirmDelete = (item: ICustomerReport) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onCloseCreateUpdate = () => {
    setIsCloseCreateUpdate(false);
    setSelectedViewReport(null);
  };
  const onSelectedUpdate = (value: ICustomerReport) => {
    setIsCloseCreateUpdate(true);
    setSelectedViewReport(value);
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa tài khoản thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <TableList
        form={formSearch}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        isLoading={isLoading}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsCloseCreateUpdate(true)}
        onChangePagination={onChangePagination}
      />
      <DrawerCreateUpdateViewReportComponent
        memberId={memberId}
        selected={selectedViewReport}
        isClose={isCloseCreateUpdate}
        onClose={onCloseCreateUpdate}
        onReload={() => onLoad({})}
      />
    </div>
  );
};

export default CustomerViewReportListPage;
