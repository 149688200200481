import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { ICurrency } from './currency.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class CurrencyRepository {
  static getList(): Promise<Either<ErrorType, ICurrency[]>> {
    const query = () =>
      axiosClient<ICurrency[]>({
        method: Methods.GET,
        url: '/v1/currencys',
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default CurrencyRepository;
