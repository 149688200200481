import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import {
  ILogged,
  IUser,
  IUserInput,
} from '../../repositories/user/user.interface';
import UserRepository from '../../repositories/user/user.repository';

class UserService {
  static userLogin({
    username,
    password,
    clientId,
    grantType,
  }: {
    username: string;
    password: string;
    clientId: string;
    grantType: string;
  }): Promise<Either<ErrorType, ILogged>> {
    return UserRepository.userLogin({
      username,
      password,
      clientId,
      grantType,
    });
  }

  static getList({
    sortNew,
    pageSize,
    pageIndex,
    search,
    isLock,
    roleId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    search?: string;
    isLock?: boolean;
    roleId?: number;
  }): Promise<Either<ErrorType, PageItems<IUser>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    if (search) {
      params.append('search', search.toString());
    }
    if (isLock) {
      params.append('isLock', isLock.toString());
    }
    if (roleId) {
      params.append('roleId', roleId.toString());
    }
    return UserRepository.getList({ params });
  }

  static create({
    password,
    username,
    description,
    roleId,
  }: {
    password: string;
    username: string;
    description: string;
    roleId: number;
  }): Promise<Either<ErrorType, IUser>> {
    const data = {
      password,
      username,
      description,
      roleId,
    } as IUserInput;
    return UserRepository.create({ data });
  }

  static update({
    password,
    id,
    description,
    roleId,
  }: {
    password: string;
    description: string;
    roleId: number;
    id: number;
  }): Promise<Either<ErrorType, IUser>> {
    const data = {
      password,
      id,
      description,
      roleId,
    } as IUserInput;
    return UserRepository.update({ data });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return UserRepository.delete({ id });
  }

  static lock({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return UserRepository.lock({ id });
  }

  static unlock({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return UserRepository.unlock({ id });
  }

  static getPackage(): Promise<Either<ErrorType, any>> {
    return UserRepository.getPackage();
  }
}

export default UserService;
