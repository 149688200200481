import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';
import { IReport, IReportDetail } from '../report/report.interface';

class ViewReportRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IReport>>> {
    const query = () =>
      axiosClient<PageItems<IReport>>({
        method: Methods.GET,
        url: '/v1/reports/get-list-by-member',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getDetail({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IReportDetail>> {
    const query = () =>
      axiosClient<IReportDetail>({
        method: Methods.GET,
        url: '/v1/reports/get-list-report-detail-by-member',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default ViewReportRepository;
