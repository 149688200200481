import { PropsWithChildren } from 'react';

import useAuthenticatedState from '../auth/states/useAuthenticatedState';

const AdminWrapper = ({ children }: PropsWithChildren) => {
  const { currentAuth } = useAuthenticatedState();

  return <>{currentAuth?.roleId === 1 && children}</>;
};

export default AdminWrapper;
