import { useEffect, useState } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Radio,
  RadioChangeEvent,
  Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-extraneous-dependencies

import StatusAccountScan from './account-status-scan';
import CountUpScanComponent from './count-up-scan';
import ModalReportCreate from './modal-report-create';
import CustomDatePicker from '../../../components/CustomDatepicker';
import usePrevious from '../../../hooks/usePrevious';
import { IAccount } from '../../../repositories/account/account.interface';
import {
  ConnectionCode,
  SystemTimeZone,
  maxPageSize,
} from '../../../repositories/core/constant';
import { IOption } from '../../../repositories/core/core.interface';
import useCustomerListState from '../../customer/list/states/useCustomerListState';

const FilterScanComponent = ({
  form,
  accounts,
  onScan,
  onChangeIsAll,
  onChangeMemberId,
}: {
  form: FormInstance<any>;
  accounts: IAccount[];
  onScan: (accounts: IAccount[]) => void;
  onChangeIsAll: (value: CheckboxChangeEvent) => void;
  onChangeMemberId: (value: number) => void;
}) => {
  const {
    event: eventCustomer,
    isLoading: isLoadingCustomer,
    list: customers,
  } = useCustomerListState();
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [totalScanning, setTotalScanning] = useState<number>(0);
  const today = moment.tz(SystemTimeZone).startOf('day');
  const startWeek = moment.tz(SystemTimeZone).startOf('isoWeek').startOf('day');
  const endWeek = startWeek.clone().endOf('isoWeek').startOf('day');

  const startWeekAgo = startWeek
    .clone()
    .add(-1, 'days')
    .startOf('isoWeek')
    .startOf('day');
  const endWeekAgo = startWeekAgo.clone().endOf('isoWeek').startOf('day');

  const dateList: IOption[] = [
    {
      label: 'Hôm nay',
      value: 'today',
    },
    {
      label: 'Hôm qua',
      value: 'yesterday',
    },
    {
      label: 'Tuần này',
      value: 'week',
    },
    {
      label: 'Tuần trước',
      value: 'weekAgo',
    },
  ];

  const onChangeRadio = (e: RadioChangeEvent) => {
    if (e.target.value === 'today') {
      form.setFieldValue('fromDate', today);
      form.setFieldValue('toDate', today);
    } else if (e.target.value === 'yesterday') {
      form.setFieldValue(
        'fromDate',
        today.clone().add(-1, 'days').startOf('day'),
      );
      form.setFieldValue(
        'toDate',
        today.clone().add(-1, 'days').startOf('day'),
      );
    } else if (e.target.value === 'week') {
      form.setFieldValue('fromDate', startWeek);
      form.setFieldValue('toDate', endWeek);
    } else if (e.target.value === 'weekAgo') {
      form.setFieldValue('fromDate', startWeekAgo);
      form.setFieldValue('toDate', endWeekAgo);
    }
  };

  const waitingList = accounts.filter(
    (x) => x.connectionCode === ConnectionCode.WAITING,
  );
  const processingList = accounts.filter(
    (x) => x.connectionCode === ConnectionCode.PROCESSING,
  );
  const failedList = accounts.filter(
    (x) => x.connectionCode === ConnectionCode.FAILED,
  );
  const stoppedList = accounts.filter(
    (x) =>
      x.connectionCode !== ConnectionCode.WAITING &&
      x.connectionCode !== ConnectionCode.FAILED &&
      x.connectionCode !== ConnectionCode.PROCESSING &&
      x.connectionCode !== ConnectionCode.SUCCESS,
  );
  const prevProcessingList = usePrevious(processingList) ?? ([] as IAccount[]);

  const onScanList = (list: IAccount[]) => {
    setTotalScanning(list.length);
    onScan(list);
    setIsScanning(true);
  };

  useEffect(() => {
    eventCustomer.getList({ pageSize: maxPageSize, pageIndex: 1 });
    form.setFieldsValue({
      fromDate: today,
      toDate: today,
      isQuick: true,
      isAll: true,
    });
  }, []);

  useEffect(() => {
    if (
      waitingList.length === 0 &&
      processingList.length === 0 &&
      prevProcessingList.length > 0 &&
      isScanning
    ) {
      setIsScanning(false);
    }
  }, [processingList]);
  return (
    <Form form={form} layout="vertical">
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
        <div className="rounded-md border border-dashed border-neutral-300 p-2">
          <div className="w-full grid-cols-1 gap-2">
            <div className="text-left">
              <div className="mb-2 w-full">
                <Radio.Group defaultValue={'today'}>
                  {dateList.map((x) => {
                    return (
                      <Radio
                        key={x.value}
                        className="mb-2"
                        onChange={onChangeRadio}
                        value={x.value}
                      >
                        {x.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
              <div className="flex w-full gap-2 text-left">
                <Form.Item name="fromDate">
                  <CustomDatePicker
                    style={{ width: '100%' }}
                    format={'DD/MM/YYYY'}
                    placeholder="Chọn ngày bắt đầu"
                  />
                </Form.Item>
                <Form.Item name="toDate">
                  <CustomDatePicker
                    style={{ width: '100%' }}
                    format={'DD/MM/YYYY'}
                    placeholder="Chọn ngày kết thúc"
                  />
                </Form.Item>
              </div>
              <div className="mb-2 flex gap-2">
                <Form.Item valuePropName="checked" name="isQuick">
                  <Checkbox defaultChecked>Lấy nhanh</Checkbox>
                </Form.Item>
              </div>
              <div className="flex gap-2">
                <Button
                  disabled={
                    processingList.length > 0 ||
                    (accounts.length === 0 && processingList.length === 0)
                  }
                  onClick={() => onScanList(accounts)}
                  type="primary"
                >
                  Lấy dữ liệu
                </Button>
                <ModalReportCreate
                  formFilter={form}
                  disabled={
                    processingList.length > 0 ||
                    (accounts.length === 0 && processingList.length === 0)
                  }
                  arrayAccountId={accounts.map((item) => item.id)}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 flex gap-2">
            <div className="w-full">
              <Form.Item name="customerId">
                <Select
                  loading={isLoadingCustomer}
                  placeholder="Lọc theo khách hàng"
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={customers.map((x) => {
                    return {
                      value: x.id,
                      label: x.username,
                    } as IOption;
                  })}
                  onChange={(value) => onChangeMemberId(value)}
                />
              </Form.Item>
            </div>
            <div className="flex-none">
              <Form.Item valuePropName="checked" name="isAll">
                <Checkbox onChange={onChangeIsAll} defaultChecked>
                  Tất cả
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col rounded-md border border-dashed border-neutral-300 lg:col-span-2">
          <div className="grow p-2">
            <div className="h-[180px] overflow-auto">
              <div className="grid grid-cols-2 gap-2 overflow-auto !text-xs lg:grid-cols-3">
                {accounts
                  .filter((x) => x.connectionCode !== 'SUCCESS')
                  .map((account) => (
                    <StatusAccountScan key={account.id} account={account} />
                  ))}
              </div>
            </div>
          </div>

          <div className="px-1">
            <CountUpScanComponent
              isScanning={isScanning}
              processingList={processingList}
              totalScanning={totalScanning}
            />
          </div>
          <div className="w-full border-t border-solid border-neutral-300 lg:h-8">
            <div className="grid h-full w-full grid-cols-2 gap-2  p-1 lg:grid-cols-4">
              <Button disabled size="small">
                {waitingList.length} Chờ quét
              </Button>
              <Button disabled type="primary" size="small">
                {processingList.length} Đang quét
              </Button>
              <Button
                onClick={() => onScanList(failedList)}
                danger
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
              >
                {failedList.length} lỗi
              </Button>
              <Button
                onClick={() => onScanList(stoppedList)}
                danger
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
              >
                {stoppedList.length} ngưng quét
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FilterScanComponent;
