/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

// eslint-disable-next-line lodash/import-scope
import { create } from 'zustand';

import { IAccountScanMethod } from '../../../../repositories/account-scan/account-scan.interface';

type IState = {
  state: { type: 'init' } | { type: 'start' };
  list: IAccountScanMethod[];
  start: () => void;
  setList: (list: IAccountScanMethod[]) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    list: [],
    start: () => set({ state: { type: 'start' } }),
    setList: (list) => set({ list }),
  }));

const useAccountScanMethodListScanState = () => {
  const [useStore] = useState(createStore);
  const { state, list, start, setList } = useStore();

  const setupLevel = (list: IAccountScanMethod[]) => {
    const oneLevel = list.filter((x) => x.parentId === null);
    oneLevel.forEach((one) => {
      // eslint-disable-next-line no-param-reassign
      one.children = list.filter((x) => x.parentId === one.id);
      one.children.forEach((two) => {
        // eslint-disable-next-line no-param-reassign
        two.children = list.filter((x) => x.parentId === two.id);
        two.children.forEach((three) => {
          // eslint-disable-next-line no-param-reassign
          three.children = list.filter((x) => x.parentId === three.id);
          three.children.forEach((four) => {
            // eslint-disable-next-line no-param-reassign
            four.children = list.filter((x) => x.parentId === four.id);
            four.children.forEach((five) => {
              // eslint-disable-next-line no-param-reassign
              five.children = list.filter((x) => x.parentId === five.id);
            });
          });
        });
      });
    });
    return oneLevel;
  };

  const getList = async (list: IAccountScanMethod[]) => {
    start();
    const levelList = setupLevel(list);
    setList(levelList);
  };

  return {
    state,
    list,
    event: {
      getList,
    },
  };
};

export default useAccountScanMethodListScanState;
