import { useState } from 'react';

import {
  BarcodeOutlined,
  ClusterOutlined,
  LoginOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Image, MenuProps, Menu, QRCode, Button, Drawer } from 'antd';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import { URL_TELEGRAM_SUPPORT } from '../../../repositories/core/constant';
import useAuthenticatedState from '../states/useAuthenticatedState';

const MainNavigation = ({ onNavigate }: { onNavigate: () => void }) => {
  const { event } = useAuthenticatedState();
  const [current, setCurrent] = useState('mail');
  const navigate = useNavigate();
  const items: MenuProps['items'] = [
    {
      label: 'Kế Toán',
      key: 'accounting-manager',
      icon: <PieChartOutlined />,
      children: [
        {
          label: 'Kế toán',
          key: 'scan',
        },
        {
          label: 'Báo cáo',
          key: 'report',
        },
      ],
    },
    {
      label: 'Công thức',
      key: 'method-manager',
      icon: <MenuUnfoldOutlined />,
      children: [
        {
          label: 'Công thức',
          key: 'method',
        },
        {
          label: 'Nhóm công thức',
          key: 'group-method',
        },
      ],
    },
    {
      label: 'Quản lý Sub',
      key: 'general-manager',
      icon: <BarcodeOutlined />,
      children: [
        {
          label: 'Sub quét',
          key: 'account',
        },
        {
          label: 'Khách hàng',
          key: 'customer',
        },
      ],
    },
    {
      label: 'Cấp tài khoản',
      key: 'user',
      icon: <UserOutlined />,
    },
    {
      label: 'Lịch sử',
      key: 'history',
      icon: <ClusterOutlined />,
    },
    {
      label: 'Cấu hình',
      key: 'config',
      icon: <SettingOutlined />,
    },
    {
      label: 'Đăng xuất',
      key: 'logout',
      icon: <LoginOutlined />,
    },
  ];
  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      event.logout();
      navigate(`login`);
    } else {
      setCurrent(e.key);
      navigate(`${e.key}`);
      onNavigate();
    }
  };
  return (
    <>
      <div className="h-full w-full rounded-lg bg-white">
        <div className="flex h-full w-full flex-col">
          <div className="flex-none py-4 text-center">
            <Link
              to={'/'}
              onClick={() => {
                onNavigate();
              }}
            >
              <Image width={150} src="logo.png" preview={false} />
            </Link>
          </div>
          <div className="flex-1 grow">
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="inline"
              items={items}
              style={{ fontSize: 14, border: 'none', background: 'none' }}
            />
          </div>
          <div className="py-4">
            <div className="mb-2 text-center text-sm">
              <div className="m-auto mb-2 flex justify-center text-center">
                <QRCode
                  size={120}
                  errorLevel="H"
                  value={URL_TELEGRAM_SUPPORT}
                />
              </div>
              <p>
                Telegram:{' '}
                <a target="_blank" href={URL_TELEGRAM_SUPPORT}>
                  Nhóm hỗ trợ
                </a>
              </p>
            </div>
          </div>
          <div className="flex-none p-2 text-center">
            <p className="text-sm">Phần mềm kế toán</p>
            <p className="text-sm">Version 2.0</p>
          </div>
        </div>
      </div>
    </>
  );
};

const LeftLayout = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div className="fixed top-0 hidden w-[200px] lg:block">
        <div className={classNames('z-30 w-[200px] flex-none  px-2 py-4')}>
          <MainNavigation onNavigate={() => {}} />
        </div>
      </div>
      <Drawer
        bodyStyle={{
          padding: '0 10px',
        }}
        headerStyle={{ display: 'none' }}
        placement="left"
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <MainNavigation
          onNavigate={() => {
            setIsOpen(false);
          }}
        />
      </Drawer>
      <div className="fixed top-0 z-20 h-[50px] w-full bg-white shadow-md lg:hidden">
        <div className="absolute left-2 top-1/2 -translate-y-1/2">
          <Button
            type="primary"
            onClick={() => setIsOpen(true)}
            icon={<MenuOutlined />}
          ></Button>
        </div>
        <div className="flex h-full w-full items-center justify-center text-center">
          <Link to={'/'}>
            <Image
              width={130}
              src="logo.png"
              preview={false}
              className="m-auto"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default LeftLayout;
