import { useEffect } from 'react';

import { Space, Button, Input, Form, Drawer } from 'antd';

import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../../hooks/useSuccessHandler';
import { drawerStyle } from '../../../../repositories/core/constant';
import { ICustomer } from '../../../../repositories/customer/customer.interface';
import { match } from '../../../../ultis/custom-matcher';
import CustomRandom from '../../../../ultis/random';
import useCustomerCreateState from '../states/useCustomerCreateState';
import useCustomerUpdateState from '../states/useCustomerUpdateState';

const DrawerCreateUpdateCustomerComponent = ({
  selected,
  isClose,
  onClose,
  onReload,
}: {
  selected: ICustomer | null;
  isClose: boolean;
  onClose: () => void;
  onReload: (value: ICustomer) => void;
}) => {
  const [form] = Form.useForm();

  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useCustomerCreateState();
  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useCustomerUpdateState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const onSubmitCreateUpdate = (values: ICustomer) => {
    if (selected && selected.id) {
      eventUpdate.updateItem({
        id: selected.id,
        username: values.username,
        keyMatch: values.keyMatch,
      });
    } else {
      eventCreate.createItem({
        username: values.username,
        keyMatch: values.keyMatch,
      });
    }
  };

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess(res) {
        showSuccessDialog('Tạo khách hàng thành công!');
        form.resetFields();
        onReload(res.customer);
        onClose();
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess(res) {
        showSuccessDialog('Cập nhật khách hàng thành công!');
        form.resetFields();
        onReload(res.customer);
        onClose();
      },
    });
  }, [stateUpdate.type]);

  useEffect(() => {
    form.setFieldsValue(selected);
  }, [selected]);

  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmitCreateUpdate} layout="vertical">
        <div className="grid grid-cols-1 gap-2">
          <Form.Item
            name="username"
            label="Tài khoản"
            rules={[{ required: true, message: 'Nhập tên tài khoản' }]}
          >
            <Input autoComplete="nope" placeholder="Nhập tên tài khoản" />
          </Form.Item>
          <div>
            <p className="mb-1">Mã đối ứng:</p>
            <div className="flex w-full items-center justify-center gap-2">
              <Form.Item name="keyMatch" className="w-full">
                <Input placeholder="Nhập mã đối ứng" />
              </Form.Item>
              <Button
                className="mb-2 w-[80px]"
                onClick={() =>
                  form.setFieldValue('keyMatch', CustomRandom.makeId())
                }
              >
                Tạo mã
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
export default DrawerCreateUpdateCustomerComponent;
