import { useEffect, useState } from 'react';

import {
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  FormInstance,
  InputNumber,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Switch,
  Table,
} from 'antd';
import classNames from 'classnames';

import StatusAccountScan from './account-status-scan';
import TableAccountScan from './table-account-scan';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import {
  IAccount,
  ITriggerSubmit,
} from '../../../repositories/account/account.interface';
import { IAccountScan } from '../../../repositories/account-scan/account-scan.interface';
import { ICategoryMethod } from '../../../repositories/category-method/category-method.interface';
import {
  ConnectionCode,
  maxPageSize,
  maxScanRequest,
} from '../../../repositories/core/constant';
import {
  ICustomer,
  ICustomerMapBiddingMethod,
  ICustomerMapMethod,
} from '../../../repositories/customer/customer.interface';
import { IGroupMethod } from '../../../repositories/group-method/group-method.interface';
import { IMethod } from '../../../repositories/method/method.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';
import DrawerCreateUpdateCustomerComponent from '../../customer/list/components/DrawerCreateUpdateCustomerComponent';
import DrawerCreateUpdateMethodComponent from '../../method/components/create-update-method/DrawerCreateUpdateMethodComponent';
import useAccountScanMapBiddingMethodCreateState from '../states/method-manage/bidding/useAccountScanMapBiddingMethodCreateState';
import useAccountScanMapBiddingMethodDeleteState from '../states/method-manage/bidding/useAccountScanMapBiddingMethodDeleteState';
import useAccountScanMapBiddingMethodListState from '../states/method-manage/bidding/useAccountScanMapBiddingMethodListState';
import useGroupMethodDeliverReceiveListScanState from '../states/method-manage/bidding/useGroupMethodDeliverReceiveListScanState';
import useAccountScanMapMethodCreateState from '../states/method-manage/method/useAccountScanMapMethodCreateState';
import useAccountScanMapMethodDeleteState from '../states/method-manage/method/useAccountScanMapMethodDeleteState';
import useAccountScanMapMethodListState from '../states/method-manage/method/useAccountScanMapMethodListState';
import useBiddingCategoryMethodState from '../states/method-manage/useBiddingCategoryMethodState';
import useCustomerListScanState from '../states/method-manage/useCustomerListScanState';
import useGroupMethodListScanState from '../states/method-manage/useGroupMethodListScanState';
import useMethodListScanState from '../states/method-manage/useMethodListScanState';
import useAccountScanListScanState from '../states/useAccountScanListScanState';
import useScanState from '../states/useScanState';

type IMethodTab = 'Công thức' | 'Chia thầu';

const AddBiddingMethodMapAccountScan = ({
  form,
  isOpen,
  isLoading,
  isLoadingCustomer,
  listCustomer,
  isLoadingMethod,
  listMethod,
  isLoadingCategory,
  listCategory,
  listGroupDeliver,
  listGroupReceive,
  isLoadingDeliverReceive,
  onSubmit,
  onClose,
  onAddNewMethod,
  onAddNewCustomer,
}: {
  form: FormInstance<any>;
  isOpen: boolean;
  isLoading: boolean;
  isLoadingCustomer: boolean;
  listCustomer: ICustomer[];
  isLoadingMethod: boolean;
  listMethod: IMethod[];
  isLoadingCategory: boolean;
  listCategory: ICategoryMethod[];
  isLoadingDeliverReceive: boolean;
  listGroupDeliver: IGroupMethod[];
  listGroupReceive: IGroupMethod[];
  onSubmit: (values: any) => void;
  onClose: () => void;
  onAddNewMethod: (type?: 'deliver' | 'receive') => void;
  onAddNewCustomer: () => void;
}) => {
  const categoryId: number = Form.useWatch('categoryId', form);

  const [isRefund, setIsRefund] = useState<boolean>(false);
  const [isGroupMethod, setIsGroupMethod] = useState<boolean>(false);

  return (
    <Drawer
      title="Gắn chia thầu"
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{
          IsGroupMethod: false,
        }}
      >
        <div className="grid grid-cols-1 gap-2">
          <div>
            <Form.Item name="IsGroupMethod" label="Loại">
              <Select
                defaultValue={false}
                onChange={(e) => setIsGroupMethod(e)}
                options={[
                  { value: false, label: 'Công thức' },
                  { value: true, label: 'Nhóm công thức' },
                ]}
              />
            </Form.Item>
          </div>

          {match([isGroupMethod.toString()])({
            false: () => (
              <>
                <div>
                  <Form.Item
                    name="categoryId"
                    label="Loại công thức"
                    rules={[{ required: true, message: 'Chọn Loại công thức' }]}
                  >
                    <Select
                      disabled={isLoadingCategory}
                      showSearch
                      placeholder="Chọn loại công thức"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listCategory
                        .filter((x) => x.parentId === null)
                        .map((category) => {
                          return {
                            label: category.name,
                            options: listCategory
                              .filter((x) => x.parentId === category.id)
                              .map((child) => {
                                return {
                                  label: child.name,
                                  value: child.id,
                                };
                              }),
                          };
                        })}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Công thức nhận">
                    <Space.Compact block={true}>
                      <Form.Item
                        name="methodReceiveId"
                        rules={[
                          { required: true, message: 'Chọn công thức nhận' },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Select
                          disabled={!categoryId}
                          loading={isLoadingMethod}
                          showSearch
                          placeholder="Chọn công thức nhận"
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={listMethod
                            .filter(
                              (x) =>
                                !x.isDeliver && x.categoryId === categoryId,
                            )
                            .map((method) => {
                              return { label: method.name, value: method.id };
                            })}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          disabled={!categoryId}
                          type="primary"
                          onClick={() => onAddNewMethod('receive')}
                          icon={<PlusOutlined />}
                        ></Button>
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item label="Công thức giao">
                    <Space.Compact block={true}>
                      <Form.Item
                        name="methodDeliverId"
                        rules={[
                          { required: true, message: 'Chọn công thức giao' },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Select
                          disabled={!categoryId}
                          loading={isLoadingMethod}
                          showSearch
                          placeholder="Chọn công thức giao"
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={listMethod
                            .filter(
                              (x) => x.isDeliver && x.categoryId === categoryId,
                            )
                            .map((method) => {
                              return { label: method.name, value: method.id };
                            })}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          disabled={!categoryId}
                          type="primary"
                          onClick={() => onAddNewMethod('deliver')}
                          icon={<PlusOutlined />}
                        ></Button>
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                </div>
              </>
            ),
            true: () => (
              <>
                <div>
                  <Form.Item
                    name="groupMethodReceiveId"
                    label="Nhóm công thức nhận"
                    rules={[
                      { required: true, message: 'Chọn nhóm công thức nhận' },
                    ]}
                  >
                    <Select
                      disabled={isLoadingDeliverReceive}
                      showSearch
                      placeholder="Chọn nhóm công thức nhận"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listGroupReceive.map((method) => {
                        return { label: method.name, value: method.id };
                      })}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="groupMethodDeliverId"
                    label="Nhóm công thức giao"
                    rules={[
                      { required: true, message: 'Chọn nhóm công thức giao' },
                    ]}
                  >
                    <Select
                      disabled={isLoadingDeliverReceive}
                      showSearch
                      placeholder="Chọn nhóm công thức giao"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listGroupDeliver.map((method) => {
                        return { label: method.name, value: method.id };
                      })}
                    />
                  </Form.Item>
                </div>
              </>
            ),
          })}

          <div className="rounded-md border border-dashed border-primary p-2">
            <div>
              <Form.Item initialValue={false}>
                <div className="flex items-center">
                  <Switch onChange={(e) => setIsRefund(e)} />
                  <span className="pl-4">Hoàn trả</span>
                </div>
              </Form.Item>
            </div>
            {isRefund && (
              <div className="flex gap-2">
                <Form.Item name="percentDeliver" initialValue={0}>
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="%"
                    addonBefore="Giao"
                    defaultValue={0}
                  />
                </Form.Item>
                <Form.Item name="percentReceive" initialValue={0}>
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="%"
                    addonBefore="Nhận"
                    defaultValue={0}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className="rounded-md border border-dashed border-primary p-2">
            <Form.List
              name="memberBidDivisions"
              rules={[
                {
                  validator: async (_, memberBidDivisions) => {
                    if (!memberBidDivisions || memberBidDivisions.length < 1) {
                      return Promise.reject(new Error('Chưa thêm khách hàng'));
                    }
                    return null;
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <div className="py-2 text-base font-semibold">
                    Danh sách khách hàng
                  </div>
                  <div className="flex flex-col gap-4">
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <div>
                          <Form.Item
                            {...restField}
                            name={[name, 'memberId']}
                            label="Khách hàng"
                            rules={[
                              { required: true, message: 'Chọn khách hàng' },
                            ]}
                          >
                            <Select
                              disabled={isLoadingCustomer}
                              showSearch
                              placeholder="Chọn khách hàng"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={listCustomer.map((customer) => {
                                return {
                                  label: customer.username,
                                  value: customer.id,
                                };
                              })}
                            />
                          </Form.Item>
                        </div>
                        <div className="mt-4 flex gap-2">
                          <div className="grow">
                            <Form.Item
                              name={[name, 'percentBidDivision']}
                              initialValue={1}
                              rules={[
                                {
                                  required: true,
                                  message: 'Chọn khách hàng',
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: '100%' }}
                                addonAfter="%"
                                addonBefore="Chia thầu"
                                defaultValue={1}
                                step={0.01}
                              />
                            </Form.Item>
                          </div>
                          <div className="grow">
                            <Form.Item
                              name={[name, 'isDeliver']}
                              initialValue={false}
                            >
                              <Switch
                                checkedChildren="Chia thầu"
                                unCheckedChildren="Nhận thầu"
                              />
                            </Form.Item>
                          </div>
                          <div className="flex-none">
                            <MinusCircleOutlined
                              className="mt-2 text-state-negative"
                              onClick={() => remove(name)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm khách hàng
                    </Button>
                  </Form.Item>
                  <Form.ErrorList
                    className="text-state-negative"
                    errors={errors}
                  />
                </>
              )}
            </Form.List>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const AccountScanMapBiddingMethodList = ({
  accountScan,
}: {
  accountScan: IAccountScan;
}) => {
  const {
    event: eventMethodMap,
    state: stateMethodMap,
    list,
    isLoading: isLoadingMethodMap,
  } = useAccountScanMapBiddingMethodListState();

  const { list: companys } = useCompanyListState();

  const {
    event: eventCustomer,
    isLoading: isLoadingCustomer,
    list: listCustomer,
  } = useCustomerListScanState();

  const {
    event: eventMethod,
    isLoading: isLoadingMethod,
    list: listMethod,
  } = useMethodListScanState();

  const {
    event: eventCreateMapBiddingMethod,
    isLoading: isLoadingCreateMapBiddingMethod,
    state: stateCreateMapBiddingMethod,
  } = useAccountScanMapBiddingMethodCreateState();

  const {
    event: eventDeleteMapBiddingMethod,
    isLoading: isLoadingDeleteMapBiddingMethod,
    state: stateDeleteMapBiddingMethod,
  } = useAccountScanMapBiddingMethodDeleteState();

  const {
    event: eventCategory,
    isLoading: isLoadingCategory,
    list: listCategory,
  } = useBiddingCategoryMethodState();

  const {
    event: eventDeliverReceive,
    isLoading: isLoadingDeliverReceive,
    listDeliver: listGroupDeliver,
    listReceive: listGroupReceive,
  } = useGroupMethodDeliverReceiveListScanState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenCreateMethod, setIsOpenCreateMethod] = useState<boolean>(false);
  const [isOpenTypeMethod, setIsOpenTypeMethod] = useState<
    'deliver' | 'receive'
  >();
  const [isOpenCreateCustomer, setIsOpenCreateCustomer] =
    useState<boolean>(false);
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmit = (values: any) => {
    eventCreateMapBiddingMethod.createItem({
      accountScanId: accountScan.id,
      categoryId: values.categoryId,
      methodDeliverId: values.methodDeliverId,
      methodReceiveId: values.methodReceiveId,
      IsGroupMethod: values.IsGroupMethod,
      memberBidDivisions: values.memberBidDivisions,
      percentReceive: values.percentReceive,
      percentDeliver: values.percentDeliver,
      groupMethodDeliverId: values.groupMethodDeliverId,
      groupMethodReceiveId: values.groupMethodReceiveId,
    });
  };

  const onLoadMethodMap = () => {
    eventMethodMap.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      accountScanId: accountScan.id,
    });
  };

  const onReloadCreateNewMethod = (method: IMethod) => {
    eventMethod.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      companyId: accountScan.companyId,
      onFinished() {
        if (method.isDeliver) {
          form.setFieldsValue({
            methodDeliverId: method.id,
          });
        } else {
          form.setFieldsValue({
            methodReceiveId: method.id,
          });
        }
      },
    });
  };

  const onReloadCreateNewCustomer = (customer: ICustomer) => {
    eventCustomer.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      onFinished() {
        form.setFieldsValue({
          memberId: customer.id,
        });
      },
    });
  };

  useEffect(() => {
    onLoadMethodMap();
    eventCustomer.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
    eventMethod.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      companyId: accountScan.companyId,
    });

    eventCategory.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
    eventDeliverReceive.getList({
      companyId: accountScan.companyId,
    });
  }, []);

  useEffect(() => {
    match([stateMethodMap, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
      setList() {},
    });
  }, [stateMethodMap.type]);

  useEffect(() => {
    match([stateCreateMapBiddingMethod, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Gắn chia thầu thành công!');
        form.resetFields();
        onClose();
        onLoadMethodMap();
      },
    });
  }, [stateCreateMapBiddingMethod.type]);

  useEffect(() => {
    match([stateDeleteMapBiddingMethod, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xoá chia thầu thành công!');
        onLoadMethodMap();
      },
    });
  }, [stateDeleteMapBiddingMethod.type]);

  const columns = [
    {
      title: 'Khách hàng',
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: 'Công thức',

      render: (record: ICustomerMapBiddingMethod) => (
        <div className="flex gap-2">
          {record.methodReceiveName}#{record.methodDeliverName}
        </div>
      ),
    },
    {
      title: 'Loại thầu',
      render: (record: ICustomerMapBiddingMethod) => (
        <p>{record.isDeliver ? 'Giao' : 'Nhận'}</p>
      ),
    },
    {
      title: 'Chia thầu (%)',
      render: (record: ICustomerMapBiddingMethod) => (
        <p>
          {record.percentBidDivision ? `${record.percentBidDivision}%` : ''}
        </p>
      ),
    },
    {
      title: 'Hoàn trả giao (%)',
      width: 100,
      render: (record: ICustomerMapBiddingMethod) => (
        <p>{record.percentDeliver ? `${record.percentDeliver}%` : ''}</p>
      ),
    },
    {
      title: 'Hoàn trả nhận (%)',
      width: 100,
      render: (record: ICustomerMapBiddingMethod) => (
        <p>{record.percentReceive ? `${record.percentReceive}%` : ''}</p>
      ),
    },

    {
      title: 'Ngày tạo',
      render: (record: ICustomerMapBiddingMethod) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: ICustomerMapMethod) => (
        <div className="flex gap-2">
          <Popconfirm
            title="Xóa công thức này"
            description="Bạn có chắc chắn xóa công thức này?"
            onConfirm={() =>
              eventDeleteMapBiddingMethod.deleteItem({
                id: record.id,
              })
            }
            okText="Có"
            cancelText="Không"
          >
            <Button
              danger
              size="small"
              loading={isLoadingDeleteMapBiddingMethod}
            >
              Xóa
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-4 flex items-center">
        <div className="grow">
          <h4 className="text-md font-semibold">Quản lý Chia thầu</h4>
        </div>
        <div>
          <Button onClick={() => setIsOpen(true)} type="primary">
            Thêm chia thầu
          </Button>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <div className="w-full min-w-[1000px]">
          <Table
            rowKey="id"
            columns={columns}
            loading={isLoadingMethodMap}
            dataSource={list}
            className="w-full"
            pagination={{
              defaultPageSize: 20,
              position: ['topLeft', 'bottomLeft'],
              showSizeChanger: true,
              showTotal(total, range) {
                return `${range[0]}-${range[1]} của ${total}`;
              },
            }}
            size="middle"
            indentSize={20}
          />
        </div>
      </div>

      <AddBiddingMethodMapAccountScan
        form={form}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoadingCreateMapBiddingMethod}
        isLoadingCustomer={isLoadingCustomer}
        listCustomer={listCustomer}
        isLoadingMethod={isLoadingMethod}
        listMethod={listMethod}
        isLoadingCategory={isLoadingCategory}
        listCategory={listCategory}
        isLoadingDeliverReceive={isLoadingDeliverReceive}
        listGroupDeliver={listGroupDeliver}
        listGroupReceive={listGroupReceive}
        onAddNewMethod={(type) => {
          setIsOpenTypeMethod(type);
          setIsOpenCreateMethod(true);
        }}
        onAddNewCustomer={() => {
          setIsOpenCreateCustomer(true);
        }}
      />
      <DrawerCreateUpdateMethodComponent
        selected={
          {
            categoryId: form.getFieldValue('categoryId'),
            companyId:
              companys.find((x) => x.id === accountScan.companyId)?.id ?? null,
            isDeliver: isOpenTypeMethod === 'deliver',
          } as IMethod
        }
        isClose={isOpenCreateMethod}
        onClose={() => setIsOpenCreateMethod(false)}
        onReload={onReloadCreateNewMethod}
      />
      <DrawerCreateUpdateCustomerComponent
        selected={null}
        isClose={isOpenCreateCustomer}
        onClose={() => setIsOpenCreateCustomer(false)}
        onReload={onReloadCreateNewCustomer}
      />
    </div>
  );
};

const AddMethodMapAccountScan = ({
  form,
  isOpen,
  isLoading,
  isLoadingCustomer,
  listCustomer,
  isLoadingMethod,
  listMethod,
  isLoadingGroupMethod,
  listGroupMethod,
  onSubmit,
  onClose,
  onAddNewMethod,
  onAddNewCustomer,
}: {
  form: FormInstance<any>;
  isOpen: boolean;
  isLoading: boolean;
  isLoadingCustomer: boolean;
  listCustomer: ICustomer[];
  isLoadingMethod: boolean;
  listMethod: IMethod[];
  isLoadingGroupMethod: boolean;
  listGroupMethod: IGroupMethod[];
  onSubmit: (values: any) => void;
  onClose: () => void;
  onAddNewMethod: () => void;
  onAddNewCustomer: () => void;
}) => {
  const [isHideRefund, setIsHideRefund] = useState<boolean>(true);
  const [type, setType] = useState<'single' | 'group'>('single');

  return (
    <Drawer
      title="Gắn công thức"
      width={'100%'}
      onClose={onClose}
      open={isOpen}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{
          type: 'single',
        }}
      >
        <div className="grid w-full grid-cols-1 gap-2">
          <div>
            <Form.Item name="type" label="Loại">
              <Select
                defaultValue="single"
                onChange={(e) => setType(e as 'single' | 'group')}
                options={[
                  { value: 'single', label: 'Công thức' },
                  { value: 'group', label: 'Nhóm công thức' },
                ]}
              />
            </Form.Item>
          </div>
          {match([type])({
            single: () => (
              <Form.Item label="Công thức">
                <Space.Compact block={true}>
                  <Form.Item
                    name="methodId"
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: 'Chọn công thức' }]}
                  >
                    <Select
                      disabled={isLoadingMethod}
                      showSearch
                      placeholder="Chọn công thức"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={listMethod.map((method) => {
                        return { label: method.name, value: method.id };
                      })}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={onAddNewMethod}
                      icon={<PlusOutlined />}
                    ></Button>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            ),
            group: () => (
              <div>
                <Form.Item
                  name="methodGroupId"
                  label="Nhóm công thức"
                  rules={[{ required: true, message: 'Chọn nhóm công thức' }]}
                >
                  <Select
                    disabled={isLoadingGroupMethod}
                    showSearch
                    placeholder="Chọn nhóm công thức"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listGroupMethod.map((method) => {
                      return { label: method.name, value: method.id };
                    })}
                  />
                </Form.Item>
              </div>
            ),
          })}

          <Form.Item label="Khách hàng">
            <Space.Compact block={true}>
              <Form.Item
                name="memberId"
                style={{ width: '100%' }}
                rules={[{ required: true, message: 'Chọn khách hàng' }]}
              >
                <Select
                  disabled={isLoadingCustomer}
                  showSearch
                  placeholder="Chọn khách hàng"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listCustomer.map((customer) => {
                    return { label: customer.username, value: customer.id };
                  })}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={onAddNewCustomer}
                  icon={<PlusOutlined />}
                ></Button>
              </Form.Item>
            </Space.Compact>
          </Form.Item>

          <div>
            <Form.Item>
              <div className="flex items-center">
                <Switch
                  onChange={() => {
                    setIsHideRefund(!isHideRefund);
                  }}
                />
                <span className="pl-4">Hoàn trả</span>
              </div>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="percentRefund">
              <InputNumber
                style={{ width: '100%' }}
                disabled={isHideRefund}
                addonAfter="%"
                defaultValue={0}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const AccountScanMapMethodList = ({
  accountScan,
}: {
  accountScan: IAccountScan;
}) => {
  const {
    event: eventMethodMap,
    state: stateMethodMap,
    list,
    isLoading: isLoadingMethodMap,
  } = useAccountScanMapMethodListState();
  const { list: companys } = useCompanyListState();
  const {
    event: eventCustomer,
    isLoading: isLoadingCustomer,
    list: listCustomer,
  } = useCustomerListScanState();

  const {
    event: eventMethod,
    list: listMethod,
    isLoading: isLoadingMethod,
  } = useMethodListScanState();

  const {
    event: eventGroupMethod,
    isLoading: isLoadingGroupMethod,
    list: listGroupMethod,
  } = useGroupMethodListScanState();

  const {
    event: eventCreateMapMethod,
    isLoading: isLoadingCreateMapMethod,
    state: stateCreateMapMethod,
  } = useAccountScanMapMethodCreateState();

  const {
    event: eventDeleteMapMethod,
    isLoading: isLoadingDeleteMapMethod,
    state: stateDeleteMapMethod,
  } = useAccountScanMapMethodDeleteState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenCreateMethod, setIsOpenCreateMethod] = useState<boolean>(false);
  const [isOpenCreateCustomer, setIsOpenCreateCustomer] =
    useState<boolean>(false);
  const onClose = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onSubmit = (values: any) => {
    eventCreateMapMethod.createItem({
      accountScanId: accountScan.id,
      methodId: values.methodId,
      methodGroupId: values.methodGroupId,
      memberId: values.memberId,
      percentRefund: values.percentRefund,
    });
  };

  const onLoadMethodMap = () => {
    eventMethodMap.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      accountScanId: accountScan.id,
    });
  };

  const onReloadCreateNewMethod = (method: IMethod) => {
    eventMethod.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      companyId: accountScan.companyId,
      onFinished() {
        form.setFieldsValue({
          methodId: method.id,
        });
      },
    });
  };

  const onReloadCreateNewCustomer = (customer: ICustomer) => {
    eventCustomer.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      onFinished() {
        form.setFieldsValue({
          memberId: customer.id,
        });
      },
    });
  };

  useEffect(() => {
    onLoadMethodMap();
    eventCustomer.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
    eventMethod.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
      companyId: accountScan.companyId,
    });
    eventGroupMethod.getList({
      pageIndex: 1,
      pageSize: maxPageSize,
    });
  }, []);

  useEffect(() => {
    match([stateCreateMapMethod, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Gắn công thức thành công!');
        form.resetFields();
        onClose();
        onLoadMethodMap();
      },
    });
  }, [stateCreateMapMethod.type]);

  useEffect(() => {
    match([stateDeleteMapMethod, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xoá công thức thành công!');
        onLoadMethodMap();
      },
    });
  }, [stateDeleteMapMethod.type]);

  useEffect(() => {
    match([stateMethodMap, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
      setList() {},
    });
  }, [stateMethodMap.type]);

  const columns = [
    {
      title: 'Khách hàng',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Công thức',
      render: (record: ICustomerMapMethod) => {
        return (
          <>
            {!record.groupMethodId && <p>{record.methodName}</p>}
            {record.groupMethodId && (
              <div className="flex flex-col">
                {record.groupMethods.map((x) => (
                  <p>{x.methodName}</p>
                ))}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Loại',
      render: (record: ICustomerMapMethod) => {
        return (
          <>
            {!record.groupMethodId && <p>{record.categoryName}</p>}
            {record.groupMethodId && (
              <div className="flex flex-col">
                {record.groupMethods.map((x) => (
                  <p>{x.categoryName}</p>
                ))}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Hoàn trả (%)',
      dataIndex: 'percentRefund',
      key: 'percentRefund',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (record: ICustomerMapMethod) => (
        <div className="flex gap-2">
          <Popconfirm
            title="Xóa công thức này"
            description="Bạn có chắc chắn xóa công thức này?"
            onConfirm={() =>
              eventDeleteMapMethod.deleteItem({
                id: record.accountScanMapMemberId,
              })
            }
            okText="Có"
            cancelText="Không"
          >
            <Button danger size="small">
              Xóa
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-4 flex items-center">
        <div className="grow">
          <h4 className="text-md font-semibold">Quản lý Công thức</h4>
        </div>
        <div>
          <Button onClick={() => setIsOpen(true)} type="primary">
            Thêm công thức
          </Button>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <div className="w-full min-w-[1000px]">
          <Table
            rowKey="id"
            columns={columns}
            loading={isLoadingMethodMap || isLoadingDeleteMapMethod}
            dataSource={list}
            className="w-full"
            pagination={{
              defaultPageSize: 20,
              position: ['topLeft', 'bottomLeft'],
              showSizeChanger: true,
              showTotal(total, range) {
                return `${range[0]}-${range[1]} của ${total}`;
              },
            }}
            size="middle"
            indentSize={20}
          />
        </div>
      </div>
      <AddMethodMapAccountScan
        form={form}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoadingCreateMapMethod}
        isLoadingCustomer={isLoadingCustomer}
        listCustomer={listCustomer}
        isLoadingMethod={isLoadingMethod}
        listMethod={listMethod}
        isLoadingGroupMethod={isLoadingGroupMethod}
        listGroupMethod={listGroupMethod}
        onAddNewMethod={() => {
          setIsOpenCreateMethod(true);
        }}
        onAddNewCustomer={() => {
          setIsOpenCreateCustomer(true);
        }}
      />
      <DrawerCreateUpdateMethodComponent
        selected={
          {
            companyId:
              companys.find((x) => x.id === accountScan.companyId)?.id ?? null,
            isDeliver: true,
          } as IMethod
        }
        isClose={isOpenCreateMethod}
        onClose={() => setIsOpenCreateMethod(false)}
        onReload={onReloadCreateNewMethod}
      />
      <DrawerCreateUpdateCustomerComponent
        selected={null}
        isClose={isOpenCreateCustomer}
        onClose={() => setIsOpenCreateCustomer(false)}
        onReload={onReloadCreateNewCustomer}
      />
    </div>
  );
};

const DrawerAccountScanMapMethodManage = ({
  isOpenDrawer,
  accountScan,
  onClose,
}: {
  isOpenDrawer: boolean;
  accountScan: IAccountScan | null;
  onClose: () => void;
}) => {
  const methodTabbar = ['Công thức', 'Chia thầu'] as IMethodTab[];
  const [tabActive, setTabActive] = useState<IMethodTab>(
    methodTabbar[0] ?? 'Công thức',
  );
  return (
    <Drawer
      title="Quản lý Công thức & Chia thầu"
      placement={'right'}
      open={isOpenDrawer}
      width={'100%'}
      onClose={() => onClose()}
      destroyOnClose={true}
    >
      <div className="mb-4">
        <Segmented
          block
          options={methodTabbar}
          value={tabActive}
          onChange={(value) => setTabActive(value as IMethodTab)}
        />
      </div>
      {accountScan &&
        match([tabActive as IMethodTab])({
          'Công thức': () => {
            return (
              <div>
                <AccountScanMapMethodList accountScan={accountScan} />
              </div>
            );
          },
          'Chia thầu': () => {
            return (
              <div>
                <AccountScanMapBiddingMethodList accountScan={accountScan} />
              </div>
            );
          },
        })}
    </Drawer>
  );
};

const AccountScanComponent = ({
  account,
  form,
  checked,
  triggerSubmit,
  accountProcessingList,
  onToggleCheckAccount,
  onSelectedDetail,
  onBackStatusAccount,
}: {
  account: IAccount;
  form: FormInstance<any>;
  checked: boolean;
  triggerSubmit: ITriggerSubmit;
  accountProcessingList: IAccount[];
  onToggleCheckAccount: (value: boolean, account: IAccount) => void;
  onSelectedDetail: (account: IAccount) => void;
  onBackStatusAccount: (accountId: number, connectionCode: string) => void;
}) => {
  const {
    event: eventScanAccount,
    state: stateScanAccount,
    isLoading: isLoadingScan,
  } = useScanState();
  const {
    event: eventAccountScan,
    loadingList,
    list: accountScans,
  } = useAccountScanListScanState();

  const [selectedAccountScan, setSelectedAccountScan] =
    useState<IAccountScan | null>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const [intervalScan, setIntervalScan] = useState<any>();

  const onOpenMethodManage = (accountScan: IAccountScan) => {
    setSelectedAccountScan(accountScan);
    setIsOpenDrawer(true);
  };

  const onCheckScan = () => {
    const temp = accountProcessingList.filter(
      (x) => x.connectionCode === ConnectionCode.PROCESSING,
    );

    if (temp.length < maxScanRequest) {
      eventScanAccount.scanItem({
        isQuick: form.getFieldValue('isQuick'),
        fromDate: form.getFieldValue('fromDate'),
        toDate: form.getFieldValue('toDate'),
        account,
      });
    }
  };

  const onScan = () => {
    const temp = setInterval(() => {
      onCheckScan();
    }, 1000);
    setIntervalScan(temp);
  };

  const onFetchAccountScan = (accountScanId?: number) => {
    if (accountScanId) {
      eventAccountScan.getAccountScanListById({
        accountId: account.id,
        pageIndex: 1,
        pageSize: maxPageSize,
        isQuick: form.getFieldValue('isQuick'),
        fromDate: form.getFieldValue('fromDate'),
        toDate: form.getFieldValue('toDate'),
        accountScanId,
      });
    } else {
      eventAccountScan.getAccountScanListByMethod({
        accountId: account.id,
        pageIndex: 1,
        pageSize: maxPageSize,
        isQuick: form.getFieldValue('isQuick'),
        fromDate: form.getFieldValue('fromDate'),
        toDate: form.getFieldValue('toDate'),
      });
    }
  };

  const onFetchByParentId = (accountScan: IAccountScan) => {
    onFetchAccountScan(accountScan.id);
  };

  const onRemoveByParentId = (accountScan: IAccountScan) => {
    eventAccountScan.removeAccountListByParentId({
      accountScanId: accountScan.id,
    });
  };

  const onClearData = () => {
    eventAccountScan.clearData();
  };

  const onCloseMethodManage = () => {
    setIsOpenDrawer(false);
    if (selectedAccountScan) {
      setTimeout(() => {
        setSelectedAccountScan(null);
      }, 1000);
      onFetchAccountScan(selectedAccountScan.parentId);
    }
  };

  useEffect(() => {
    if (
      triggerSubmit.count > 0 &&
      triggerSubmit.accounts.find((x) => x.id === account.id)
    ) {
      onScan();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (isLoadingScan) {
      clearInterval(intervalScan);
      onClearData();
      onBackStatusAccount(account.id, ConnectionCode.PROCESSING);
    }
  }, [isLoadingScan]);

  useEffect(() => {
    return () => clearInterval(intervalScan);
  }, []);

  useEffect(() => {
    match([stateScanAccount, 'type'])({
      start() {},
      init() {},
      scanFailed(_) {
        onClearData();
        onBackStatusAccount(account.id, ConnectionCode.FAILED);
      },
      scanSuccess(res) {
        if (res.result.connectionCode === ConnectionCode.SUCCESS) {
          onFetchAccountScan();
        } else {
          onClearData();
        }
        onBackStatusAccount(account.id, res.result.connectionCode);
      },
    });
  }, [stateScanAccount.type]);

  return (
    <>
      <div className="my-2 flex items-center">
        <Checkbox
          checked={checked}
          onChange={(e) => onToggleCheckAccount(e.target.checked, account)}
        ></Checkbox>
        <h4
          className={classNames(
            'pl-2 font-semibold uppercase',
            checked ? '' : 'opacity-30',
          )}
        >
          <StatusAccountScan key={account.id} account={account} />
        </h4>
        <Button
          onClick={() => onSelectedDetail(account)}
          className="ml-4"
          size="small"
          icon={<SearchOutlined />}
        />
      </div>

      <div className="w-full overflow-auto">
        <div className="w-full min-w-[1100px]">
          {account.connectionCode === ConnectionCode.SUCCESS && (
            <TableAccountScan
              account={account}
              accountScans={accountScans}
              companyId={account.companyId}
              onFetchByParentId={onFetchByParentId}
              onRemoveByParentId={onRemoveByParentId}
              loadingList={loadingList}
              onOpenMethodManage={onOpenMethodManage}
            />
          )}
        </div>
      </div>

      <DrawerAccountScanMapMethodManage
        isOpenDrawer={isOpenDrawer}
        onClose={onCloseMethodManage}
        accountScan={selectedAccountScan}
      />
    </>
  );
};

export default AccountScanComponent;
