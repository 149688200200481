import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import { IMethod } from '../../repositories/method/method.interface';
import MethodRepository from '../../repositories/method/method.repository';

class MethodService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
    companyId,
    categoryId,
    name,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    companyId?: number;
    categoryId?: number;
    name?: string;
  }): Promise<Either<ErrorType, PageItems<IMethod>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      CompanyId: companyId ? companyId.toString() : '',
      CategoryId: categoryId ? categoryId.toString() : '',
      Name: name ?? '',
    });
    return MethodRepository.getList({ params });
  }

  static create({
    bid,
    categoryId,
    companyId,
    currencyId,
    isDeliver,
    name,
    number,
    configScanUserId,
  }: {
    bid: number;
    categoryId: number;
    companyId: number;
    currencyId: number;
    isDeliver: boolean;
    name: string;
    number: number;
    configScanUserId: number;
  }): Promise<Either<ErrorType, IMethod>> {
    const variables = {
      bid,
      categoryId,
      companyId,
      currencyId,
      isDeliver,
      name,
      number,
      configScanUserId,
    } as IMethod;
    return MethodRepository.create({ data: variables });
  }

  static update({
    id,
    bid,
    categoryId,
    companyId,
    currencyId,
    isDeliver,
    name,
    number,
    configScanUserId,
  }: {
    id: number;
    bid: number;
    categoryId: number;
    companyId: number;
    currencyId: number;
    isDeliver: boolean;
    name: string;
    number: number;
    configScanUserId: number;
  }): Promise<Either<ErrorType, IMethod>> {
    const variables = {
      id,
      bid,
      categoryId,
      companyId,
      currencyId,
      isDeliver,
      name,
      number,
      configScanUserId,
    } as IMethod;
    return MethodRepository.update({ data: variables });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return MethodRepository.delete({ id });
  }
}

export default MethodService;
