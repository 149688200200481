import { Either } from 'fp-ts/lib/Either';

import {
  IAccount,
  IResultScanAccount,
} from '../../repositories/account/account.interface';
import AccountRepository from '../../repositories/account/account.repository';
import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';

class AccountService {
  static getList({
    sortNew,
    pageSize,
    isDelete,
    pageIndex,
    username,
    prefixCode,
    companyId,
  }: {
    sortNew: boolean;
    pageSize: number;
    isDelete: boolean;
    pageIndex: number;
    username?: string;
    prefixCode?: string;
    companyId?: number;
  }): Promise<Either<ErrorType, PageItems<IAccount>>> {
    const params = new URLSearchParams({
      sortNew: sortNew.toString(),
      pageSize: pageSize.toString(),
      isDelete: isDelete.toString(),
      pageIndex: pageIndex.toString(),
      search: username ?? '',
      prefixCode: prefixCode ?? '',
      companyId: companyId ? companyId.toString() : '',
    });
    return AccountRepository.getList({ params });
  }

  static create({
    username,
    password,
    companyId,
    code,
    description,
    isScanMember,
  }: {
    username: string;
    password: string;
    companyId: number;
    code: string;
    description: string;
    isScanMember: boolean;
  }): Promise<Either<ErrorType, IAccount>> {
    const variables = {
      username,
      password,
      companyId,
      code,
      description,
      isScanMember,
    } as IAccount;
    return AccountRepository.create({ account: variables });
  }

  static update({
    id,
    password,
    companyId,
    code,
    description,
    isScanMember,
  }: {
    id: number;
    password: string;
    companyId: number;
    code: string;
    description: string;
    isScanMember: boolean;
  }): Promise<Either<ErrorType, IAccount>> {
    const variables = {
      id,
      password,
      companyId,
      code,
      description,
      isScanMember,
    } as IAccount;
    return AccountRepository.update({ account: variables });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return AccountRepository.delete({ id });
  }

  static scanAccount({
    isQuick,
    fromDate,
    toDate,
    account,
  }: {
    isQuick: boolean;
    fromDate: string;
    toDate: string;
    account: IAccount;
  }): Promise<Either<ErrorType, IResultScanAccount>> {
    const variables = {
      isQuick,
      fromDate,
      toDate,
      account,
    };
    return AccountRepository.scanAccount({ variables });
  }

  static GetAccountListByMemberId = async ({
    memberId,
  }: {
    memberId: number;
  }): Promise<Either<ErrorType, IAccount[]>> => {
    return AccountRepository.GetAccountListByMemberId({ memberId });
  };
}

export default AccountService;
