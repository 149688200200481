import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { IHealth } from '../../../repositories/auth/auth.interface';
import { ErrorType } from '../../../repositories/core/error-type';
import AuthService from '../../../services/auth/auth.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; res: IHealth };

  start: () => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (value: IHealth) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    start: () => set({ state: { type: 'start' } }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (res) => set({ state: { type: 'fetchSuccess', res } }),
  }));

const useMaintenanceState = () => {
  const [useStore] = useState(createStore);
  const { state, start, fetchSuccess, fetchFailed } = useStore();

  const getHealth = async () => {
    start();
    const result = await AuthService.getHealth();
    if (isRight(result)) {
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
  };

  return {
    state,
    event: {
      getHealth,
    },
  };
};

export default useMaintenanceState;
