import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

type ICustomType = 'primary' | 'secondary' | 'tertiary' | 'text' | 'outline';
type ICustomSize = 'large' | 'medium' | 'small';

type CustomButtonProps = {
  customType?: ICustomType;
  customSize?: ICustomSize;
} & ButtonProps;
const CustomButton = ({ className, style, ...rest }: CustomButtonProps) => {
  return (
    <Button
      className={classNames(className)}
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

export default CustomButton;
