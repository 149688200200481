type IEnvironmentName = 'DEV' | 'PROD';

type IEnv = {
  [k in IEnvironmentName]: {
    environment: IEnvironmentName;
    base: string;
    api: string;
  };
};

const env: IEnv = {
  DEV: {
    environment: 'DEV',
    base: 'http://hp79kt.com',
    api: 'http://api.hp79kt.com/api',
  },
  PROD: {
    environment: 'PROD',
    base: 'http://hp79kt.com',
    api: 'http://api.hp79kt.com/api',
  },
};

class AppEnv {
  static get = () =>
    env[process.env.REACT_APP_ENV as IEnvironmentName] ?? env.DEV;
}

export default AppEnv;
