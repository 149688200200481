export const maxPageSize = 99999;
export const APIURL = 'http://api.hp79kt.com/api';
export const APIURL_BOT = 'http://bot.hp79kt.com';
// export const APIURL_BOT = 'http://localhost:8002';
export const URL_TELEGRAM_SUPPORT = 'https://t.me/+6qOBOXZtYjgzYzA1';
export const defaultPageSize = 20;
export const maxScanRequest = 30;

export const drawerStyle = {
  maxWidth: 1200,
  margin: '0 auto',
  width: '100%',
};
export const Methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
export const StatusCodeWeb = {
  CLOSE: 'CLOSE',
  MAINTENANCE: 'MAINTENANCE',
  ACTIVE: 'ACTIVE',
};
export const CompanyId = {
  IBET: 1,
  SBO: 2,
  SVENUS: 5,
  LASVEGAS: 6,
  ONE789: 7,
  SGS889: 8,
  HK1107: 10,
  VN8899: 11,
  SGD777: 9,
  OK368: 12,
  S3IN1BET: 13,
  PS3838: 14,
  EXIBET: 16,
  EXSBO: 15,
  WON: 17,
};

export const RoleList = [
  {
    label: 'ADMINISTRATOR',
    value: 1,
  },
  {
    label: 'SUPER',
    value: 2,
  },
  {
    label: 'MASTER',
    value: 3,
  },
  {
    label: 'AGENT',
    value: 4,
  },
  {
    label: 'MEMBER',
    value: 5,
  },
];

export const SystemTimeZone = 'America/Aruba';
export enum StatusScan {
  processing = 'processing',
  stopped = 'stopped',
  success = 'success',
  failed = 'failed',
  waiting = 'waiting',
}

export const ConnectionCode = {
  WAITING: 'WAITING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  STOPPED: 'STOPPED',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  SUSPENDED: 'SUSPENDED',
  PROCESSING: 'PROCESSING',
  CAPTRA_INVALID: 'CAPTRA_INVALID',
  DENIED_IP: 'DENIED_IP',
  NOT_WINLOSS: 'NOT_WINLOSS',
  EMPTY_ROLE_CODE_PREFIX_CODE: 'EMPTY_ROLE_CODE_PREFIX_CODE',
  CODE_INVALID: 'CODE_INVALID',
  SYSTEM_MAINTENANCE: 'SYSTEM_MAINTENANCE',
};

export const Message = {
  WAITING: 'Chờ xử lý',
  FAILED: 'Quét lỗi',
  SUCCESS: 'Quét thành công',
  STOPPED: 'Dừng quét',
  CHANGE_PASSWORD: 'Cần thay đổi mật khẩu mới',
  WRONG_PASSWORD: 'Sai mật khẩu',
  SUSPENDED: 'Tài khoản bị khóa',
  PROCESSING: 'Đang xử lý',
  CAPTRA_INVALID: 'Mã Captra không đúng',
  DENIED_IP: 'IP bị chặn',
  NOT_WINLOSS: 'Tài khoản không có mục Win Loss Details',
  EMPTY_ROLE_CODE_PREFIX_CODE: 'Không lấy được thông tin sub',
  CODE_INVALID: 'Security Code sai',
  SYSTEM_MAINTENANCE: 'Hệ thống bảo trì',
};
export const RoleCode = {
  UNKNOW: 'UNKNOW',
  MASTER: 'MASTER',
  AGENT: 'AGENT',
  MEMBER: 'MEMBER',
  MATCH: 'MATCH',
  SUPER: 'SUPER',
  SSUPER: 'SSUPER',
  SHARE_HOLDER: 'SHARE_HOLDER',
};
