/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import PackageService from '../../../../services/package/package.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'registerFailed'; error: ErrorType }
    | { type: 'registerSuccess'; res: boolean };
  isLoading: boolean;

  start: () => void;
  setLoading: (value: boolean) => void;
  registerFailed: (error: ErrorType) => void;
  registerSuccess: (res: boolean) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,

    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    registerFailed: (error) =>
      set({ state: { type: 'registerFailed', error } }),
    registerSuccess: (res) =>
      set({
        state: { type: 'registerSuccess', res },
      }),
  }));

const useRegisterPackageUserIdState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,

    start,
    setLoading,
    registerFailed,
    registerSuccess,
  } = useStore();

  const register = async ({
    userId,
    packageId,
  }: {
    userId: number;
    packageId: number;
  }) => {
    start();
    setLoading(true);
    const result = await PackageService.create({ userId, packageId });
    if (isRight(result)) {
      registerSuccess(result.right);
    } else {
      registerFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      register,
    },
  };
};

export default useRegisterPackageUserIdState;
