/* eslint-disable @typescript-eslint/no-shadow */

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ICompany } from '../../../repositories/company/company.interface';
import { ErrorType } from '../../../repositories/core/error-type';
import CompanyService from '../../../services/company/company.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; list: ICompany[] };
  isLoading: boolean;
  list: ICompany[];
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (list: ICompany[]) => void;
};

const createStore = create<IState>((set) => ({
  state: { type: 'init' },
  isLoading: false,
  list: [],
  start: () => set({ state: { type: 'start' } }),
  setLoading: (value) => set({ isLoading: value }),
  fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
  fetchSuccess: (list) => set({ state: { type: 'fetchSuccess', list }, list }),
}));

const useCompanyListState = () => {
  const {
    state,
    isLoading,
    list,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = createStore();

  const getList = async () => {
    start();
    setLoading(true);
    const result = await CompanyService.getList();
    if (isRight(result)) {
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    list,
    event: {
      getList,
    },
  };
};

export default useCompanyListState;
