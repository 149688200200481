import { Either } from 'fp-ts/lib/Either';

import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';
import {
  IReport,
  IReportDetail,
} from '../../repositories/report/report.interface';
import ViewReportRepository from '../../repositories/view-report/report.repository';

class ViewReportService {
  static getList({
    sortNew,
    pageSize,
    pageIndex,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
  }): Promise<Either<ErrorType, PageItems<IReport>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    return ViewReportRepository.getList({ params });
  }

  static getDetail({
    sortNew,
    pageSize,
    pageIndex,
    reportId,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    reportId: number;
  }): Promise<Either<ErrorType, IReportDetail>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
      ReportId: reportId.toString(),
    });
    return ViewReportRepository.getDetail({ params });
  }
}

export default ViewReportService;
