/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import UserService from '../../../../services/user/user.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'unlockFailed'; error: ErrorType }
    | { type: 'unlockSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  unlockFailed: (error: ErrorType) => void;
  unlockSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    unlockFailed: (error) => set({ state: { type: 'unlockFailed', error } }),
    unlockSuccess: () => set({ state: { type: 'unlockSuccess' } }),
  }));

const useUserUnlockState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, unlockFailed, unlockSuccess } =
    useStore();

  const unlockItem = async ({ id }: { id: number }) => {
    start();
    setLoading(true);
    const result = await UserService.unlock({
      id,
    });
    if (isRight(result)) {
      unlockSuccess();
    } else {
      unlockFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      unlockItem,
    },
  };
};

export default useUserUnlockState;
