import { Modal } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';

export const useModalExport = () => {
  const showReportDialog = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    Modal.info({
      title: 'Thông tin xem file báo cáo',
      content: (
        <div>
          <p>Tài khoản: {username}</p>
          <p>Mật khẩu: {password}</p>
        </div>
      ),
      okText: 'Copy & Đóng',
      onOk() {
        copy(`Tài khoản/Mật khẩu: ${username}/${password}`);
      },
    });
  };

  return { showReportDialog };
};
