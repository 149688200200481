import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Checkbox, Collapse, Form } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import AccountScanComponent from './account';
import FilterScanComponent from './filter';
import {
  IAccount,
  ITriggerSubmit,
} from '../../../repositories/account/account.interface';
import { maxPageSize } from '../../../repositories/core/constant';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';
import PermissionPackageWrapper from '../../../wrappers/permission/permission-package-wrapper';
import DrawerAccountDetailComponent from '../../account/components/account-manage-component';
import useAccountListScanState from '../states/useAccountListScanState';

const { Panel } = Collapse;
const TabItemScanComponent = () => {
  const [form] = Form.useForm();

  const { list: companys } = useCompanyListState();

  const {
    event: eventAccount,
    list: accounts,
    listActive: accountActives,
    companyListActive: companyActives,
  } = useAccountListScanState();

  const [selectedAccountDetail, setSelectedAccountDetail] =
    useState<IAccount | null>(null);

  const [isOpenAccountDetail, setIsOpenAccountDetail] =
    useState<boolean>(false);

  const [triggerSubmit, setTriggerSubmit] = useState<ITriggerSubmit>({
    count: 0,
    accounts: [],
  });

  const onBackStatusAccount = (accountId: number, connectionCode: string) => {
    eventAccount.updateConnectionCode({
      accountId,
      connectionCode,
    });
  };

  const onSelectedDetail = (value: IAccount) => {
    setIsOpenAccountDetail(true);
    setSelectedAccountDetail(value);
  };

  const onCloseAccountDetail = () => {
    setIsOpenAccountDetail(false);
    setSelectedAccountDetail(null);
  };

  const onScan = (list: IAccount[]) => {
    setTriggerSubmit({
      count: triggerSubmit.count + 1,
      accounts: list,
    });
  };

  const onIsAllScan = (e: CheckboxChangeEvent) => {
    eventAccount.toggleCheckAll({
      isChecked: e.target.checked,
    });
  };

  const onChangeMemberId = (value: number) => {
    eventAccount.getAccountListByMemberId({ memberId: value });
  };

  useEffect(() => {
    eventAccount.updateCompanyList({ companys });
  }, [companys]);

  useEffect(() => {
    eventAccount.getList({ pageIndex: 1, pageSize: maxPageSize });
  }, []);

  return (
    <div>
      <PermissionPackageWrapper
        children={
          <FilterScanComponent
            accounts={accountActives}
            form={form}
            onScan={onScan}
            onChangeIsAll={onIsAllScan}
            onChangeMemberId={onChangeMemberId}
          />
        }
      ></PermissionPackageWrapper>

      <div className="mt-4">
        {companys.map((company) => {
          const filter = accounts.filter((f) => f.companyId === company.id);

          return (
            filter.length > 0 && (
              <div className="mt-2" key={company.companyCode}>
                <Collapse
                  size="small"
                  key={company.companyCode}
                  expandIconPosition={'start'}
                  defaultActiveKey={company.companyCode}
                >
                  <Panel
                    header={
                      <div>
                        <Checkbox
                          checked={
                            companyActives &&
                            !!companyActives.find((x) => x.id === company.id)
                          }
                          onChange={(e) =>
                            eventAccount.toggleCheckCompany({
                              isChecked: e.target.checked,
                              companyId: company.id,
                            })
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                        <strong className="pl-2">
                          {company.name}{' '}
                          {filter.length > 0 ? `(${filter.length})` : ''}
                        </strong>
                      </div>
                    }
                    key={company.companyCode}
                  >
                    {filter.map((account) => (
                      <AccountScanComponent
                        key={account.id}
                        account={account}
                        accountProcessingList={accounts}
                        triggerSubmit={triggerSubmit}
                        form={form}
                        checked={
                          !!accountActives.find((x) => x.id === account.id)
                        }
                        onSelectedDetail={onSelectedDetail}
                        onBackStatusAccount={onBackStatusAccount}
                        onToggleCheckAccount={(value, checkAccount) =>
                          eventAccount.toggleCheckAccount({
                            isChecked: value,
                            account: checkAccount,
                          })
                        }
                      />
                    ))}
                  </Panel>
                </Collapse>
              </div>
            )
          );
        })}
      </div>

      <DrawerAccountDetailComponent
        selected={selectedAccountDetail}
        isOpen={isOpenAccountDetail}
        onClose={onCloseAccountDetail}
      />
    </div>
  );
};

export default TabItemScanComponent;
