import { useEffect, useState } from 'react';

import { EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import {
  Table,
  Breadcrumb,
  Button,
  Form,
  Input,
  Select,
  FormInstance,
  Popconfirm,
  Tooltip,
  Drawer,
  Space,
  TablePaginationConfig,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import useUserCreateState from './states/useUserCreateState';
import useUserListState from './states/useUserListState';
import useUserLockState from './states/useUserLockState';
import useUserUnlockState from './states/useUserUnlockState';
import useUserUpdateState from './states/useUserUpdateState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import {
  RoleList,
  defaultPageSize,
  drawerStyle,
} from '../../../repositories/core/constant';
import { IOption } from '../../../repositories/core/core.interface';
import { IUser } from '../../../repositories/user/user.interface';
import CommonProccess from '../../../ultis/common-proccess';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import AdminWrapper from '../../../wrappers/admin';
import useAuthenticatedState from '../../../wrappers/auth/states/useAuthenticatedState';
import DrawerPackageManageComponent from '../component/DrawerPackageManageComponent';

const DrawerAddEditUserComponent = ({
  form,
  roleList,
  isClose,
  isLoading,
  selected,
  maxRoleId,
  onSubmit,
  onClose,
}: {
  form: FormInstance<any>;
  roleList: IOption[];
  isClose: boolean;
  isLoading: boolean;
  selected: IUser | null;
  maxRoleId: number;
  onSubmit: (values: any) => void;
  onClose: () => void;
}) => {
  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={onClose}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <div className="grid grid-cols-1  gap-2">
          <div>
            <Form.Item
              name="username"
              label="Tài khoản"
              rules={[{ required: true, message: 'Nhập tên tài khoản' }]}
            >
              <Input
                disabled={!!selected}
                placeholder="Nhập tên tài khoản"
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="password" label="Mật khẩu">
              <Input placeholder="Nhập mật khẩu" allowClear />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="roleId"
              label="Cấp"
              rules={[
                {
                  required: true,
                  message: 'Chọn cấp',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={roleList.filter((x) => x.value > maxRoleId)}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="description" label="Mô tả">
              <Input.TextArea rows={4} placeholder="Nhập mô tả" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const TableList = ({
  maxRoleId,
  form,
  isLoading,
  list,
  current,
  statusList,
  roleList,
  totalItems,
  onLock,
  onUnlock,
  onUpdate,
  onSubmitSearch,
  onOpenDrawer,
  onOpenDrawerPackage,
  onChangePagination,
}: {
  maxRoleId: number;
  form: FormInstance<any>;
  isLoading: boolean;
  list: IUser[];
  current: number;
  statusList: IOption[];
  roleList: IOption[];
  totalItems: number;
  onLock: (item: IUser) => void;
  onUnlock: (item: IUser) => void;
  onUpdate: (item: IUser) => void;
  onSubmitSearch: (values: any) => void;
  onOpenDrawer: () => void;
  onOpenDrawerPackage: (item: IUser) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IUser> = [
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      key: 'username',
      fixed: 'left',
    },
    {
      title: 'Sub quét',
      dataIndex: 'totalAccount',
      key: 'totalAccount',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Loại',

      render: (record: IUser) => {
        const item = CommonProccess.GetLevelUserByValue(record.roleId);
        return (
          <>
            <p>{item?.label}</p>
          </>
        );
      },
    },
    {
      title: 'Ngày hết hạn',
      width: 120,
      render: (record: IUser) => (
        <p>
          {record.expiredDatePackage &&
            CustomDateTime.convertStringToDateTime(
              record.expiredDatePackage ?? null,
              'dd/MM/yyyy',
            )}
        </p>
      ),
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IUser) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 180,
      render: (record: IUser) => (
        <div className="flex gap-2">
          <AdminWrapper>
            <Tooltip title="Quản lý gói">
              <Button
                type="primary"
                onClick={() => onOpenDrawerPackage(record)}
                size="small"
              >
                Quản lý gói
              </Button>
            </Tooltip>
          </AdminWrapper>
          <Tooltip title="Chỉnh sửa">
            <Button
              onClick={() => onUpdate(record)}
              size="small"
              icon={<EditOutlined />}
            />
          </Tooltip>
          {record.isLock ? (
            <Tooltip title="Mở khoá tài khoản">
              <Popconfirm
                title="Mở khoá tài khoản này"
                description="Bạn có chắc chắn mở khoá tài khoản này?"
                onConfirm={() => onUnlock(record)}
                okText="Có"
                cancelText="Không"
              >
                <Button
                  danger
                  size="small"
                  type="dashed"
                  icon={<UnlockOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip title="Khoá tài khoản">
              <Popconfirm
                title="Khoá tài khoản này"
                description="Bạn có chắc chắn khoá tài khoản này?"
                onConfirm={() => onLock(record)}
                okText="Có"
                cancelText="Không"
              >
                <Button
                  danger
                  size="small"
                  type="primary"
                  icon={<LockOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Form onFinish={onSubmitSearch} form={form} layout="vertical">
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-4">
          <div>
            <Form.Item name="username">
              <Input placeholder="Nhập tên tài khoản" />
            </Form.Item>
          </div>

          <div>
            <Form.Item name="isLock">
              <Select
                placeholder={'Chọn tình trạng'}
                style={{ width: '100%' }}
                options={statusList}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="roleId">
              <Select
                placeholder={'Chọn cấp'}
                style={{ width: '100%' }}
                options={roleList.filter((x) => x.value > maxRoleId)}
                allowClear
              />
            </Form.Item>
          </div>
          <div>
            <Button
              className="w-full"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Button onClick={onOpenDrawer} className="w-full" type="primary">
              Thêm mới
            </Button>
          </div>
        </div>
      </Form>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        className="w-full"
        scroll={{ x: 800 }}
        pagination={{
          current,
          defaultCurrent: 1,
          defaultPageSize,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          total: totalItems,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        onChange={onChangePagination}
        size="middle"
        indentSize={20}
      />
    </>
  );
};

const HeaderPageList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Tài khoản',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Tài khoản</h2>
    </div>
  );
};

const HistoryListPage = () => {
  const statusList: IOption[] = [
    {
      value: 'true',
      label: 'Khoá',
    },
    {
      value: 'false',
      label: 'Mở khoá',
    },
  ];

  const roleList: IOption[] = RoleList;

  const { currentAuth } = useAuthenticatedState();
  const { state, event, isLoading, list, totalItems } = useUserListState();
  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useUserCreateState();
  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useUserUpdateState();
  const {
    state: stateLock,
    event: eventLock,
    isLoading: isLoadingLock,
  } = useUserLockState();
  const {
    state: stateUnlock,
    event: eventUnlock,
    isLoading: isLoadingUnlock,
  } = useUserUnlockState();

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const [selectedAccount, setSelectedAccount] = useState<IUser | null>(null);
  const [isCloseCreateUpdate, setIsCloseCreateUpdate] =
    useState<boolean>(false);
  const [isClosePackageManage, setIsClosePackageManage] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
      search: formSearch.getFieldValue('username'),
      isLock: formSearch.getFieldValue('isLock'),
      roleId: formSearch.getFieldValue('roleId'),
    });
  };

  const onCloseCreateUpdate = () => {
    setIsCloseCreateUpdate(false);
    setSelectedAccount(null);
    form.resetFields();
  };

  const onClosePackageManage = () => {
    setIsClosePackageManage(false);
    setSelectedAccount(null);
  };

  const onSubmitCreateUpdate = (values: IUser) => {
    if (selectedAccount && selectedAccount.id) {
      eventUpdate.updateItem({
        id: selectedAccount.id,
        password: values.password,
        description: values.description,
        roleId: values.roleId,
      });
    } else {
      eventCreate.createItem({
        username: values.username ?? '',
        password: values.password,
        description: values.description,
        roleId: values.roleId,
      });
    }
  };

  const onConfirmLock = (item: IUser) => {
    eventLock.lockItem({ id: item.id });
  };

  const onConfirmUnlock = (item: IUser) => {
    eventUnlock.unlockItem({ id: item.id });
  };

  const onSelectedUpdate = (value: IUser) => {
    form.setFieldsValue(value);
    setIsCloseCreateUpdate(true);
    setSelectedAccount(value);
  };

  const onSubmitSearch = () => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  };

  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };

  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Tạo tài khoản thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess() {
        showSuccessDialog('Cập nhật tài khoản thành công!');
        setIsCloseCreateUpdate(false);
        form.resetFields();
        onLoad({});
      },
    });
  }, [stateUpdate.type]);

  useEffect(() => {
    match([stateLock, 'type'])({
      start() {},
      init() {},
      lockFailed(res) {
        showErrorDialog(res.error);
      },
      lockSuccess() {
        showSuccessDialog('Khoá tài khoản thành công!');
        onLoad({});
      },
    });
  }, [stateLock.type]);

  useEffect(() => {
    match([stateUnlock, 'type'])({
      start() {},
      init() {},
      unlockFailed(res) {
        showErrorDialog(res.error);
      },
      unlockSuccess() {
        showSuccessDialog('Mở khoá tài khoản thành công!');
        onLoad({});
      },
    });
  }, [stateUnlock.type]);

  return (
    <div className="w-full p-2">
      <HeaderPageList />
      <TableList
        maxRoleId={currentAuth?.roleId ?? 0}
        list={list}
        totalItems={totalItems}
        current={currentPage}
        isLoading={isLoading}
        onSubmitSearch={onSubmitSearch}
        onLock={onConfirmLock}
        onUnlock={onConfirmUnlock}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsCloseCreateUpdate(true)}
        onOpenDrawerPackage={(record) => {
          setIsClosePackageManage(true);
          setSelectedAccount(record);
        }}
        form={formSearch}
        statusList={statusList}
        roleList={roleList}
        onChangePagination={onChangePagination}
      />
      <DrawerAddEditUserComponent
        maxRoleId={currentAuth?.roleId ?? 0}
        form={form}
        selected={selectedAccount}
        roleList={roleList}
        isClose={isCloseCreateUpdate}
        onClose={onCloseCreateUpdate}
        onSubmit={onSubmitCreateUpdate}
        isLoading={!!(isLoadingCreate || isLoadingUpdate)}
      />
      <DrawerPackageManageComponent
        selected={selectedAccount}
        isClose={isClosePackageManage}
        onClose={onClosePackageManage}
      />
    </div>
  );
};

export default HistoryListPage;
