/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../../repositories/core/error-type';
import { ICurrency } from '../../../../../repositories/currency/currency.interface';
import CurrencyService from '../../../../../services/currency/currency.service';

type ICurrencyListState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; list: ICurrency[] };
  isLoading: boolean;
  list: ICurrency[];
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (list: ICurrency[]) => void;
};

const createStore = () =>
  create<ICurrencyListState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    list: [],
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (list) =>
      set({ state: { type: 'fetchSuccess', list }, list }),
  }));

const useCurrencyListState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    list,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = useStore();

  const getList = async () => {
    start();
    setLoading(true);
    const result = await CurrencyService.getList();
    if (isRight(result)) {
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    list,
    event: {
      getList,
    },
  };
};

export default useCurrencyListState;
