export const LocalStorageKey = {
  Token: 'token',
};

class LocalStorage {
  static getToken(): string | null {
    try {
      const result = localStorage.getItem(LocalStorageKey.Token);
      return result;
    } catch {
      return null;
    }
  }

  static setToken(value: string): boolean {
    try {
      localStorage.setItem(LocalStorageKey.Token, value);
      return true;
    } catch {
      return false;
    }
  }

  static removeToken(): boolean {
    try {
      localStorage.removeItem(LocalStorageKey.Token);
      return true;
    } catch {
      return false;
    }
  }
}

export default LocalStorage;
