import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { ICategoryMethod } from './category-method.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class CategoryMethodRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<ICategoryMethod>>> {
    const query = () =>
      axiosClient<PageItems<ICategoryMethod>>({
        method: Methods.GET,
        url: '/v1/categorys/get-list-detail-by',
        params,
        data: {},
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default CategoryMethodRepository;
