/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

// eslint-disable-next-line lodash/import-scope
import { isRight } from 'fp-ts/lib/Either';
// eslint-disable-next-line lodash/import-scope
import { uniqBy } from 'lodash';
import { create } from 'zustand';

import { IAccountScan } from '../../../repositories/account-scan/account-scan.interface';
import AccountScanService from '../../../services/account-scan/account-scan.service';

type IAccountScanListState = {
  state: { type: 'init' } | { type: 'start' };
  root: IAccountScan[];
  list: IAccountScan[];
  loadingList: number[];
  start: () => void;
  setList: (list: IAccountScan[], root: IAccountScan[]) => void;
  setLoadingAccountScan: (list: number[]) => void;
};

const createStore = () =>
  create<IAccountScanListState>((set) => ({
    state: { type: 'init' },
    root: [],
    list: [],
    loadingList: [],
    start: () => set({ state: { type: 'start' } }),
    setList: (list, root) => set({ list, root }),
    setLoadingAccountScan: (list) => set({ loadingList: list }),
  }));

const useAccountScanListScanState = () => {
  const [useStore] = useState(createStore);
  const { state, list, root, loadingList, setList, setLoadingAccountScan } =
    useStore();

  const setupLevel = (list: IAccountScan[]) => {
    const oneLevel = list.filter((x) => x.parentId === null);
    oneLevel.forEach((one) => {
      // eslint-disable-next-line no-param-reassign
      one.children = list.filter((x) => x.parentId === one.id);
      one.children.forEach((two) => {
        // eslint-disable-next-line no-param-reassign
        two.children = list.filter((x) => x.parentId === two.id);
        two.children.forEach((three) => {
          // eslint-disable-next-line no-param-reassign
          three.children = list.filter((x) => x.parentId === three.id);
          three.children.forEach((four) => {
            // eslint-disable-next-line no-param-reassign
            four.children = list.filter((x) => x.parentId === four.id);
            four.children.forEach((five) => {
              // eslint-disable-next-line no-param-reassign
              five.children = list.filter((x) => x.parentId === five.id);
            });
          });
        });
      });
    });
    return oneLevel;
  };

  const setLoadingList = (value: boolean, accountScanId?: number) => {
    if (accountScanId) {
      const isCheck = loadingList.find((x) => x === accountScanId);
      if (value) {
        if (!isCheck) {
          const tempList = [...loadingList, accountScanId];
          setLoadingAccountScan(tempList);
        }
      } else {
        const tempList = loadingList.filter((x) => x !== accountScanId);
        setLoadingAccountScan(tempList);
      }
    }
  };

  const getAccountScanListById = async ({
    pageSize,
    pageIndex,
    accountId,
    isQuick,
    memberId,
    toDate,
    fromDate,
    accountScanId,
  }: {
    pageSize: number;
    pageIndex: number;
    accountId: number;
    isQuick: boolean;
    memberId?: number | null;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    accountScanId?: number;
  }) => {
    setLoadingList(true, accountScanId);
    const result = await AccountScanService.getList({
      sortNew: false,
      pageSize,
      pageIndex,
      accountId,
      isQuick,
      memberId,
      toDate,
      fromDate,
      parentId: accountScanId ?? null,
    });
    if (isRight(result)) {
      let rootDataList: IAccountScan[] = [...root];
      result.right.items.forEach((item) => {
        rootDataList = rootDataList.filter((x) => x.id !== item.id);
        rootDataList.push(item);
      });
      const nestedDataList = setupLevel([...rootDataList]);
      setList(nestedDataList, rootDataList);
    }
    setLoadingList(false, accountScanId);
  };

  const removeAccountListByParentId = async ({
    accountScanId,
  }: {
    accountScanId?: number;
  }) => {
    const tempDataRootList = root.filter((x) => x.parentId !== accountScanId);
    const nestedDataList = setupLevel([...tempDataRootList]);
    setList(nestedDataList, tempDataRootList);
  };

  const clearData = async () => {
    setList([], []);
  };

  const getAccountScanListByMethod = async ({
    pageSize,
    pageIndex,
    accountId,
    isQuick,
    memberId,
    toDate,
    fromDate,
  }: {
    pageSize: number;
    pageIndex: number;
    accountId: number;
    isQuick: boolean;
    memberId?: number | null;
    fromDate: moment.Moment;
    toDate: moment.Moment;
  }) => {
    let parentIdList: string[] = [];
    const resultParentList = await AccountScanService.getListMapMethod({
      accountId,
    });
    if (isRight(resultParentList) && resultParentList.right.length > 0) {
      resultParentList.right.forEach((x) => {
        const arrayIds = x.parentIds.split(',');
        parentIdList = parentIdList.concat(arrayIds);
      });
      parentIdList = uniqBy(parentIdList, (obj) => obj);
      const rootDataList: IAccountScan[] = [...root];
      // eslint-disable-next-line no-restricted-syntax
      for await (const parentIdChild of parentIdList) {
        const parentId = Number(parentIdChild);
        setLoadingList(true, parentId);
        const result = await AccountScanService.getList({
          sortNew: false,
          pageSize,
          pageIndex,
          accountId,
          isQuick,
          memberId,
          toDate,
          fromDate,
          parentId,
        });
        if (isRight(result)) {
          result.right.items.forEach((item) => {
            if (rootDataList.filter((x) => x.id === item.id).length === 0) {
              rootDataList.push(item);
            }
          });
        }
        setLoadingList(false, parentId);
      }
      const nestedDataList = setupLevel([...rootDataList]);
      setList(nestedDataList, rootDataList);
    } else if (
      isRight(resultParentList) &&
      resultParentList.right.length === 0
    ) {
      const result = await AccountScanService.getList({
        sortNew: false,
        pageSize,
        pageIndex,
        accountId,
        isQuick,
        memberId,
        toDate,
        fromDate,
      });
      if (isRight(result)) {
        let rootDataList: IAccountScan[] = [...root];
        result.right.items.forEach((item) => {
          rootDataList = rootDataList.filter((x) => x.id !== item.id);
          rootDataList.push(item);
        });
        const nestedDataList = setupLevel([...rootDataList]);
        setList(nestedDataList, rootDataList);
      }
    }
  };

  return {
    state,
    list,
    loadingList,
    event: {
      getAccountScanListById,
      getAccountScanListByMethod,
      removeAccountListByParentId,
      clearData,
    },
  };
};

export default useAccountScanListScanState;
