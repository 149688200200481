import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { APIURL } from './constant';
import SentryCatch from './sentry';
import LocalStorage from '../../ultis/local-storage';

const instance = axios.create({
  baseURL: APIURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    'x-api-key': 'keyAceAcounting2022',
  },
  timeout: 216000000,
});

instance.interceptors.request.use(
  async (config) => {
    const token = LocalStorage.getToken();
    if (token && config) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    SentryCatch.CatchError({ error: err });
    if (err.response) {
      return Promise.reject(err.response);
    }
    if (err.request) {
      return Promise.reject(err.request);
    }
    return Promise.reject(err.message);
  },
);

/**
 * Replaces main `axios` instance with the custom-one.
 *
 * @param cfg - Axios configuration object.
 * @returns A promise object of a response of the HTTP request with the 'data' object already
 * destructured.
 */
const axiosClient = <T>(cfg: AxiosRequestConfig) =>
  instance.request<any, T>(cfg);

export default axiosClient;
