import { useEffect, useState } from 'react';

import { Button, Table, Breadcrumb } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

import useViewReportListState from './states/useViewReportListState';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { defaultPageSize } from '../../../../repositories/core/constant';
import { IReport } from '../../../../repositories/report/report.interface';
import { match } from '../../../../ultis/custom-matcher';
import CustomDateTime from '../../../../ultis/format-datetime';
import useAuthenticatedState from '../../../../wrappers/auth/states/useAuthenticatedState';
import { useModalExport } from '../../../report/hooks/useModalExport';
import useReportExportState from '../../../report/list/states/useReportExportState';
import DrawerReportDetailComponent from '../detail/report-detail-component';

const TablReportList = ({
  isLoading,
  list,
  onDetail,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IReport[];
  onDetail: (item: IReport) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IReport> = [
    {
      title: 'Báo cáo',
      render: (record: IReport) => (
        <p>
          {CustomDateTime.convertStringToDateTime(
            record.fromDate ?? null,
            'dd/MM/yyyy',
          )}
          {' - '}
          {CustomDateTime.convertStringToDateTime(
            record.toDate ?? null,
            'dd/MM/yyyy',
          )}
        </p>
      ),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IReport) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 110,
      render: (record: IReport) => (
        <div className="flex gap-2">
          <Button onClick={() => onDetail(record)} type="primary" size="small">
            Chi tiết
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        scroll={{ x: 800 }}
        className="w-full"
        pagination={{
          defaultPageSize: 20,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderReportList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Khách hàng',
          },
          {
            title: 'Báo cáo',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Báo cáo</h2>
    </div>
  );
};

const ViewReportListPage = () => {
  const { state, event, isLoading, list } = useViewReportListState();
  const { event: eventAuth } = useAuthenticatedState();
  const navigate = useNavigate();
  const {
    state: stateExport,
    event: eventExport,
    isLoading: isLoadingExport,
  } = useReportExportState();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const { showErrorDialog } = useErrorHandler();
  const { showReportDialog } = useModalExport();
  const onLogout = () => {
    eventAuth.logout();
    navigate(`/view-report/login`);
  };
  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };
  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateExport, 'type'])({
      start() {},
      init() {},
      exportFailed(res) {
        showErrorDialog(res.error);
      },
      exportSuccess(res) {
        showReportDialog({
          username: res.info.username,
          password: res.info.password,
        });
      },
    });
  }, [stateExport.type]);

  return (
    <div className="w-full bg-white p-2">
      <HeaderReportList />
      <TablReportList
        list={list}
        isLoading={isLoading}
        onDetail={(report) => {
          setSelectedReport(report);
          setIsOpenDetail(true);
        }}
        onChangePagination={onChangePagination}
      />
      <DrawerReportDetailComponent
        isOpen={isOpenDetail}
        selected={selectedReport}
        isLoadingExport={isLoadingExport}
        onClose={() => {
          setSelectedReport(null);
          setIsOpenDetail(false);
        }}
        onExport={(id) => eventExport.exportItem({ id })}
      />
      <div className="p-2">
        <Button danger type="primary" className="w-full" onClick={onLogout}>
          Đăng Xuất
        </Button>
      </div>
    </div>
  );
};

export default ViewReportListPage;
