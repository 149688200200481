import { Either } from 'fp-ts/lib/Either';

import { IScanConfig } from '../../repositories/config/config.interface';
import ConfigRepository from '../../repositories/config/config.repository';
import { PageItems } from '../../repositories/core/core.interface';
import { ErrorType } from '../../repositories/core/error-type';

class ConfigService {
  static getScanConfig({
    sortNew,
    pageSize,
    pageIndex,
    marketCode,
  }: {
    sortNew: boolean;
    pageSize: number;
    pageIndex: number;
    marketCode?: string;
  }): Promise<Either<ErrorType, PageItems<IScanConfig>>> {
    const params = new URLSearchParams({
      SortNew: sortNew.toString(),
      PageSize: pageSize.toString(),
      PageIndex: pageIndex.toString(),
    });
    if (marketCode) {
      params.append('MarketCode', marketCode);
    }
    return ConfigRepository.getScanConfig({ params });
  }

  static create({
    companyId,
    name,
    value,
    children,
    categoryParentId,
  }: {
    companyId: number;
    name: string;
    value: string;
    children: string;
    categoryParentId: number;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const data = {
      companyId,
      name,
      value,
      children,
      categoryParentId,
    } as IScanConfig;
    return ConfigRepository.create({ data });
  }

  static update({
    id,
    companyId,
    name,
    value,
    children,
    categoryParentId,
  }: {
    id: number;
    companyId: number;
    name: string;
    value: string;
    children: string;
    categoryParentId: number;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const data = {
      id,
      companyId,
      name,
      value,
      children,
      categoryParentId,
    } as IScanConfig;
    return ConfigRepository.update({ data });
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    return ConfigRepository.delete({ id });
  }

  static reset({
    companyId,
  }: {
    companyId: number;
  }): Promise<Either<ErrorType, boolean>> {
    return ConfigRepository.reset({ companyId });
  }

  static getConfigScanUserIdBy({
    categoryId,
    companyId,
  }: {
    categoryId: number;
    companyId: number;
  }): Promise<Either<ErrorType, IScanConfig>> {
    const params = new URLSearchParams({
      categoryId: categoryId.toString(),
      companyId: companyId.toString(),
    });
    return ConfigRepository.getConfigScanUserIdBy({ params });
  }
}

export default ConfigService;
