import React from 'react';

import './styles/globals.scss';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import initializeServices from './initializeServices';
import reportWebVitals from './reportWebVitals';
import router from './routes';

initializeServices();
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.Suspense fallback={'....'}>
    <RouterProvider router={router} />
  </React.Suspense>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
