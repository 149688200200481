import { Drawer } from 'antd';

import { drawerStyle } from '../../../../repositories/core/constant';
import { ICustomer } from '../../../../repositories/customer/customer.interface';
import CustomerViewReportListPage from '../../view-report-list/view-report-list';

const DrawerManageViewReportListCustomerComponent = ({
  selected,
  isClose,
  onClose,
  onReload,
}: {
  selected: ICustomer | null;
  isClose: boolean;
  onClose: () => void;
  onReload: (value: ICustomer) => void;
}) => {
  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={() => {
        onClose();
      }}
      open={isClose}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
    >
      {selected && <CustomerViewReportListPage memberId={selected?.id} />}
    </Drawer>
  );
};
export default DrawerManageViewReportListCustomerComponent;
