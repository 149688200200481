import { Either } from 'fp-ts/lib/Either';

import { IAuth, IHealth } from '../../repositories/auth/auth.interface';
import AuthRepository from '../../repositories/auth/auth.repository';
import { ErrorType } from '../../repositories/core/error-type';
import { IUsedPackage } from '../../repositories/package/package.interface';

class AuthService {
  static getDetail(): Promise<Either<ErrorType, IAuth>> {
    return AuthRepository.getDetail();
  }

  static changePassword({
    newPassword,
    oldPassword,
  }: {
    newPassword: string;
    oldPassword: string;
  }): Promise<Either<ErrorType, boolean>> {
    return AuthRepository.changePassword({ newPassword, oldPassword });
  }

  static getPackageDetail(): Promise<Either<ErrorType, IUsedPackage>> {
    return AuthRepository.getPackageDetail();
  }

  static getHealth(): Promise<Either<ErrorType, IHealth>> {
    return AuthRepository.getHealth();
  }
}

export default AuthService;
