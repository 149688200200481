/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../../repositories/core/error-type';
import AccountScanService from '../../../../../services/account-scan/account-scan.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'deleteFailed'; error: ErrorType }
    | { type: 'deleteSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  deleteFailed: (error: ErrorType) => void;
  deleteSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    deleteFailed: (error) => set({ state: { type: 'deleteFailed', error } }),
    deleteSuccess: () => set({ state: { type: 'deleteSuccess' } }),
  }));

const useAccountScanMapMethodDeleteState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, deleteFailed, deleteSuccess } =
    useStore();

  const deleteItem = async ({ id }: { id: number }) => {
    start();
    setLoading(true);
    const result = await AccountScanService.deleteAccountScanMapMethod({
      id,
    });
    if (isRight(result)) {
      deleteSuccess();
    } else {
      deleteFailed(result.left);
    }
    setLoading(false);
  };

  const deleteItemGroup = async ({
    accountScanId,
    memberId,
    methodGroupId,
  }: {
    accountScanId: number;
    memberId: number;
    methodGroupId: number;
  }) => {
    start();
    setLoading(true);
    const result = await AccountScanService.deleteAccountScanMapGroupMethod({
      accountScanId,
      memberId,
      methodGroupId,
    });
    if (isRight(result)) {
      deleteSuccess();
    } else {
      deleteFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      deleteItem,
      deleteItemGroup,
    },
  };
};

export default useAccountScanMapMethodDeleteState;
