import { message } from 'antd';

export const useSuccessHandler = () => {
  function showSuccessDialog(msg: string) {
    message.open({
      type: 'success',
      content: msg,
    });
  }

  return { showSuccessDialog };
};
