import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { IGroupMethod } from './group-method.interface';
import axiosClient from '../core/axios-client';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';
import { IMethod } from '../method/method.interface';

class GroupMethodRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IGroupMethod>>> {
    const query = () =>
      axiosClient<PageItems<IGroupMethod>>({
        method: Methods.GET,
        url: '/v1/method-groups/get-list-detail-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    data,
  }: {
    data: IGroupMethod;
  }): Promise<Either<ErrorType, IGroupMethod>> {
    const query = () =>
      axiosClient<IGroupMethod>({
        method: Methods.POST,
        url: `/v1/method-groups`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static update({
    data,
  }: {
    data: IGroupMethod;
  }): Promise<Either<ErrorType, IGroupMethod>> {
    const query = () =>
      axiosClient<IGroupMethod>({
        method: Methods.PUT,
        url: `/v1/method-groups`,
        data,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/method-groups/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static getMethodGroupDeliverReceiveList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, IGroupMethod[]>> {
    const query = () =>
      axiosClient<IGroupMethod[]>({
        method: Methods.GET,
        url: '/v1/method-groups/get-list-deliver-or-receive-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static getMethodListOfGroup({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IMethod>>> {
    const query = () =>
      axiosClient<PageItems<IMethod>>({
        method: Methods.GET,
        url: '/v1/method-groups/get-list-map-method-group-by',
        params,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static addToGroup({
    methodId,
    methodGroupId,
  }: {
    methodId: number;
    methodGroupId: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.POST,
        url: `/v1/method-groups/add-method`,
        data: { methodId, methodGroupId },
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static deleteMethodFromGroup({
    id,
  }: {
    id: number;
  }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/method-groups/delete-method/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }
}

export default GroupMethodRepository;
