import { useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import useMaintenanceState from './states/useMaintenanceState';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { StatusCodeWeb } from '../../repositories/core/constant';
import { match } from '../../ultis/custom-matcher';

const ServicesWrapper = () => {
  const { state, event } = useMaintenanceState();
  const { showErrorDialog } = useErrorHandler();
  const navigate = useNavigate();
  useEffect(() => {
    event.getHealth();
    setInterval(() => {
      event.getHealth();
    }, 180000);
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess(res) {
        if (res.res.statusCode === StatusCodeWeb.MAINTENANCE) {
          navigate(`/maintenance`);
        }
      },
    });
  }, [state.type]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default ServicesWrapper;
