/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
// eslint-disable-next-line lodash/import-scope
import { uniqBy } from 'lodash';
import { create } from 'zustand';

import { IAccountScanMethod } from '../../../../repositories/account-scan/account-scan.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import AccountScanService from '../../../../services/account-scan/account-scan.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'setList'; list: IAccountScanMethod[] }
    | { type: 'setLoadingAccountScanList'; loadingList: number[] };

  loadingAccountScanList: number[];
  list: IAccountScanMethod[];
  start: () => void;
  fetchFailed: (error: ErrorType) => void;
  setList: (list: IAccountScanMethod[]) => void;
  setLoadingAccountScanList: (loadingList: number[]) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    loadingAccountScanList: [],
    list: [],
    start: () => set({ state: { type: 'start' } }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    setList: (list) => set({ state: { type: 'setList', list }, list }),
    setLoadingAccountScanList: (loadingList) =>
      set({
        state: { type: 'setLoadingAccountScanList', loadingList },
        loadingAccountScanList: loadingList,
      }),
  }));

const useAccountDetailState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    list,
    loadingAccountScanList,
    start,
    fetchFailed,
    setList,
    setLoadingAccountScanList,
  } = useStore();

  const onFinishedLoadingAccountScan = (
    accountScanId: number,
    isFinished: boolean,
  ) => {
    const cloneList = [...loadingAccountScanList];
    if (isFinished) {
      setLoadingAccountScanList(cloneList.filter((x) => x !== accountScanId));
    } else {
      cloneList.push(accountScanId);
      setLoadingAccountScanList(cloneList);
    }
  };
  const removeListByParentId = async ({ parentId }: { parentId: number }) => {
    let cloneList = [...list];
    cloneList = cloneList.filter((x) => x.parentId !== parentId);
    setList(cloneList);
  };
  const getList = async ({
    pageSize,
    pageIndex,
    accountId,
    parentId,
  }: {
    pageSize: number;
    pageIndex: number;
    accountId: number;
    parentId: number | null;
  }) => {
    let cloneList = [...list];
    start();
    if (parentId) {
      onFinishedLoadingAccountScan(parentId, false);
      cloneList = cloneList.filter((x) => x.parentId !== parentId);
    }
    const result = await AccountScanService.getListMapMethodBy({
      sortNew: false,
      pageSize,
      pageIndex,
      accountId,
      parentId,
    });

    if (isRight(result)) {
      result.right.items.forEach((item) => {
        cloneList = cloneList.filter((x) => x.id !== item.id);
        cloneList.push(item);
      });
      setList(cloneList);
    } else {
      fetchFailed(result.left);
    }
    if (parentId) {
      onFinishedLoadingAccountScan(parentId, true);
    }
  };

  const getAccountScanListByMethod = async ({
    pageSize,
    pageIndex,
    accountId,
  }: {
    pageSize: number;
    pageIndex: number;
    accountId: number;
  }) => {
    const cloneList = [...list];
    let parentIdList: string[] = [];
    const resultParentList = await AccountScanService.getListMapMethod({
      accountId,
    });
    if (isRight(resultParentList) && resultParentList.right.length > 0) {
      resultParentList.right.forEach((x) => {
        const arrayIds = x.parentIds.split(',');
        parentIdList = parentIdList.concat(arrayIds);
      });
      parentIdList = uniqBy(parentIdList, (obj) => obj);
      // eslint-disable-next-line no-restricted-syntax
      for await (const parentIdChild of parentIdList) {
        const result = await AccountScanService.getListMapMethodBy({
          sortNew: false,
          pageSize,
          pageIndex,
          accountId,
          parentId: parentIdChild === 'NULL' ? null : parseFloat(parentIdChild),
        });
        if (isRight(result)) {
          result.right.items.forEach((element) => {
            const findIndex = cloneList.findIndex((x) => x.id === element.id);
            if (findIndex < 0) {
              cloneList.push(element);
            } else {
              cloneList[findIndex] = element;
            }
          });
        } else {
          fetchFailed(result.left);
        }
      }
      setList(cloneList);
    } else if (
      isRight(resultParentList) &&
      resultParentList.right.length === 0
    ) {
      getList({
        pageSize,
        pageIndex,
        accountId,
        parentId: null,
      });
    }
  };

  return {
    state,
    loadingAccountScanList,
    list,
    event: {
      getList,
      getAccountScanListByMethod,
      removeListByParentId,
    },
  };
};

export default useAccountDetailState;
