import React from 'react';

import CustomLoading from './CustomLoading/CustomLoading';

const LoadingPage = () => (
  <div className={'flex h-full w-full grow items-center justify-center'}>
    <CustomLoading />
  </div>
);

export default LoadingPage;
