/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { IOption } from '../../../../repositories/core/core.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import { IReportCompanyDetail } from '../../../../repositories/report/report.interface';
import ReportService from '../../../../services/report/report.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; detail: IReportCompanyDetail };
  isLoading: boolean;
  detail: IReportCompanyDetail | null;
  customerList: IOption[];
  customerExchangedList: IOption[];
  openList: string[];
  start: () => void;
  setLoading: (value: boolean) => void;
  setOpenList: (list: string[]) => void;
  setCustomerList: (list: IOption[]) => void;
  setCustomerExchangedList: (list: IOption[]) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (detail: IReportCompanyDetail) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    detail: null,
    openList: [],
    customerList: [],
    customerExchangedList: [],
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    setOpenList: (list) => set({ openList: list }),
    setCustomerList: (list) => set({ customerList: list }),
    setCustomerExchangedList: (list) => set({ customerExchangedList: list }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (detail) =>
      set({ state: { type: 'fetchSuccess', detail }, detail }),
  }));

const useReportMarketCodeDetailState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    detail,
    openList,
    customerList,
    customerExchangedList,
    start,
    setLoading,
    setCustomerList,
    setCustomerExchangedList,
    fetchFailed,
    fetchSuccess,
    setOpenList,
  } = useStore();

  const toggleOpen = (key: string) => {
    let cloneList = [...openList];
    const find = cloneList.find((x) => x === key);
    if (find) {
      cloneList = cloneList.filter((x) => x !== key);
    } else {
      cloneList.push(key);
    }
    setOpenList(cloneList);
  };

  const getDetail = async ({
    pageSize,
    pageIndex,
    reportId,
  }: {
    pageSize: number;
    pageIndex: number;
    reportId: number;
  }) => {
    start();
    setLoading(true);
    const result = await ReportService.getDetailMarketCodeBy({
      sortNew: true,
      pageSize,
      pageIndex,
      reportId,
    });

    if (isRight(result)) {
      const memberList: IOption[] = [];
      result.right.companies.forEach((company) => {
        company.members.forEach((member) => {
          if (!memberList.find((x) => x.value === member.id)) {
            memberList.push({
              label: member.username,
              value: member.id,
            } as IOption);
          }
        });
      });
      const memberExchangedList: IOption[] = [];
      result.right.companies.forEach((company) => {
        company.members
          .filter((x) => x.exchangeCurrency && x.exchangeCurrency.details)
          .forEach((member) => {
            if (!memberList.find((x) => x.value === member.id)) {
              memberList.push({
                label: member.username,
                value: member.id,
              } as IOption);
            }
          });
      });
      setCustomerList(memberList);
      setCustomerExchangedList(memberExchangedList);
      fetchSuccess(result.right);
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    detail,
    openList,
    customerList,
    customerExchangedList,
    event: {
      getDetail,
      toggleOpen,
    },
  };
};

export default useReportMarketCodeDetailState;
