/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../repositories/core/error-type';
import ReportService from '../../../services/report/report.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'createFailed'; error: ErrorType }
    | { type: 'createSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  createFailed: (error: ErrorType) => void;
  createSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    createFailed: (error) => set({ state: { type: 'createFailed', error } }),
    createSuccess: () => set({ state: { type: 'createSuccess' } }),
  }));

const useReportCreateState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, createSuccess, createFailed } =
    useStore();

  const createItem = async ({
    name,
    description,
    isQuick,
    fromDate,
    toDate,
    arrayAccountId,
  }: {
    name: string;
    description: string;
    isQuick: boolean;
    fromDate: string;
    toDate: string;
    arrayAccountId: number[];
  }) => {
    start();
    setLoading(true);
    const result = await ReportService.create({
      name,
      description,
      isQuick,
      fromDate,
      toDate,
      arrayAccountId,
    });
    if (isRight(result)) {
      createSuccess();
    } else {
      createFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      createItem,
    },
  };
};

export default useReportCreateState;
