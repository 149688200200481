/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import UserService from '../../../../services/user/user.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'lockFailed'; error: ErrorType }
    | { type: 'lockSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  lockFailed: (error: ErrorType) => void;
  lockSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    lockFailed: (error) => set({ state: { type: 'lockFailed', error } }),
    lockSuccess: () => set({ state: { type: 'lockSuccess' } }),
  }));

const useUserLockState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, lockFailed, lockSuccess } =
    useStore();

  const lockItem = async ({ id }: { id: number }) => {
    start();
    setLoading(true);
    const result = await UserService.lock({
      id,
    });
    if (isRight(result)) {
      lockSuccess();
    } else {
      lockFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      lockItem,
    },
  };
};

export default useUserLockState;
