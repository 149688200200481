import { useEffect, useState } from 'react';

import { Button, Form, FormInstance, Input, Modal } from 'antd';

import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { match } from '../../../ultis/custom-matcher';
import useReportCreateState from '../states/useReportCreateState';

const ModalReportCreate = ({
  formFilter,
  disabled,
  arrayAccountId,
}: {
  formFilter: FormInstance<any>;
  disabled: boolean;
  arrayAccountId: number[];
}) => {
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const { state, event } = useReportCreateState();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = () => {
    const filter = formFilter.getFieldsValue();
    event.createItem({
      name: `${filter.fromDate.format('yyyy/MM/DD')} - ${filter.toDate.format(
        'yyyy/MM/DD',
      )}`,
      description: form.getFieldValue('description'),
      isQuick: filter.isQuick,
      fromDate: filter.fromDate.format('yyyy-MM-DD'),
      toDate: filter.toDate.format('yyyy-MM-DD'),
      arrayAccountId,
    });
  };
  const handleOk = () => {
    setConfirmLoading(true);
    form.submit();
  };

  const handleCancel = () => {
    setIsOpen(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
        setConfirmLoading(false);
        setIsOpen(false);
        form.resetFields();
      },
      createSuccess() {
        showSuccessDialog('Tạo báo cáo thành công!');
        setConfirmLoading(false);
        setIsOpen(false);
        form.resetFields();
      },
    });
  }, [state.type]);

  return (
    <>
      <Button disabled={disabled} type="dashed" onClick={() => setIsOpen(true)}>
        Lưu báo cáo
      </Button>
      <Modal
        title={`Tạo báo cáo`}
        open={isOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Thay đổi"
        cancelText="Hủy bỏ"
      >
        <div className="p-4">
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <div className="grid grid-cols-1 gap-2">
              <Form.Item name="description">
                <Input.TextArea placeholder="Nhập mô tả" />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ModalReportCreate;
