/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { IScanConfig } from '../../../../repositories/config/config.interface';
import { ErrorType } from '../../../../repositories/core/error-type';
import ConfigService from '../../../../services/config/config.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'updateFailed'; error: ErrorType }
    | { type: 'updateSuccess'; config: IScanConfig };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  updateFailed: (error: ErrorType) => void;
  updateSuccess: (config: IScanConfig) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    updateFailed: (error) => set({ state: { type: 'updateFailed', error } }),
    updateSuccess: (config) =>
      set({ state: { type: 'updateSuccess', config } }),
  }));

const useScanConfigUpdateState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, updateSuccess, updateFailed } =
    useStore();

  const updateItem = async ({
    id,
    companyId,
    name,
    value,
    children,
    categoryParentId,
  }: {
    id: number;
    companyId: number;
    name: string;
    value: string;
    children: string;
    categoryParentId: number;
  }) => {
    start();
    setLoading(true);
    const result = await ConfigService.update({
      id,
      companyId,
      name,
      value,
      children,
      categoryParentId,
    });
    if (isRight(result)) {
      updateSuccess(result.right);
    } else {
      updateFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      updateItem,
    },
  };
};

export default useScanConfigUpdateState;
