import { Either } from 'fp-ts/lib/Either';

import { ErrorType } from '../../repositories/core/error-type';
import { ICurrency } from '../../repositories/currency/currency.interface';
import CurrencyRepository from '../../repositories/currency/currency.repository';

class CurrencyService {
  static getList(): Promise<Either<ErrorType, ICurrency[]>> {
    return CurrencyRepository.getList();
  }
}

export default CurrencyService;
