import { useEffect } from 'react';

import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SegmentedValue } from 'antd/es/segmented';

import TableFootReport from './table-footer';
import TableHeaderReport from './table-header';
import TableRowAccountScanReport from './table-list-account-scan-report';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { maxPageSize } from '../../../../repositories/core/constant';
import { IOption } from '../../../../repositories/core/core.interface';
import {
  IExchangeCurrencyList,
  IReport,
} from '../../../../repositories/report/report.interface';
import { match } from '../../../../ultis/custom-matcher';
import useReportMarketCodeDetailState from '../states/useReportMarketCodeDetailState';

const TableMarketCodeDetailComponent = ({
  report,
  selectedColumn,
  checkedExchange,
  selectedCustomer,
  triggerFetch,
  onDelete,
  onChangeCheckedExchange,
  onUpdateCustomerList,
  onShowCompare,
}: {
  report: IReport;
  selectedColumn: string[];
  checkedExchange: CheckboxValueType[];
  selectedCustomer: number | null;
  triggerFetch: number;
  onDelete: (value: IExchangeCurrencyList) => void;
  onChangeLayout: (value: SegmentedValue) => void;
  onChangeCheckedExchange: (value: CheckboxValueType[]) => void;
  onUpdateCustomerList: (values: IOption[], exchanged: IOption[]) => void;
  onShowCompare: (memberId: number, accountScan: string) => void;
}) => {
  const {
    state,
    event,
    detail: reportDetail,
    isLoading,
    openList,
    customerList,
    customerExchangedList,
  } = useReportMarketCodeDetailState();
  const { showErrorDialog } = useErrorHandler();
  const fetchReportDetail = () => {
    event.getDetail({
      pageSize: maxPageSize,
      pageIndex: 1,
      reportId: report.id,
    });
  };

  useEffect(() => {
    fetchReportDetail();
  }, []);

  useEffect(() => {
    fetchReportDetail();
  }, [triggerFetch]);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess: () => {
        onUpdateCustomerList(customerList, customerExchangedList);
      },
    });
  }, [state.type]);

  return (
    <>
      {reportDetail && (
        <Checkbox.Group
          style={{ width: '100%' }}
          value={checkedExchange}
          onChange={onChangeCheckedExchange}
        >
          <table className="w-full border border-solid border-neutral-300">
            <TableHeaderReport selectedColumn={selectedColumn} />
            <tbody>
              {reportDetail.companies.map((company) => {
                return (
                  <>
                    <tr>
                      <td colSpan={selectedColumn.length}>
                        <div className="bg-[#e1e1e1] p-2 font-bold ">
                          {company.companyCode}
                        </div>
                      </td>
                    </tr>
                    {company.members
                      .filter(
                        (x) =>
                          (selectedCustomer && x.id === selectedCustomer) ||
                          !selectedCustomer,
                      )
                      .map((member, index) => {
                        return (
                          <TableRowAccountScanReport
                            selectedColumn={selectedColumn}
                            checkedExchange={checkedExchange}
                            key={`${member.id}-${index}`}
                            member={member}
                            order={index + 1}
                            openList={openList}
                            onToggleOpen={(key) => event.toggleOpen(key)}
                            onDelete={onDelete}
                            onShowCompare={onShowCompare}
                          />
                        );
                      })}
                  </>
                );
              })}
            </tbody>
            <TableFootReport
              usd={reportDetail.total.usd}
              vnd={reportDetail.total.vnd}
              selectedColumn={selectedColumn}
              checkedExchange={checkedExchange}
            />
          </table>
        </Checkbox.Group>
      )}
    </>
  );
};

export default TableMarketCodeDetailComponent;
