import { useState, useEffect } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Form, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import DrawerAddMethodToGroupComponent from './components/AddMethodToGroupComponent';
import useRemoveMethodFromGroupState from './components/states/useRemoveMethodFromGroupState';
import useMethodListOfGroupState from './states/useMethodListOfGroupState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { maxPageSize } from '../../../repositories/core/constant';
import { IMethod } from '../../../repositories/method/method.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import useGroupMethodCreateState from '../list/states/useGroupMethodCreateState';

const TableList = ({
  isLoading,
  isLoadingDelete,
  list,
  onDelete,
  onUpdate,
  onOpenDrawer,
}: {
  isLoading: boolean;
  isLoadingDelete: boolean;
  list: IMethod[];
  onDelete: (item: IMethod) => void;
  onUpdate: (item: IMethod) => void;
  onOpenDrawer: () => void;
}) => {
  const columns: ColumnsType<IMethod> = [
    {
      title: 'Công thức',
      dataIndex: 'methodName',
      key: 'methodName',
      width: 180,
      fixed: 'left',
    },

    {
      title: 'Loại',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },

    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IMethod) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (record: IMethod) => (
        <div className="flex gap-2">
          <Tooltip title="Xóa công thức">
            <Popconfirm
              title="Xóa công thức này"
              description="Bạn có chắc chắn xóa công thức này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-2 flex w-full justify-end">
        <Button onClick={onOpenDrawer} type="primary" icon={<PlusOutlined />}>
          Thêm công thức
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading || isLoadingDelete}
        dataSource={list}
        className="w-full"
        scroll={{ x: 1000 }}
        pagination={{
          defaultPageSize: 20,
          position: ['bottomLeft'],
          showSizeChanger: false,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total} nhóm công thức`;
          },
        }}
        size="middle"
        indentSize={20}
      />
    </>
  );
};

const GroupMethodDetailPage = ({
  methodGroupId,
}: {
  methodGroupId: number;
}) => {
  const { state, event, isLoading, list } = useMethodListOfGroupState();
  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useGroupMethodCreateState();

  const {
    state: stateDelete,
    event: eventDelete,
    isLoading: isLoadingDelete,
  } = useRemoveMethodFromGroupState();

  const [form] = Form.useForm();
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const [isCloseCreate, setIsCloseCreate] = useState<boolean>(false);
  const onConfirmDelete = (item: IMethod) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onCloseCreate = () => {
    setIsCloseCreate(false);
    form.resetFields();
  };
  const onSelectedUpdate = (value: IMethod) => {
    form.setFieldsValue(value);
    setIsCloseCreate(true);
  };

  useEffect(() => {
    event.getList({
      pageSize: maxPageSize,
      pageIndex: 1,
      methodGroupId,
    });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess() {
        showSuccessDialog('Tạo nhóm công thức thành công!');
        setIsCloseCreate(false);
        form.resetFields();
        event.getList({
          pageSize: maxPageSize,
          pageIndex: 1,
          methodGroupId,
        });
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa nhóm công thức thành công!');
        event.getList({
          pageSize: maxPageSize,
          pageIndex: 1,
          methodGroupId,
        });
      },
    });
  }, [stateDelete.type]);

  return (
    <div className="w-full p-2">
      <TableList
        list={list}
        isLoading={isLoading}
        isLoadingDelete={isLoadingDelete}
        onDelete={onConfirmDelete}
        onUpdate={onSelectedUpdate}
        onOpenDrawer={() => setIsCloseCreate(true)}
      />
      <DrawerAddMethodToGroupComponent
        methodGroupId={methodGroupId}
        isClose={isCloseCreate}
        onClose={onCloseCreate}
        onReload={() =>
          event.getList({
            pageSize: maxPageSize,
            pageIndex: 1,
            methodGroupId,
          })
        }
      />
    </div>
  );
};

export default GroupMethodDetailPage;
