import React, { useEffect } from 'react';

import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Form, Input, Image } from 'antd';
import { useNavigate } from 'react-router-dom';

import useLoginPageState from './states/useLoginPageState';
import CustomButton from '../../../components/CustomButton';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { match } from '../../../ultis/custom-matcher';

const LoginViewReportPage = () => {
  const [form] = Form.useForm();
  const { state, event, isLoading } = useLoginPageState();
  const { showErrorDialog } = useErrorHandler();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    event.loginUserByToken({
      username: values.username,
      password: values.password,
      clientId: 'ViewReport',
      grantType: 'member',
    });
  };

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      loginFailed(res) {
        showErrorDialog(res.error);
      },
      loginSuccess() {
        navigate(`/view-report`);
      },
    });
  }, [state.type]);

  return (
    <div className="flex h-full w-full ">
      <div className="fixed left-1/2 top-1/2 w-full max-w-[400px] -translate-x-1/2 -translate-y-1/2 rounded-md border border-neutral-100 bg-white  p-10 shadow-lg">
        <div className="pb-10 text-center">
          <Image width={250} src="/logo.png" preview={false} />
        </div>
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <div className="grid grid-cols-1 gap-2">
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Xin vui lòng nhập tên đăng nhập!' },
              ]}
            >
              <Input
                size="large"
                className="h-[40px]"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Tên đăng nhập"
                allowClear
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Xin vui lòng nhập mật khẩu!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Mật khẩu"
                className="h-[40px]"
                autoComplete="off"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item>
              <CustomButton
                className="w-full bg-primary text-base font-bold text-white"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
                size="large"
              >
                Xem báo cáo
              </CustomButton>
            </Form.Item>
          </div>
        </Form>
        <div>
          <span className="block text-sm text-neutral-darkest">
            * Đăng nhập để xem báo cáo của khách hàng
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginViewReportPage;
