import classNames from 'classnames';

import CustomFormatNumberReport from '../../../../../components/CustomFormatNumberReport';

const TableFootReport = ({
  usd,
  vnd,
  selectedColumn,
}: {
  usd: number;
  vnd: number;
  selectedColumn: string[];
}) => {
  return (
    <tfoot className="border-separate ">
      <tr className="p-2 font-semibold">
        {selectedColumn.includes('EC') && (
          <th
            colSpan={selectedColumn.length - 3}
            className="w-[50px] border border-solid border-neutral-300 p-2"
          >
            Tổng cộng
          </th>
        )}
        {selectedColumn.includes('VND') && (
          <th className="w-[150px] border border-solid border-neutral-300 p-2">
            <CustomFormatNumberReport
              className={classNames(
                vnd > 0
                  ? 'font-semibold '
                  : 'font-semibold text-state-negative',
              )}
              value={vnd}
            />
          </th>
        )}
        {selectedColumn.includes('USD') && (
          <th className="w-[150px] border border-solid border-neutral-300 p-2">
            <CustomFormatNumberReport
              className={classNames(
                usd > 0
                  ? 'font-semibold '
                  : 'font-semibold text-state-negative',
              )}
              value={usd}
            />
          </th>
        )}
      </tr>
    </tfoot>
  );
};

export default TableFootReport;
