import {
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import classNames from 'classnames';

import CustomFormatNumberReport from '../../../components/CustomFormatNumberReport';
import { IAccount } from '../../../repositories/account/account.interface';
import {
  IAccountScan,
  IMapMember,
  IMapMethod,
} from '../../../repositories/account-scan/account-scan.interface';
import { ICompany } from '../../../repositories/company/company.interface';
import useCompanyListState from '../../../wrappers/company/states/useCompanyListState';

const getCurrencyMethodBidDivision = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[60px] w-full border-b border-solid border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      {method.bdCurrencyDeliverName !== method.bdCurrencyReceiveName && (
        <>
          <div className="relative h-[30px] w-full font-semibold">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              {method.bdCurrencyReceiveName}
            </span>
          </div>
          <div className="relative h-[30px] w-full border-t border-solid border-neutral-300 font-semibold">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              {method.bdCurrencyDeliverName}
            </span>
          </div>
        </>
      )}
      {method.bdCurrencyDeliverName === method.bdCurrencyReceiveName && (
        <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
          {method.bdCurrencyDeliverName}
        </span>
      )}
    </div>
  );
};

const getCurrencyMethod = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[30px] w-full border-b border-solid border-neutral-300 bg-opacity-40 font-semibold  last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.currencyName}
      </span>
    </div>
  );
};

const getResultMethodBidDivision = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[60px] border-b border-solid border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      {method.bdCurrencyDeliverName !== method.bdCurrencyReceiveName && (
        <>
          <div className="relative h-[30px] w-full font-semibold ">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              <CustomFormatNumberReport
                className={classNames(
                  method.bdCalculatorReceive > 0
                    ? 'font-semibold'
                    : 'font-semibold text-state-negative',
                )}
                value={method.bdCalculatorReceive}
              />
            </span>
          </div>
          <div className="relative h-[30px] w-full border-t border-solid border-neutral-300 font-semibold">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              <CustomFormatNumberReport
                className={classNames(
                  method.bdCalculatorDeliver > 0
                    ? 'font-semibold'
                    : 'font-semibold text-state-negative',
                )}
                value={method.bdCalculatorDeliver}
              />
            </span>
          </div>
        </>
      )}
      {method.bdCurrencyDeliverName === method.bdCurrencyReceiveName && (
        <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
          <CustomFormatNumberReport
            className={classNames(
              method.bdCalculatorReceive + method.bdCalculatorDeliver > 0
                ? 'font-semibold text-state-positive'
                : 'font-semibold text-state-negative',
            )}
            value={method.bdCalculatorReceive + method.bdCalculatorDeliver}
          />
        </span>
      )}
    </div>
  );
};

const getResultMethod = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[30px] w-full border-b border-solid border-neutral-300 bg-opacity-40  font-semibold  last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        <CustomFormatNumberReport
          className={classNames(
            method.calculatorTotal > 0
              ? 'font-semibold'
              : 'font-semibold text-state-negative',
          )}
          value={method.calculatorTotal}
        />
      </span>
    </div>
  );
};

const getMemberMethodBidDivision = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[60px] border-b border-solid border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.memberUsername} [{method.bdPercent * -1}%]
      </span>
    </div>
  );
};

const getMemberMethod = ({ method }: { method: IMapMethod }) => {
  return (
    <div
      className={classNames(
        'relative h-[30px] w-full border-b border-solid border-neutral-300 bg-opacity-40  font-semibold  last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.memberUsername}
      </span>
    </div>
  );
};

const getMethodBidDivision = ({
  method,
  companyCode,
}: {
  method: IMapMethod;
  companyCode: string;
}) => {
  const receive = `${method.bdMethodReceiveName.replace(
    `${companyCode}-`,
    '',
  )}${method.bdPercentReceive > 0 ? `[${method.bdPercentReceive}%]` : ''}`;
  const deliver = `${method.bdMethodDeliverName.replace(
    `${companyCode}-`,
    '',
  )}${method.bdPercentDeliver > 0 ? `[${method.bdPercentDeliver * -1}%]` : ''}`;

  return (
    <div
      className={classNames(
        'relative h-[60px] w-full border-b  border-solid   border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.isDeliverBidDivision ? 'bg-state-critical' : 'bg-state-positive',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {receive}#{deliver}
      </span>
    </div>
  );
};

const getMethod = ({
  method,
  companyCode,
}: {
  method: IMapMethod;
  companyCode: string;
}) => {
  return (
    <div
      className={classNames(
        'relative h-[30px] w-full border-b   border-solid   border-neutral-300 bg-opacity-40 font-semibold last:border-b-0',
        method.deliver === 1 ? 'bg-blue-300' : 'bg-primary',
      )}
    >
      <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        {method.methodName.replace(`${companyCode}-`, '')}
      </span>
    </div>
  );
};

const TableRowAccountScan = ({
  isLoadMore,
  accountScan,
  winloss,
  index,
  classNameUsername,
  loadingList,
  company,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
}: {
  isLoadMore: boolean;
  loadingList: number[];
  classNameUsername?: string;
  accountScan: IAccountScan;
  winloss: IMapMember;
  index: number;
  company: ICompany | null;
  onFetchByParentId: (accountScan: IAccountScan) => void;
  onRemoveByParentId: (accountScan: IAccountScan) => void;
  onOpenMethodManage: (accountScan: IAccountScan) => void;
}) => {
  return (
    <tr className="hover:bg-slate-100">
      {index === 0 && (
        <td
          rowSpan={accountScan.mapMembers.length}
          className="border border-solid border-neutral-300 align-middle text-xs"
        >
          <div
            className={classNames(
              'flex h-full w-full cursor-pointer items-center',
              classNameUsername,
            )}
            onClick={() => {
              if (accountScan.isChildren && isLoadMore) {
                if (accountScan.children.length > 0) {
                  onRemoveByParentId(accountScan);
                } else if (accountScan.children.length === 0) {
                  onFetchByParentId(accountScan);
                }
              }
            }}
          >
            {accountScan.isChildren && isLoadMore && (
              <>
                <div className="inline-block h-[20px] w-[14px]">
                  {accountScan.children.length === 0 &&
                    !loadingList.includes(accountScan.id) && (
                      <PlusSquareOutlined />
                    )}
                  {accountScan.children.length > 0 &&
                    !loadingList.includes(accountScan.id) && (
                      <MinusSquareOutlined />
                    )}
                  {loadingList.includes(accountScan.id) && (
                    <Spin size="small" />
                  )}
                </div>
              </>
            )}

            <p className="inline-block  pl-1 uppercase">
              {accountScan.username}
            </p>
          </div>
        </td>
      )}
      <td className="border border-solid border-neutral-300 p-1 text-left align-middle text-xs font-semibold">
        <div className="block w-[80px] ">
          <span className="line-clamp-1">{winloss.configScanUserName}</span>
        </div>
      </td>
      <td className="border border-solid border-neutral-300  text-center align-middle text-xs">
        <CustomFormatNumberReport value={winloss.turnOver} />
      </td>
      <td className="border border-solid border-neutral-300 text-center align-middle text-xs">
        <CustomFormatNumberReport value={winloss.commBase} />
      </td>
      <td className="border border-solid border-neutral-300 text-center align-middle text-xs">
        <CustomFormatNumberReport value={winloss.grossComm} />
      </td>
      <td className="border border-solid border-neutral-300 text-center align-middle text-xs">
        <CustomFormatNumberReport
          className={classNames(
            winloss.winlossMember > 0
              ? 'font-semibold text-state-positive'
              : 'font-semibold text-state-negative',
          )}
          value={winloss.winlossMember}
        />
      </td>

      <td className="border border-solid border-neutral-300 text-center text-xs">
        {winloss.mapMembers.length > 0 &&
          winloss.mapMembers.map((member) => {
            return (
              <>
                {member.isBidDivision &&
                  getMethodBidDivision({
                    method: member,
                    companyCode: company ? company.companyCode : '',
                  })}
                {!member.isBidDivision &&
                  getMethod({
                    method: member,
                    companyCode: company ? company.companyCode : '',
                  })}
              </>
            );
          })}
        {winloss.mapMembers.length === 0 && (
          <div className="relative h-[30px] w-full">
            <span className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              Chưa có công thức
            </span>
          </div>
        )}
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {winloss.mapMembers.map((member) => {
          return (
            <>
              {member.isBidDivision &&
                getMemberMethodBidDivision({
                  method: member,
                })}
              {!member.isBidDivision &&
                getMemberMethod({
                  method: member,
                })}
            </>
          );
        })}
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {winloss.mapMembers.map((member) => {
          return (
            <>
              {member.isBidDivision &&
                getResultMethodBidDivision({
                  method: member,
                })}
              {!member.isBidDivision &&
                getResultMethod({
                  method: member,
                })}
            </>
          );
        })}
      </td>
      <td className="border border-solid border-neutral-300 text-center text-xs">
        {winloss.mapMembers.map((member) => {
          return (
            <>
              {member.isBidDivision &&
                getCurrencyMethodBidDivision({
                  method: member,
                })}
              {!member.isBidDivision &&
                getCurrencyMethod({
                  method: member,
                })}
            </>
          );
        })}
      </td>
      {index === 0 && (
        <td
          rowSpan={accountScan.mapMembers.length}
          className="border border-solid border-neutral-300 align-middle text-xs"
        >
          <div className="flex h-full w-full items-center justify-center p-[1px]">
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => onOpenMethodManage(accountScan)}
            />
          </div>
        </td>
      )}
    </tr>
  );
};

const TableRowListAccountScan = ({
  account,
  level,
  accountScan,
  loadingList,
  company,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
}: {
  account: IAccount;
  level: number;
  accountScan: IAccountScan;
  loadingList: number[];
  company: ICompany | null;
  onFetchByParentId: (accountScan: IAccountScan) => void;
  onRemoveByParentId: (accountScan: IAccountScan) => void;
  onOpenMethodManage: (accountScan: IAccountScan) => void;
}) => {
  const isLoadMore = !(!account.isScanMember && level > 2);
  return (
    <>
      {accountScan.mapMembers.map((winloss, index) => {
        return (
          <TableRowAccountScan
            isLoadMore={isLoadMore}
            classNameUsername={`pl-${level}`}
            key={`${winloss.accountScanId}-${winloss.configScanUserName}-${index}`}
            winloss={winloss}
            index={index}
            accountScan={accountScan}
            company={company}
            onFetchByParentId={onFetchByParentId}
            onRemoveByParentId={onRemoveByParentId}
            loadingList={loadingList}
            onOpenMethodManage={onOpenMethodManage}
          />
        );
      })}
      {accountScan.children &&
        accountScan.children.map((child, index) => {
          return (
            <TableRowListAccountScan
              level={level + 2}
              key={`${child.id}-${child.parentId}-${index}`}
              account={account}
              accountScan={child}
              loadingList={loadingList}
              company={company}
              onFetchByParentId={onFetchByParentId}
              onRemoveByParentId={onRemoveByParentId}
              onOpenMethodManage={onOpenMethodManage}
            />
          );
        })}
    </>
  );
};

const TableHeaderAccountScan = () => {
  return (
    <thead className="border-separate ">
      <tr className="p-1 font-semibold">
        <th className="w-[120px] border border-solid border-neutral-300">
          Tài Khoản
        </th>
        <th className="w-[80px] border border-solid border-neutral-300">
          Loại
        </th>
        <th className="w-[90px] border border-solid border-neutral-300">
          Mức cược
        </th>
        <th className="w-[90px] border border-solid border-neutral-300">
          CommBase
        </th>
        <th className="w-[90px] border border-solid border-neutral-300">
          GrossComm
        </th>
        <th className="w-[90px] border border-solid border-neutral-300">
          Thắng thua
        </th>

        <th className="border border-solid border-neutral-300">Công thức</th>
        <th className="w-[120px] border border-solid border-neutral-300">
          Thành viên
        </th>
        <th className="w-[90px] border border-solid border-neutral-300">
          Kết quả
        </th>
        <th className="w-[80px] border border-solid border-neutral-300">
          Loại tiền
        </th>
        <th className="w-[24px] border border-solid border-neutral-300"></th>
      </tr>
    </thead>
  );
};

const TableAccountScan = ({
  account,
  loadingList,
  accountScans,
  companyId,
  onFetchByParentId,
  onRemoveByParentId,
  onOpenMethodManage,
}: {
  account: IAccount;
  loadingList: number[];
  accountScans: IAccountScan[];
  companyId: number;
  onFetchByParentId: (accountScan: IAccountScan) => void;
  onRemoveByParentId: (accountScan: IAccountScan) => void;
  onOpenMethodManage: (accountScan: IAccountScan) => void;
}) => {
  const { list: companys } = useCompanyListState();
  const getCompanyCode = companys.find((x) => x.id === companyId);
  return (
    <div>
      <table className="w-full border border-solid border-neutral-300">
        <TableHeaderAccountScan />
        <tbody>
          {accountScans.length > 0 ? (
            accountScans.map((accountScan, index) => {
              const level = 2;
              return accountScan.mapMembers.length > 0 ? (
                <TableRowListAccountScan
                  account={account}
                  level={level}
                  key={`${accountScan.id}-${index}`}
                  accountScan={accountScan}
                  loadingList={loadingList}
                  company={getCompanyCode ?? null}
                  onFetchByParentId={onFetchByParentId}
                  onRemoveByParentId={onRemoveByParentId}
                  onOpenMethodManage={onOpenMethodManage}
                />
              ) : (
                <tr>
                  <td colSpan={10}>
                    <div className="text-md flex h-[80px] w-full items-center justify-center text-center ">
                      Không có dữ liệu
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <div className="text-md flex h-[80px] w-full items-center justify-center text-center ">
                  Không có dữ liệu
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableAccountScan;
