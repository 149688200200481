/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ICategoryMethod } from '../../../../../repositories/category-method/category-method.interface';
import { ErrorType } from '../../../../../repositories/core/error-type';
import CategoryMethodService from '../../../../../services/category-method/category-method.service';

type ICategoryMethodState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; list: ICategoryMethod[] };
  isLoading: boolean;
  list: ICategoryMethod[];
  start: () => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (error: ErrorType) => void;
  fetchSuccess: (list: ICategoryMethod[]) => void;
};

const createStore = () =>
  create<ICategoryMethodState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    list: [],
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    fetchFailed: (error) => set({ state: { type: 'fetchFailed', error } }),
    fetchSuccess: (list) =>
      set({ state: { type: 'fetchSuccess', list }, list }),
  }));

const useCategoryMethodState = () => {
  const [useStore] = useState(createStore);
  const {
    state,
    isLoading,
    list,
    start,
    setLoading,
    fetchFailed,
    fetchSuccess,
  } = useStore();

  const getList = async ({
    pageSize,
    pageIndex,
    companyId,
    companyCode,
    onFinished,
  }: {
    pageSize: number;
    pageIndex: number;
    companyId?: number;
    companyCode?: string;
    onFinished?: (list: ICategoryMethod[]) => void;
  }) => {
    start();
    setLoading(true);
    const result = await CategoryMethodService.getList({
      sortNew: true,
      pageSize,
      pageIndex,
      companyId,
      companyCode,
    });
    if (isRight(result)) {
      fetchSuccess(result.right.items);
      if (onFinished) {
        onFinished(result.right.items);
      }
    } else {
      fetchFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    list,
    event: {
      getList,
    },
  };
};

export default useCategoryMethodState;
