import { useEffect, useState } from 'react';

import {
  Space,
  Button,
  Input,
  Form,
  Drawer,
  FormInstance,
  Select,
  Checkbox,
} from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import classNames from 'classnames';

import useScanConfigCreateState from './states/useScanConfigCreateState';
import useScanConfigUpdateState from './states/useScanConfigUpdateState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { IScanConfig } from '../../../repositories/config/config.interface';
import { drawerStyle, maxPageSize } from '../../../repositories/core/constant';
import CommonProccess from '../../../ultis/common-proccess';
import { match } from '../../../ultis/custom-matcher';
import useCategoryMethodListState from '../../method/components/create-update-method/states/useCategoryMethodListState';

const CreateUpdateConfigCompanyComponent = ({
  selected,
  form,
  onSubmitCreateUpdate,
}: {
  selected: IScanConfig;
  form: FormInstance<any>;
  onSubmitCreateUpdate: (values: IScanConfig) => void;
}) => {
  const parent = CommonProccess.GetCategoryListByCompanyId(
    selected.companyId,
  ).filter((child) => !child.parentId);

  const [checked, setChecked] = useState<CheckboxValueType[]>(
    selected.value ? selected.value.split(',') : [],
  );
  const [checkedChildren, setCheckedChildren] = useState<CheckboxValueType[]>(
    selected.children ? selected.children.split(',') : [],
  );
  const {
    event: eventCategory,
    isLoading: isLoadingCategory,
    list: categories,
  } = useCategoryMethodListState();

  useEffect(() => {
    form.setFieldsValue(selected);
    eventCategory.getList({ pageIndex: 1, pageSize: maxPageSize });
  }, []);
  return (
    <Form
      form={form}
      onFinish={() => {
        onSubmitCreateUpdate({
          ...form.getFieldsValue(),
          companyId: selected.companyId,
          value: checked.join(','),
          children: checkedChildren.join(','),
        } as IScanConfig);
      }}
      layout="vertical"
    >
      <div className="grid grid-cols-1 gap-2">
        <div>
          <Form.Item
            name="name"
            label="Tên"
            rules={[{ required: true, message: 'Nhập tên' }]}
          >
            <Input placeholder="Nhập tên" allowClear />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="categoryParentId"
            label="Loại công thức tương ứng"
            rules={[{ required: true, message: 'Chọn loại công thức' }]}
          >
            <Select
              style={{ width: '100%' }}
              options={categories
                .filter((x) => x.parentId === null)
                .map((category) => {
                  return {
                    label: category.name,
                    value: category.id,
                  };
                })}
            />
          </Form.Item>
        </div>
        <div>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={checked}
            className="w-full"
            onChange={(checkedValues) => setChecked(checkedValues)}
          >
            <div className="w-full">
              <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
                {parent?.map((x) => {
                  const chilrenList = CommonProccess.GetCategoryListByCompanyId(
                    selected.companyId,
                  ).filter(
                    (child) => child.parentId && child.parentId === x.id,
                  );
                  return (
                    <>
                      <div
                        className={classNames(
                          chilrenList.length > 0
                            ? 'col-span-2 lg:col-span-3'
                            : '',
                        )}
                      >
                        <Checkbox value={x.value}>{x.label}</Checkbox>

                        {chilrenList.length > 0 && (
                          <Checkbox.Group
                            style={{ width: '100%' }}
                            value={checkedChildren}
                            className="w-full"
                            onChange={(checkedValues) =>
                              setCheckedChildren(checkedValues)
                            }
                          >
                            <div className="grid grid-cols-2 gap-2 pl-4  pt-4 lg:grid-cols-3">
                              {chilrenList.map((y) => {
                                return (
                                  <div>
                                    <Checkbox value={y.value}>
                                      {y.label}
                                    </Checkbox>
                                  </div>
                                );
                              })}
                            </div>
                          </Checkbox.Group>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Checkbox.Group>
        </div>
      </div>
    </Form>
  );
};
const DrawerCreateUpdateConfigCompanyComponent = ({
  selected,
  isOpen,
  onClose,
  onReload,
}: {
  selected: IScanConfig | null;
  isOpen: boolean;
  onClose: () => void;
  onReload: (value: IScanConfig) => void;
}) => {
  const [form] = Form.useForm();

  const {
    state: stateCreate,
    event: eventCreate,
    isLoading: isLoadingCreate,
  } = useScanConfigCreateState();

  const {
    state: stateUpdate,
    event: eventUpdate,
    isLoading: isLoadingUpdate,
  } = useScanConfigUpdateState();

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();

  const onSubmitCreateUpdate = (formValues: IScanConfig) => {
    if (selected && selected.id) {
      eventUpdate.updateItem({
        id: selected.id,
        companyId: formValues.companyId,
        name: formValues.name,
        value: formValues.value,
        children: formValues.children,
        categoryParentId: formValues.categoryParentId,
      });
    } else {
      eventCreate.createItem({
        companyId: formValues.companyId,
        name: formValues.name,
        value: formValues.value,
        children: formValues.children,
        categoryParentId: formValues.categoryParentId,
      });
    }
  };

  useEffect(() => {
    match([stateCreate, 'type'])({
      start() {},
      init() {},
      createFailed(res) {
        showErrorDialog(res.error);
      },
      createSuccess(res) {
        showSuccessDialog('Tạo cấu hình dữ liệu thành công!');
        onReload(res.config);
        onClose();
      },
    });
  }, [stateCreate.type]);

  useEffect(() => {
    match([stateUpdate, 'type'])({
      start() {},
      init() {},
      updateFailed(res) {
        showErrorDialog(res.error);
      },
      updateSuccess(res) {
        showSuccessDialog('Cập nhật cấu hình dữ liệu thành công!');
        onReload(res.config);
        onClose();
      },
    });
  }, [stateUpdate.type]);

  return (
    <Drawer
      title="Thông tin"
      width={'100%'}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      open={isOpen}
      headerStyle={drawerStyle}
      bodyStyle={drawerStyle}
      extra={
        <Space>
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      {selected && (
        <CreateUpdateConfigCompanyComponent
          form={form}
          selected={selected}
          onSubmitCreateUpdate={onSubmitCreateUpdate}
        />
      )}
    </Drawer>
  );
};
export default DrawerCreateUpdateConfigCompanyComponent;
