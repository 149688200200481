/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../repositories/core/error-type';
import { IReportExport } from '../../../../repositories/report/report.interface';
import ReportService from '../../../../services/report/report.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'exportFailed'; error: ErrorType }
    | { type: 'exportSuccess'; info: IReportExport };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  exportFailed: (error: ErrorType) => void;
  exportSuccess: (info: IReportExport) => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    exportFailed: (error) => set({ state: { type: 'exportFailed', error } }),
    exportSuccess: (info) => set({ state: { type: 'exportSuccess', info } }),
  }));

const useReportExportState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, exportFailed, exportSuccess } =
    useStore();

  const exportItem = async ({ id }: { id: number }) => {
    start();
    setLoading(true);
    const result = await ReportService.export({
      id,
    });
    if (isRight(result)) {
      exportSuccess(result.right);
    } else {
      exportFailed(result.left);
    }
    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      exportItem,
    },
  };
};

export default useReportExportState;
