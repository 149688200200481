import { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Breadcrumb, Popconfirm, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import useReportDeleteState from './states/useReportDeleteState';
import useReportExportState from './states/useReportExportState';
import useReportListState from './states/useReportListState';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { defaultPageSize } from '../../../repositories/core/constant';
import { IReport } from '../../../repositories/report/report.interface';
import { match } from '../../../ultis/custom-matcher';
import CustomDateTime from '../../../ultis/format-datetime';
import DrawerReportDetailComponent from '../detail/report-detail-component';
import { useModalExport } from '../hooks/useModalExport';

const TablReportList = ({
  isLoading,
  list,
  onDetail,
  onDelete,
  onChangePagination,
}: {
  isLoading: boolean;
  list: IReport[];
  onDetail: (item: IReport) => void;
  onDelete: (item: IReport) => void;
  onChangePagination: (value: TablePaginationConfig) => void;
}) => {
  const columns: ColumnsType<IReport> = [
    {
      title: 'Báo cáo',
      render: (record: IReport) => (
        <p>
          {CustomDateTime.convertStringToDateTime(
            record.fromDate ?? null,
            'dd/MM/yyyy',
          )}
          {' - '}
          {CustomDateTime.convertStringToDateTime(
            record.toDate ?? null,
            'dd/MM/yyyy',
          )}
        </p>
      ),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Người tạo',
      dataIndex: 'userUsername',
      key: 'userUsername',
    },
    {
      title: 'Ngày tạo',
      width: 140,
      render: (record: IReport) => (
        <p>
          {CustomDateTime.convertStringToDateTime(record.createdDate ?? null)}
        </p>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 110,
      render: (record: IReport) => (
        <div className="flex gap-2">
          <Button onClick={() => onDetail(record)} type="primary" size="small">
            Chi tiết
          </Button>
          <Tooltip title="Xóa báo cáo">
            <Popconfirm
              title="Xóa báo cáo này"
              description="Bạn có chắc chắn xóa báo cáo này?"
              onConfirm={() => onDelete(record)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                size="small"
                type="dashed"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list}
        scroll={{ x: 800 }}
        className="w-full"
        pagination={{
          defaultPageSize: 20,
          position: ['topLeft', 'bottomLeft'],
          showSizeChanger: true,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} của ${total}`;
          },
        }}
        size="middle"
        indentSize={20}
        onChange={onChangePagination}
      />
    </>
  );
};

const HeaderReportList = () => {
  return (
    <div className="pb-2">
      <Breadcrumb
        items={[
          {
            title: 'Trang chủ',
          },
          {
            title: 'Báo cáo',
          },
        ]}
      />
      <h2 className="pt-4 text-lg font-bold text-primary">Báo cáo</h2>
    </div>
  );
};

const ReportListPage = () => {
  const { state, event, isLoading, list } = useReportListState();
  const { state: stateDelete, event: eventDelete } = useReportDeleteState();

  const {
    state: stateExport,
    event: eventExport,
    isLoading: isLoadingExport,
  } = useReportExportState();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);

  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const { showReportDialog } = useModalExport();

  const onConfirmDelete = (item: IReport) => {
    eventDelete.deleteItem({ id: item.id });
  };

  const onLoad = ({
    current,
    size,
  }: {
    current?: number | undefined;
    size?: number;
  }) => {
    if (size) {
      setPageSize(size);
    }
    event.getList({
      pageSize: size ?? pageSize,
      pageIndex: current ?? currentPage,
    });
  };
  const onChangePagination = (config: TablePaginationConfig) => {
    setCurrentPage(config.current ?? 1);
    onLoad({ current: config.current, size: config.pageSize });
  };
  useEffect(() => {
    setCurrentPage(1);
    onLoad({ current: 1 });
  }, []);

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      fetchFailed(res) {
        showErrorDialog(res.error);
      },
      fetchSuccess() {},
    });
  }, [state.type]);

  useEffect(() => {
    match([stateDelete, 'type'])({
      start() {},
      init() {},
      deleteFailed(res) {
        showErrorDialog(res.error);
      },
      deleteSuccess() {
        showSuccessDialog('Xóa báo cáo thành công!');
        onLoad({});
      },
    });
  }, [stateDelete.type]);

  useEffect(() => {
    match([stateExport, 'type'])({
      start() {},
      init() {},
      exportFailed(res) {
        showErrorDialog(res.error);
      },
      exportSuccess(res) {
        showReportDialog({
          username: res.info.username,
          password: res.info.password,
        });
      },
    });
  }, [stateExport.type]);

  return (
    <div className="w-full p-2">
      <HeaderReportList />
      <TablReportList
        list={list}
        isLoading={isLoading}
        onDetail={(report) => {
          setSelectedReport(report);
          setIsOpenDetail(true);
        }}
        onDelete={onConfirmDelete}
        onChangePagination={onChangePagination}
      />
      <DrawerReportDetailComponent
        isOpen={isOpenDetail}
        selected={selectedReport}
        isLoadingExport={isLoadingExport}
        onClose={() => {
          setSelectedReport(null);
          setIsOpenDetail(false);
        }}
        onExport={(id) => eventExport.exportItem({ id })}
      />
    </div>
  );
};

export default ReportListPage;
