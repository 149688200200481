import { Outlet } from 'react-router-dom';

import BackgroundRoot from '../components/Background';

const Root = () => {
  return (
    <>
      <div className="relative flex min-h-screen flex-col">
        <Outlet />
      </div>
      <div className="hidden md:block">
        <BackgroundRoot />
      </div>
    </>
  );
};

export default Root;
