import CustomNumber from '../ultis/format-number';

const CustomFormatNumberReport = ({
  className,
  value,
}: {
  className?: string;
  value: number;
}) => {
  return <span className={className}>{CustomNumber.formatNumber(value)}</span>;
};

export default CustomFormatNumberReport;
