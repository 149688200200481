import { Either } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';

import { IAccount, IResultScanAccount } from './account.interface';
import axiosClient from '../core/axios-client';
import axiosUnClient from '../core/axios-unclient';
import { Methods } from '../core/constant';
import { PageItems } from '../core/core.interface';
import { handleApiError } from '../core/error-handle';
import { ErrorType } from '../core/error-type';

class AccountRepository {
  static getList({
    params,
  }: {
    params: URLSearchParams;
  }): Promise<Either<ErrorType, PageItems<IAccount>>> {
    const query = () =>
      axiosClient<PageItems<IAccount>>({
        method: Methods.POST,
        url: '/v1/accounts/get-list-detail-by',
        params,
        data: {},
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }

  static create({
    account,
  }: {
    account: IAccount;
  }): Promise<Either<ErrorType, IAccount>> {
    const query = () =>
      axiosClient<IAccount>({
        method: Methods.POST,
        url: `/v1/accounts`,
        data: account,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static update({
    account,
  }: {
    account: IAccount;
  }): Promise<Either<ErrorType, IAccount>> {
    const query = () =>
      axiosClient<IAccount>({
        method: Methods.PUT,
        url: `/v1/accounts`,
        data: account,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static delete({ id }: { id: number }): Promise<Either<ErrorType, boolean>> {
    const query = () =>
      axiosClient<boolean>({
        method: Methods.DELETE,
        url: `/v1/accounts/${id}`,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static scanAccount({
    variables,
  }: {
    variables: any;
  }): Promise<Either<ErrorType, IResultScanAccount>> {
    const query = () =>
      axiosUnClient<IResultScanAccount>({
        method: Methods.POST,
        url: `/scan-account`,
        data: variables,
      });

    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((res) => res),
    )();
  }

  static GetAccountListByMemberId({
    memberId,
  }: {
    memberId: number;
  }): Promise<Either<ErrorType, IAccount[]>> {
    const query = () =>
      axiosClient<IAccount[]>({
        method: Methods.GET,
        url: `/v1/accounts/get-list-account-by-member?memberId=${memberId}`,
      });
    return pipe(
      TE.tryCatch(query, (reason) => handleApiError(reason)),
      TE.map((resp) => resp),
    )();
  }
}

export default AccountRepository;
