import { useEffect, useState } from 'react';

import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';

import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useSuccessHandler } from '../../../hooks/useSuccessHandler';
import { match } from '../../../ultis/custom-matcher';
import useChangePasswordState from '../states/useChangePasswordState';

const ChangePasswordComponent = () => {
  const { showErrorDialog } = useErrorHandler();
  const { showSuccessDialog } = useSuccessHandler();
  const { state, event } = useChangePasswordState();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = () => {
    event.changePassword({
      newPassword: form.getFieldValue('newPassword'),
      oldPassword: form.getFieldValue('oldPassword'),
    });
  };
  const handleOk = () => {
    setConfirmLoading(true);
    form.submit();
  };

  const handleCancel = () => {
    setIsOpen(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    match([state, 'type'])({
      start() {},
      init() {},
      changeFailed(res) {
        showErrorDialog(res.error);
        setConfirmLoading(false);
        setIsOpen(false);
      },
      changeSuccess() {
        showSuccessDialog('Thay đổi mật khẩu thành công!');
        setConfirmLoading(false);
        setIsOpen(false);
        form.resetFields();
      },
    });
  }, [state.type]);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Thay đổi mật khẩu</Button>
      <Modal
        title="Thay đổi mật khẩu"
        open={isOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Thay đổi"
        cancelText="Hủy bỏ"
      >
        <div className="p-4">
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <div className="grid grid-cols-1 gap-2">
              <Form.Item
                name="oldPassword"
                rules={[
                  { required: true, message: 'Xin vui lòng nhập mật khẩu cũ!' },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Mật khẩu cũ"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập mật khẩu mới!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Nhập lại mật khẩu mới"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>{' '}
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập lại mật khẩu mới!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Mật khẩu mới không khớp nhau!'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Mật khẩu mới"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordComponent;
