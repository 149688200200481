/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';

import { isRight } from 'fp-ts/lib/Either';
import { create } from 'zustand';

import { ErrorType } from '../../../../../repositories/core/error-type';
import AccountScanService from '../../../../../services/account-scan/account-scan.service';

type IState = {
  state:
    | { type: 'init' }
    | { type: 'start' }
    | { type: 'createFailed'; error: ErrorType }
    | { type: 'createSuccess' };
  isLoading: boolean;
  start: () => void;
  setLoading: (value: boolean) => void;
  createFailed: (error: ErrorType) => void;
  createSuccess: () => void;
};

const createStore = () =>
  create<IState>((set) => ({
    state: { type: 'init' },
    isLoading: false,
    start: () => set({ state: { type: 'start' } }),
    setLoading: (value) => set({ isLoading: value }),
    createFailed: (error) => set({ state: { type: 'createFailed', error } }),
    createSuccess: () => set({ state: { type: 'createSuccess' } }),
  }));

const useAccountScanMapMethodCreateState = () => {
  const [useStore] = useState(createStore);
  const { state, isLoading, start, setLoading, createSuccess, createFailed } =
    useStore();

  const createItem = async ({
    accountScanId,
    methodId,
    methodGroupId,
    memberId,
    percentRefund,
  }: {
    methodId?: number;
    memberId: number;
    percentRefund?: number;
    accountScanId: number;
    methodGroupId?: number;
  }) => {
    start();
    setLoading(true);
    if (methodId) {
      const result = await AccountScanService.createAccountScanMapMethod({
        accountScanId,
        methodId,
        memberId,
        percentRefund,
      });
      if (isRight(result)) {
        createSuccess();
      } else {
        createFailed(result.left);
      }
    } else {
      const result = await AccountScanService.createAccountScanMapGroupMethod({
        accountScanId,
        methodGroupId,
        memberId,
        percentRefund,
      });
      if (isRight(result)) {
        createSuccess();
      } else {
        createFailed(result.left);
      }
    }

    setLoading(false);
  };

  return {
    state,
    isLoading,
    event: {
      createItem,
    },
  };
};

export default useAccountScanMapMethodCreateState;
